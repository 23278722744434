import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import TableTooltip from '../../components/Tools/TableTooltip';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  setLink: any;
  handleOpenEditModal?: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  setLink,
  handleOpenEditModal,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'Client',
    header: () => (
      <div className="w-[240px] sm:w-[25vw] border-r dark:border-gray-700 h-[60px] flex items-center">
        <div className="flex w-[240px] sm:w-[25vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'Client' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div className="w-[240px] sm:w-[25vw] h-[60px] flex justify-start items-center border-r">
          <div className="max-w-[240px] sm:max-w-[25vw]  text-sm cursor-pointer dark:border-gray-700 dark:text-white truncates">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Domain',
    header: () => (
      <div className="w-[240px] sm:w-[18vw] h-[40px] flex items-center">
        <div className="flex w-[240px] sm:w-[18vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Domain</div>
          <div>
            {sortingColumn === 'Domain' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'domain',
    cell: (props: any) => {
      return (
        <div className="w-[240px] sm:w-[18vw] flex justify-center items-center text-sm py-2 dark:text-white">
          {props?.getValue()}
        </div>
      );
    },
  },
  {
    id: 'Link Count',
    header: () => (
      <div className="w-[180px] sm:w-[18vw] h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[18vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Link Count</div>
          <div>
            {sortingColumn === 'Link Count' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'link_count',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[18vw] flex justify-center gap-2 items-center text-sm py-2 dark:text-white">
          {props?.getValue()}
        </div>
      );
    },
  },
  {
    id: 'Client ID',
    header: () => (
      <div className="w-[180px] sm:w-[18vw] h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[18vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client ID</div>
          <div>
            {sortingColumn === 'Client ID' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client_id',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[18vw] flex justify-center gap-2 items-center text-sm py-2 dark:text-white">
          {props?.getValue()}
        </div>
      );
    },
  },
  {
    id: 'Links',
    header: 'Links',
    accessorKey: 'client_id',
    cell: (props: any) => {
      return (
        <div
          className="w-[100px] sm:w-[6vw] flex justify-center items-center py-2 text-sm cursor-pointer "
          onClick={() => {
            setLink(props.getValue());
            handleOpenEditModal();
          }}
        >
          <Icon name="Eye" />
        </div>
      );
    },
  },
];
