import { useMemo, useState, useEffect } from 'react';
import TansStackTableV2 from '../../components/TansStackTableV2';
import { generateColumns } from './column';
import CustomModal from '../../components/CustomModal';
import SearchBar from '../../components/Search';
import { data } from './data';
import LinksLookupModal from '../../components/Modals/Tools/LinksLookupModal';
import useLinkBriefStore from '../../store/useLinkBriefStore';

const LinksLookup = () => {
  const [sortingColumn, setSortingColumn] = useState('');
  const [sortingDirection, setSortingDirection] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [link, setLink] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { fetchAggregatedLinkBrief, aggregatedLinkBrief } = useLinkBriefStore(
    (state) => state
  );

  const formattedDataMemo = useMemo(() => {
    const formatData = (data: any) => {
      const linksData = data?.map((item: any) => {
        return {
          client: item?.name,
          domain: item?.domain,
          link_count: item?.link_count,
          client_id: item?.client_id,
        };
      });
      return linksData;
    };
    const formattedData = formatData(aggregatedLinkBrief?.link_brief_items);
    return formattedData;
  }, [aggregatedLinkBrief?.link_brief_items]);
  // add useEffect to fetch data
  useEffect(() => {
    fetchAggregatedLinkBrief({ page: page, limit: limit });
  }, [page]);

  const handleOpenEditModal = () => {
    setOpenModal(true);
  };

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        setLink,
        handleOpenEditModal,
      }),
    [sortingColumn, sortingDirection]
  );

  const handleSearch = () => {};
  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] lg:overflow-hidden min-h-[60dvh] lg:min-h-[93dvh] dark:bg-[#0C111D] font-inter rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between mt-8 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-10 rounded-t-lg">
          <div className="flex items-center flex-wrap gap-2">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              onSearch={handleSearch}
              placeholder="Search by client"
            />
          </div>
        </div>
        <div>
          <TansStackTableV2
            data={!formattedDataMemo ? [] : formattedDataMemo}
            columns={columns}
            totalPages={7}
            page={page}
            setPage={setPage}
            loading={aggregatedLinkBrief?.loading}
            totalDocs={70}
            stickyColumn="Client Name"
            tableHeight="h-[72dvh] sm:h-[69.8dvh]"
          />
        </div>

        <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
          <LinksLookupModal link={link} onClose={() => setOpenModal(false)} />
        </CustomModal>
      </div>
    </div>
  );
};

export default LinksLookup;
