import React, { useState, useEffect } from 'react';
import useClientStore from '../../store/useClientStore';
import { Card, Grid, Col } from '@tremor/react';
import CopyToClipboardButton from '../../components/CopyButton';

interface ReportingProps {
  selectedClient?: any;
  loading?: boolean
}

const Reporting: React.FC<ReportingProps> = ({ selectedClient, loading }) => {
  const { updateClient } = useClientStore((state) => state);
  const [seoValues, setSeoValues] = useState({
    dashthisUrl:
      selectedClient?.seo_google_report_config?.dashthis_url || '',
    rankRangerUrl:
      selectedClient?.seo_google_report_config?.rank_ranger_url || '',
    outreachUrl:
      selectedClient?.seo_google_report_config?.outreach_url || '',
    strategyUrl: selectedClient?.seo_google_report_config?.strategy || '',
  });
  const [ppcValues, setppcValues] = useState({
    dashthisUrl:
      selectedClient?.ppc_google_report_config?.dashthis_url || '',
    rankRangerUrl:
      selectedClient?.ppc_google_report_config?.rank_ranger_url || '',
    strategyUrl: selectedClient?.ppc_google_report_config?.strategy || '',
  });
  const [socialValues, setSocialValues] = useState({
    dashthisUrl:
      selectedClient?.social_meta_report_config?.dashthis_url || '',
    rankRangerUrl:
      selectedClient?.social_meta_report_config?.rank_ranger_url || '',
    strategyUrl: selectedClient?.social_meta_report_config?.strategy || '',
  });
  const [bingValues, setBingValues] = useState({
    dashthisUrl: selectedClient?.seo_bing_report_config?.dashthis_url || '',
    strategyUrl: selectedClient?.bing_report_config?.strategy || '',
  });

  const isSEOAuto =
    selectedClient?.seo_google_report_config?.reporting_method !== 'manual';
  const isPPCAuto =
    selectedClient?.ppc_google_report_config?.reporting_method !== 'manual';
  const isSocialAuto =
    selectedClient?.social_meta_report_config?.reporting_method !== 'manual';
  const defaultPPCWeekly =
    selectedClient?.ppc_google_report_config?.weekly_report === 'enabled'
      ? true
      : false;
  const isBingAuto =
    selectedClient?.seo_bing_report_config?.reporting_method !== 'manual';

  
  const [isSEOEnabled, setIsSEOEnabled] = useState(isSEOAuto);
  const [isPPCEnabled, setIsPPCEnabled] = useState(isPPCAuto);
  const [isSocialEnabled, setIsSocialEnabled] = useState(isSocialAuto);
  const [isWeeklyPPCEnabled, setWeeklyIsPPCEnabled] =
    useState(defaultPPCWeekly);
  const [isBingEnabled, setIsBingEnabled] = useState(isBingAuto);

  const [isPublicURLEnabled, setIsPublicURLEnabled] = useState(selectedClient?.public_dashboard || false);
  const publicDefURL = `${window.location.origin}/client/dashboard?token=${selectedClient?.public_dashboard_token}&client_id=${selectedClient?.id}&ppage=seo-ga4`
  const [publicURL, setPublicURL] = useState(selectedClient?.public_dashboard_token ? publicDefURL : '');
  
  useEffect(() => {
    setSeoValues({
      dashthisUrl: selectedClient?.seo_google_report_config?.dashthis_url || '',
      rankRangerUrl:
        selectedClient?.seo_google_report_config?.rank_ranger_url || '',
      outreachUrl: selectedClient?.seo_google_report_config?.outreach_url || '',
      strategyUrl: selectedClient?.seo_google_report_config?.strategy || '',
    });

    setppcValues({
      dashthisUrl: selectedClient?.ppc_google_report_config?.dashthis_url || '',
      rankRangerUrl:
        selectedClient?.ppc_google_report_config?.rank_ranger_url || '',
      strategyUrl: selectedClient?.ppc_google_report_config?.strategy || '',
    });

    setSocialValues({
      dashthisUrl:
        selectedClient?.social_meta_report_config?.dashthis_url || '',
      rankRangerUrl:
        selectedClient?.social_meta_report_config?.rank_ranger_url || '',
      strategyUrl: selectedClient?.social_meta_report_config?.strategy || '',
    });
    setBingValues({
      dashthisUrl: selectedClient?.seo_bing_report_config?.dashthis_url || '',
      strategyUrl: selectedClient?.bing_report_config?.strategy || '',
    })
    setIsSEOEnabled(isSEOAuto);
    setIsPPCEnabled(isPPCAuto);
    setIsSocialEnabled(isSocialAuto);
    setWeeklyIsPPCEnabled(defaultPPCWeekly);
    setIsBingEnabled(isBingAuto);

    setPublicURL(selectedClient?.public_dashboard_token ? publicDefURL :  '')
    setIsPublicURLEnabled(selectedClient?.public_dashboard && selectedClient?.public_dashboard_token?.length > 0 ? true : false)
  }, [selectedClient]);

  const handleMonthlyChanges = () => {
    const data = {
      seo_google_report_config: {
        ...selectedClient.seo_google_report_config,
        dashthis_url: seoValues.dashthisUrl,
        rank_ranger_url: seoValues.rankRangerUrl,
        strategy: seoValues.strategyUrl,
        outreach_url: seoValues.outreachUrl,
        reporting_method: isSEOEnabled ? 'auto' : 'manual',
      },
      ppc_google_report_config: {
        ...selectedClient.ppc_google_report_config,
        dashthis_url: ppcValues.dashthisUrl,
        rank_ranger_url: ppcValues.rankRangerUrl,
        reporting_method: isPPCEnabled ? 'auto' : 'manual',
      },
      social_meta_report_config: {
        ...selectedClient.social_meta_report_config,
        dashthis_url: socialValues.dashthisUrl,
        rank_ranger_url: socialValues.rankRangerUrl,
        reporting_method: isSocialEnabled ? 'auto' : 'manual',
      },
      seo_bing_report_config: {
        ...selectedClient.seo_bing_report_config,
        dashthis_url: bingValues.dashthisUrl,
        strategy: bingValues.strategyUrl,
        reporting_method: isBingEnabled ? 'auto' : 'manual',
      }
    };
    updateClient(selectedClient?.id, data).catch((e) => console.log(e.message));
  };

  const handlePublishDashboard = () => {
    const data = {
      public_dashboard: !isPublicURLEnabled
    }
    setIsPublicURLEnabled(!isPublicURLEnabled);
    updateClient(selectedClient?.id, data).catch((e) => console.log(e.message));
  }

  const handleWeeklyChanges = () => {
    const data = {
      ppc_google_report_config: {
        weekly_report: isWeeklyPPCEnabled ? 'enabled' : 'disabled',
      },
    };
    updateClient(selectedClient?.id, data).catch((e: any) =>
      console.log(e.message)
    );
  };

  // Function to handle input changes
  const handleSeoValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputField: string
  ) => {
    const { value } = event.target;
    setSeoValues((prevInputValues) => ({
      ...prevInputValues,
      [inputField]: value,
    }));
  };

  // Function to handle input changes
  const handlePPCValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputField: string
  ) => {
    const { value } = event.target;
    setppcValues((prevInputValues) => ({
      ...prevInputValues,
      [inputField]: value,
    }));
  };

  // Function to handle input changes
  const handleSocialValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputField: string
  ) => {
    const { value } = event.target;
    setSocialValues((prevInputValues) => ({
      ...prevInputValues,
      [inputField]: value,
    }));
  };

  const handleBingValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputField: string
  ) => {
    const { value } = event.target;
    setBingValues((prevInputValues) => ({
      ...prevInputValues,
      [inputField]: value,
    }));
  };


  return (
    <Grid numItems={2} className="gap-8">
      <Col>
        <Card>
          <div className="flex items-center justify-between w-full mb-8">
            {/* <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400">
                      Google SEO
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={isSEOEnabled}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                </div> */}
            <div className="font-semibold text-xl dark:text-white">
              Dashboard
            </div>
            <button
              onClick={handleMonthlyChanges}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Save
            </button>
          </div>
          <div className="flex flex-col items-start gap-y-6 bg-white  w-full dark:bg-dark-tremor-background">
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Google SEO
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={seoValues?.dashthisUrl}
                onChange={(e) => handleSeoValues(e, 'dashthisUrl')}
                required
              />
            </div>
            {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Google Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={isWeeklyPPCEnabled}
                          onChange={(e) =>
                            setWeeklyIsPPCEnabled(e.target.checked)
                          }
                          disabled={!selectedClient}
                          checked={isWeeklyPPCEnabled}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Google Ads
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={ppcValues?.dashthisUrl}
                onChange={(e) => handlePPCValues(e, 'dashthisUrl')}
                required
              />
            </div>
            {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Bing Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={isWeeklyPPCEnabled}
                          onChange={(e) =>
                            setWeeklyIsPPCEnabled(e.target.checked)
                          }
                          disabled={!selectedClient}
                          checked={isWeeklyPPCEnabled}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Bing Ads
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={bingValues?.dashthisUrl}
                onChange={(e) => handleBingValues(e, 'dashthisUrl')}
                required
              />
            </div>
            {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                      Meta
                    </span>
                  </div>
                  <div className="flex items-center cursor-not-allowed">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={!isSocialEnabled}
                        disabled
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F] cursor-not-allowed"></div>
                    </label>
                  </div>
                </div>
              </div> */}
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Meta
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={socialValues?.dashthisUrl}
                onChange={(e) => handleSocialValues(e, 'dashthisUrl')}
                required
              />
            </div>
            {/* <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Public Analytics
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Public dashboard URL"
                disabled={true}
                value={socialValues?.dashthisUrl}
                onChange={(e) => handleSocialValues(e, 'dashthisUrl')}
                required
              />
            </div> */}
            <div className="flex items-center justify-start w-[90%]">
              <div className="flex items-center gap-4 justify-between w-full">
                <div className="w-[9rem]">
                  <span className="text-[#001C44] dark:text-gray-400 text-sm inline-block">
                    Public Dashboard
                  </span>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className='w-full flex px-4 row border dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-lg'>
                    <input
                      className="py-2 w-full text-sm text-gray-500 pr-2 bg-white dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151]"
                      placeholder="Public dashboard URL"
                      disabled
                      value={isPublicURLEnabled ? `...${selectedClient?.public_dashboard_token}` : 'generate public dashboard token...'}
                      onChange={(e) => console.log(e.target.value, 'publicUrl')}
                      required
                    />
                
                    <CopyToClipboardButton
                      disabled={publicURL ? false : true}
                      textToCopy={publicURL}
                      size={12}
                      />
                  </div>
                  <button
                    onClick={handlePublishDashboard}
                    className="ml-2 text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    {!loading && isPublicURLEnabled ? 'Disable' : 'Generate'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col>
        <Card>
          <div className="flex items-center justify-between w-full mb-8">
            <div className="font-semibold text-xl dark:text-white">
              Strategy Docs
            </div>
            <button
              onClick={handleMonthlyChanges}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Save
            </button>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col items-start gap-y-6 bg-white w-full dark:bg-dark-tremor-background">
              {/* <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400">
                      Google SEO
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={isSEOEnabled}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleMonthlyChanges}
                  className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div> */}
              <div className="flex w-[90%]">
                <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                  Google SEO
                </span>

                <input
                  className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                  placeholder="Enter URL here..."
                  value={seoValues.strategyUrl}
                  onChange={(e) => handleSeoValues(e, 'strategyUrl')}
                  required
                />
              </div>
              {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Google Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={isWeeklyPPCEnabled}
                          onChange={(e) =>
                            setWeeklyIsPPCEnabled(e.target.checked)
                          }
                          disabled={!selectedClient}
                          checked={isWeeklyPPCEnabled}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="flex w-[90%]">
                <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                  Google Ads
                </span>

                <input
                  className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                  placeholder="Enter URL here..."
                  value={ppcValues.strategyUrl}
                  onChange={(e) => handlePPCValues(e, 'strategyUrl')}
                  required
                />
              </div>
              {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Bing Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={isWeeklyPPCEnabled}
                          onChange={(e) =>
                            setWeeklyIsPPCEnabled(e.target.checked)
                          }
                          disabled={!selectedClient}
                          checked={isWeeklyPPCEnabled}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="flex w-[90%]">
                <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                  Bing Ads
                </span>

                <input
                  className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                  placeholder="Enter URL here..."
                  value={bingValues.strategyUrl}
                  onChange={(e) => handleBingValues(e, 'strategyUrl')}
                  
                  required
                />
              </div>
              {/* <div className="flex items-center justify-start w-full">
                <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                      Meta
                    </span>
                  </div>
                  <div className="flex items-center cursor-not-allowed">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={!isSocialEnabled}
                        disabled
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F] cursor-not-allowed"></div>
                    </label>
                  </div>
                </div>
              </div> */}
              <div className="flex w-[90%]">
                <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                  Meta
                </span>

                <input
                  className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                  placeholder="Enter URL here..."
                  value={socialValues.strategyUrl}
                  onChange={(e) => handleSocialValues(e, 'strategyUrl')}
                  required
                />
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col>
        <Card>
          <div className="flex items-center justify-between w-full mb-8">
            <div className="font-semibold text-xl dark:text-white">
              Monthly Report
            </div>
            <button
              onClick={handleMonthlyChanges}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Save
            </button>
          </div>
          <div className="flex flex-col items-start gap-y-6 bg-white dark:bg-dark-tremor-background">
            <div className="flex items-center gap-4">
              <div className="w-[7.6rem]">
                <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                  Google SEO
                </span>
              </div>
              <div className="flex items-center">
                <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isSEOEnabled}
                    onChange={(e) => setIsSEOEnabled(e.target.checked)}
                    defaultChecked={isSEOEnabled}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                </label>
              </div>
            </div>
            {/* <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Dashthis
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={seoValues?.dashthisUrl}
                onChange={(e) => handleSeoValues(e, 'dashthisUrl')}
                required
              />
            </div>
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Rank Ranger
              </span>

              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={seoValues.rankRangerUrl}
                onChange={(e) => handleSeoValues(e, 'rankRangerUrl')}
                required
              />
            </div>
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Strategy
              </span>

              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={seoValues.strategyUrl}
                onChange={(e) => handleSeoValues(e, 'strategyUrl')}
                required
              />
            </div>
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Outreach
              </span>

              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={seoValues.outreachUrl}
                onChange={(e) => handleSeoValues(e, 'outreachUrl')}
                required
              />
            </div> */}
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center gap-4">
                <div className="w-[7.6rem]">
                  <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                    Google Ads
                  </span>
                </div>
                <div className="flex items-center">
                  <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isPPCEnabled}
                      onChange={(e) => setIsPPCEnabled(e.target.checked)}
                      defaultChecked={isPPCEnabled}
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Dashthis
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={ppcValues?.dashthisUrl}
                onChange={(e) => handlePPCValues(e, 'dashthisUrl')}
                required
              />
            </div>
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Rank Ranger
              </span>

              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={ppcValues.rankRangerUrl}
                onChange={(e) => handlePPCValues(e, 'rankRangerUrl')}
                required
              />
            </div> */}
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center gap-4">
                <div className="w-[7.6rem]">
                  <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                    Bing Ads
                  </span>
                </div>
                <div className="flex items-center">
                  <label className="relative inline-flex items-center justify-center align-center cursor-not-allowed">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      // checked={isBingAuto}
                      onChange={(e) => setIsBingEnabled(e.target.checked)}
                      // defaultChecked={isBingAuto}
                      disabled
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center gap-4">
                <div className="w-[7.6rem]">
                  <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                    Meta
                  </span>
                </div>
                <div className="flex items-center">
                  <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={isSocialEnabled}
                      onChange={(e) => setIsSocialEnabled(e.target.checked)}
                      defaultChecked={isSocialEnabled}
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Dashthis
              </span>
              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={socialValues?.dashthisUrl}
                onChange={(e) => handleSocialValues(e, 'dashthisUrl')}
                required
              />
            </div>
            <div className="flex w-[90%]">
              <span className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                Rank Ranger
              </span>

              <input
                className="block py-2 px-4 w-full border text-sm text-gray-900 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:border-[#374151] dark:border-[#374151] rounded-r-lg"
                placeholder="Enter URL here..."
                value={socialValues.rankRangerUrl}
                onChange={(e) => handleSocialValues(e, 'rankRangerUrl')}
                required
              />
            </div> */}
          </div>
        </Card>
      </Col>
      <Col>
        <Card>
          <div className="flex items-center justify-between w-full mb-8">
            <div className="font-semibold text-xl dark:text-white">
              Weekly Report
            </div>
            <button
              onClick={handleWeeklyChanges}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Save
            </button>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col items-start gap-y-6 bg-white w-full dark:bg-dark-tremor-background">
              <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Google Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={isWeeklyPPCEnabled}
                          onChange={(e) =>
                            setWeeklyIsPPCEnabled(e.target.checked)
                          }
                          disabled={!selectedClient}
                          checked={isWeeklyPPCEnabled}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                      Google SEO
                    </span>
                  </div>
                  <div className="flex items-center">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={!isSEOEnabled}
                        disabled
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F] cursor-not-allowed"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center gap-4">
                    <div className="w-[7.6rem]">
                      <span className="text-xl text-[#001C44] dark:text-gray-400">
                        Bing Ads
                      </span>
                    </div>
                    <div className="flex items-center">
                      <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          defaultChecked={!isSEOEnabled}
                          disabled
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F] cursor-not-allowed"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start w-full">
                <div className="flex items-center gap-4">
                  <div className="w-[7.6rem]">
                    <span className="text-xl text-[#001C44] dark:text-gray-400 ">
                      Meta
                    </span>
                  </div>
                  <div className="flex items-center cursor-not-allowed">
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        defaultChecked={!isSocialEnabled}
                        disabled
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F] cursor-not-allowed"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Grid>
  );
};
export default Reporting;
