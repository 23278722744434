import Icon from '../../assets/icons/SvgComponent';
import { Badge } from '@tremor/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import usePerformanceStore from '../../store/usePerformanceStore';

const PerformanceCounter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const urlStatus = searchParams.get('status');
  const { summary } = usePerformanceStore((state) => state);
  const defalutStatus = urlStatus || 'all';
  const [activeWeeklyStatus, setActiveWeeklyStatus] = useState(
    defalutStatus || 'all'
  );

  const statusOptions = [
    {
      id: 'all',
      label: 'All',
      bgColor: '#B2BBC71A',
      hexColor: '#405573',
      count: summary?.red + summary?.amber + summary?.green || 0,
    },
    {
      id: 'red',
      label: 'Red',
      bgColor: '#FF00000D',
      hexColor: '#FF0000',
      count: summary?.red || 0,
    },
    {
      id: 'amber',
      label: 'Amber',
      bgColor: '#FF692E1A',
      hexColor: '#FF692E',
      count: summary?.amber || 0,
    },
    {
      id: 'green',
      label: 'Green',
      bgColor: '#3CCB7F1A',
      hexColor: '#3CCB7F',
      count: summary?.green || 0,
    },
  ];

  const handleReportTypes = (status: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('status', status);
    searchParams.set('page', '1');
    setActiveWeeklyStatus(status);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };

  useEffect(() => {
    handleReportTypes(activeWeeklyStatus);
  }, []);

  return (
    <div className="cursor-pointer w-screen sm:w-fit gap-2 flex justify-center sm:justify-start items-center overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
      {statusOptions?.map((option, index) => (
        <div
          key={index}
          className={`flex flex-col gap-1 p-1 items-center w-fit font-inter font-[600] rounded-md ${
            activeWeeklyStatus === option.id &&
            'dark:bg-gray-800 dark:text-white'
          }`}
          onClick={() => handleReportTypes(option.id)}
        >
          {/* <div className="dark:text-white text-xs ">{option.label}</div> */}
          {summary?.loading ? (
            <div className="w-20 h-[25px] flex justify-center items-center">
              <div className="ml-2">
                <Icon name="loading" size={20} />
              </div>
            </div>
          ) : (
            <div
              className="cursor-pointer h-[24px] text-sm rounded-[8px] flex items-center"
              style={{ color: option?.hexColor, background: option?.bgColor }}
            >
              <div className="flex items-center justify-center gap-[8px] px-[8px] py-[5px]">
                <div
                  className="w-[6px] h-[6px] rounded-full"
                  style={{ backgroundColor: option.hexColor }}
                />
                <div className="text-sm font-[500]">{option.label}</div>
                <div className="text-sm font-[600]">{option.count}</div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PerformanceCounter;
