import { useMemo } from 'react';
import { formatToCurrency, formatNumber } from '../../../../utils/common';
import useClientStore from '../../../../store/useClientStore';
import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import useBingStore from '../../../../store/useBingStore';
import AnalyticsTable from '../../AnalyticsTable';
import Tooltip from '../../../HelpTooltip';

interface IProps {
  keywordsResponse: any;
}

const BingKeywordOverview = ({ keywordsResponse }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);

  const keywordsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Keyword',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Keyword' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[13vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Keyword' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[13vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Keyword' || headerId === 'Keyword') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[13vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[200px] sm:w-[13vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[200px] sm:max-w-[13vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Impression',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impression' || headerId === 'Impression') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cpc',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversion Rate',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversion Rate' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversion Rate' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Conversion Rate' ||
            headerId === 'Conversion Rate'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversion_rate',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'ROAS',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'ROAS' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'ROAS' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'ROAS' || headerId === 'ROAS') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'roas',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    let loading = true;
    const data = (keywordsResponse || []).map((resource: any) => {
      return {
        name: resource?.Keyword,
        clicks: Number(resource?.Clicks),
        cost: Number(resource?.Spend),
        conversions: Number(resource?.Conversions),
        conversion_rate: Number(resource?.ConversionRate),
        impressions: Number(resource?.Impressions),
        cpc: Number(resource?.CostPerConversion),
        roas: Number(resource?.ReturnOnAdSpend),
        ctr: Number(resource?.Ctr),
      };
    });

    if (keywordsResponse) {
      loading = false;
    }
    return { tableHeader, data, loading };
  }, [keywordsResponse]);

  return (
    <>
      <div className="hidden sm:flex justify-between items-center">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
          Keyword Overview
        </h2>
        <div className="sm:pr-4">
          <Tooltip
            content={
              selectedClient?.google_ads?.customer_id
                ? `Customer ID: ${selectedClient?.google_ads?.customer_id}`
                : 'N/A'
            }
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name="BingAnalyticsTab" size={20} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="mt-8">
        {keywordsReportData?.data?.length === 0 && !campaignsReport.loading ? (
          <Card className="card-item px-1 sm:px-6">
            <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
              <div className="mb-8">
                <Icon name="NoDataAdGroup" />
              </div>
              <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                No keywords to display right now.
              </p>
              <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
                Stay connected with the latest in keyword strategies and market
                insights. Our dedicated area provides the updates and analysis
                crucial for optimizing your campaigns.
              </p>
            </div>
          </Card>
        ) : (
          <AnalyticsTable
            data={keywordsReportData?.data}
            columns={keywordsReportData?.tableHeader}
            loading={campaignsReport.loading}
            totalDocs={
              keywordsReportData?.data ? keywordsReportData?.data.length : 'N/A'
            }
            sortByColumnId="Cost"
            pagination={false}
          />
        )}
      </div>
    </>
  );
};

export default BingKeywordOverview;
