import React, { useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/useAuthStore';
import { useEffect, useState } from 'react';
import useClientStore from '../../store/useClientStore';
import useReportStore from '../../store/useReportStore';
import moment from 'moment';
import { Card, Grid, Col } from '@tremor/react';
import useUserStore from '../../store/useUserStore';
import TansStackTableV2 from '../../components/TansStackTableV2';
import DatePicker from 'react-datepicker';
import ReactSelect from '../../components/ReactSelect';
import Icon from '../../assets/icons/SvgComponent';
import { generateColumns } from './column';
import GoogleImg from '../../assets/images/services/Google.png';
import MetaImg from '../../assets/images/services/MetaADS.png';
import GoogleAdsImg from '../../assets/images/services/GoogleADS.png';
import BingAdsImg from '../../assets/images/services/BingADS.png';
import RemarketingImg from '../../assets/images/services/Remarketing.png';
import { useThemeStore } from '../../store/useThemeStore';

const typeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Google SEO', value: 'seo_google' },
  { label: 'Bing SEO', value: 'seo_bing' },
  { label: 'Google Ads', value: 'ppc_google' },
  { label: 'Microsoft Ads', value: 'ppc_bing' },
  { label: 'Meta', value: 'social_meta' },
  { label: 'LinkedIn', value: 'social_linkedin' },
  { label: 'Tiktok', value: 'social_tiktok' },
  { label: 'Pinterest ', value: 'social_pinterest' },
  { label: 'Remarketing', value: 'display' },
];

const generateServiceImage = (key: string) => {
  const keywordMappings = {
    seo_google: { image: GoogleImg, label: 'Google Seo' },
    ppc_google: { image: GoogleAdsImg, label: 'Google Ads' },
    ppc_bing: { image: BingAdsImg, label: 'Microsoft Ads' },
    social_meta: { image: MetaImg, label: 'Meta Ads' },
    display: { image: RemarketingImg, label: 'Remarketing' },
  };

  return (
    keywordMappings[key] || {
      image: '',
      label: 'Service Not Available',
    }
  );
};

const Reports: React.FC = () => {
  const { mode } = useThemeStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [gridView, setGridView] = useState(false);
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [selectedService, setSelectedService] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const scrollRef = useRef<any>(null);
  const { fetchUser } = useUserStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const { currentUser, selectedDomain } = useAuthStore((state) => state);
  const { fetchClient, selectedClient } = useClientStore((state) => state);
  const { report, fetchReportDetailsV2, selectedReport, reportDate } =
    useReportStore((state) => state);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const startDateParam = searchParams.get('startDate');
    const serviceParam = searchParams.get('service');
    const today = moment().subtract(1, 'month').format('MM-yyyy');

    let date;
    if (!startDateParam) {
      const currentMonth = moment().clone().startOf('month');
      date = currentMonth.startOf('month').format('MM-YYYY');
    } else {
      date = moment(startDateParam).startOf('month').format('MM-YYYY');
    }

    if (serviceParam) {
      const serviceLabel =
        serviceParam === 'seo_google' ? 'Google SEO' : 'Google Ads';
      setSelectedService({ label: serviceLabel, value: serviceParam });
    }

    if (selectedDomain?.id && selectedClient.service?.length > 0) {
      fetchReportDetailsV2({
        client_id: selectedDomain?.id,
        date: startDateParam ? startDateParam : null,
        type: serviceParam || '',
        page: page,
        limit: 10,
        status: 'sent',
        report_type: selectedClient.service[0],
      });
    }
  }, [location.search, selectedClient, page]);

  useEffect(() => {
    if (selectedDomain?.id) {
      fetchClient(selectedDomain?.id);
    }
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      fetchClient(selectedDomain?.id);
    }
  }, [selectedDomain.id]);

  const scrollToTop = () => {
    const headerHeight = '64px';
    scrollRef.current.style.scrollMargin = headerHeight;
    return scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToTop();
    }
  }, [location.pathname]);

  const handleDateChange = (val: any) => {
    const date = moment(val).format('MM-YYYY');
    const searchParams = new URLSearchParams(location.search);
    if (val) {
      searchParams.set('startDate', date);
    } else {
      searchParams.delete('startDate');
    }
    const updatedSearch = searchParams?.toString();
    navigate({ search: updatedSearch });
  };

  const handleServiceChange = (option: any) => {
    if (option) {
      setSelectedService(option);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('service', option.value);
      const updatedSearch = searchParams.toString();
      navigate({ search: updatedSearch });
    }
  };

  const searchParams = new URLSearchParams(location.search);
  const startDateDefault = null; // No default value
  const startDateParam = searchParams.get('startDate');
  const today = moment().format('MM-yyyy');
  const maxDate = moment(today, 'MM-yyyy')
    .subtract(1, 'month')
    .endOf('month')
    .toDate();

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        navigate,
        generateServiceImage,
      }),
    [sortingColumn, sortingDirection]
  );

  const selectedServices = selectedClient?.service || [];

  const filteredOptions = typeOptions.filter((option) =>
    selectedServices.includes(option?.value)
  );

  const clearFilters = () => {
    // Clear the selectedService state
    setSelectedService(null);

    // Get the current search params
    const searchParams = new URLSearchParams(location.search);

    // Remove the startDate and service params
    searchParams.delete('startDate');
    searchParams.delete('service');

    // Update the URL with the new search parameters
    navigate({ search: searchParams.toString() });
  };

  return (
    <div className="px-10 py-2 rounded-lg">
      <div
        className={`bg-white dark:bg-[#1F2937] flex justify-end gap-2 py-4 px-6  ${!gridView ? 'rounded-t-[12px]' : 'rounded-[12px]'}`}
      >
        <div className="w-[140px]">
          <ReactSelect
            options={filteredOptions}
            value={selectedService}
            handleOnChange={(selectedOption: any) =>
              handleServiceChange(selectedOption)
            }
            placeholder="Services"
          />
        </div>
        <div className="w-[100px] z-40 font-inter">
          <DatePicker
            selected={
              startDateParam
                ? moment(startDateParam, 'MM-yyyy').toDate()
                : startDateDefault
            }
            onChange={handleDateChange}
            dateFormat="MMM yyyy"
            maxDate={maxDate}
            showMonthYearPicker
            placeholderText="All Date"
            className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker bg-blue dark:bg-[#374151] dark:text-white dark:border-none font-inter rounded-lg text-[14px] text-[#525252]"
          />
        </div>
        <div
          onClick={clearFilters}
          className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${startDateParam || selectedService ? 'bg-[#808EA280]' : ''}`}
          title="Clear Filter"
        >
          <Icon
            name="Xclose"
            size={16}
            color={mode === 'dark' ? '#FFF' : '#000'}
          />
        </div>

        {/* <div
          onClick={() => setGridView(false)}
          className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${!gridView && 'bg-[#808EA280]'}`}
          title="Table View"
        >
          <Icon
            name="TableView"
            size={16}
            color={mode === 'dark' ? '#FFF' : '#000'}
          />
        </div> */}

        {/* <div
          onClick={() => setGridView(true)}
          className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${gridView && 'bg-[#808EA280]'}`}
        >
          <Icon
            name="GridView"
            size={16}
            color={mode === 'dark' ? '#FFF' : '#000'}
          />
        </div> */}
      </div>
      {gridView ? (
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={3}
          className="mt-8 gap-4"
        >
          {selectedReport?.data?.map((data, index) => (
            <Col key={index}>
              <Card className="relative dark:bg-[#1F2937]">
                <div className="absolute top-[-20px] left-3 sm:left-5 z-[8]">
                  <div className="w-[52px] h-[52px]">
                    <img
                      src={generateServiceImage(data?.report_type)?.image}
                      alt={generateServiceImage(data?.report_type)?.label}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="text-[20px] text-[#001C44]  dark:text-white font-bold mt-3">
                  {generateServiceImage(data?.report_type)?.label}
                </div>
                <div className="text-[#001C4480] text-sm my-3 dark:text-white line-clamp-4">
                  {data?.report?.overview}
                </div>
                <div className="text-[#001C4480] text-sm my-3 dark:text-white ">
                  {data?.date}
                </div>
                <div
                  className="text-[#0029FF] text-sm font-bold uppercase "
                  onClick={() =>
                    navigate(`/reports/details/${data?.id}`, {
                      state: data,
                    })
                  }
                >
                  Read more
                </div>
              </Card>
            </Col>
          ))}
        </Grid>
      ) : (
        <TansStackTableV2
          data={selectedReport?.data ? selectedReport?.data : []}
          columns={columns}
          totalPages={selectedReport?.total_pages}
          page={page}
          setPage={setPage}
          loading={selectedReport.loading}
          totalDocs={selectedReport?.total_docs}
        />
      )}
    </div>
  );
};

export default Reports;
