import moment from "moment";
import useClientStore from "../../../store/useClientStore";
import { parseDate } from "../../../utils/common";
import useGoogleStore from "../../../store/useGoogleStore";
import { useCallback } from "react";
import { getStatus } from "../../../utils";
import useMetaStore from "../../../store/useMetaStore";
import use3rdPartyStore from "../../../store/use3rdPartyStore";
import useBingStore from "../../../store/useBingStore";

const searchParams = new URLSearchParams(window.location.href);
  let startDateURL = searchParams.get('startDate');
  let endDateURL = searchParams.get('endDate');

const seoStartDate = parseDate(localStorage.getItem('seoStartDate')) ?? startDateURL;
const seoEndDate = parseDate(localStorage.getItem('seoEndDate')) ?? endDateURL;
const startDate = parseDate(localStorage.getItem('startDate')) ?? startDateURL;
const endDate = parseDate(localStorage.getItem('endDate')) ?? endDateURL;

// Google analytics
export const useRetryFetchGA4Analytics = () => {
  const {
    fetchGA4SeoData,
    resetGoogleStore
  } = useGoogleStore((state) => state);

  const { selectedClient } = useClientStore((state) => state);

  const retryFetchGA4Analytics = useCallback(() => {
    const client = selectedClient;

    const data = {
      account_email: client?.google_analytics?.ga4?.account_email,
      propertyId: selectedClient?.google_analytics?.ga4?.property_id,
      startDate: moment(seoStartDate).subtract(1, 'year').format('YYYY-MM-DD'),
      reportType: 'seo',
      endDate: moment(seoEndDate).format('YYYY-MM-DD'),
      resetCache: false,
    };

    if (data.propertyId && data.account_email) {
      return fetchGA4SeoData(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      return resetGoogleStore(['ga4SeoData']);
    }
  }, [selectedClient, fetchGA4SeoData, resetGoogleStore]);

  return retryFetchGA4Analytics;
};

// Google search console
export const useRetryFetchGS4Analytics = () => {
  const {
    fetchGsearchData,
    resetGoogleStore
  } = useGoogleStore((state) => state);

  const { selectedClient } = useClientStore((state) => state);

  const retryFetchGAConsole = useCallback(() => {
    const client = selectedClient;
    const data = {
      siteUrl: client?.google_search_console?.siteUrl,
      account_email: client?.google_search_console?.account_email,
      startDate: moment(seoStartDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(seoEndDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.siteUrl && data.account_email && getStatus(client?.google_search_console?.status)) {
      fetchGsearchData(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      resetGoogleStore(['ga4SeoData']);
    }
  }, [selectedClient, fetchGsearchData, resetGoogleStore]);

  return retryFetchGAConsole;
};

/** 3rd party store */
// Ahref back links
export const useRetryFetchAhrefLinks = () => {
  const {
    fetchAhrefsLinks,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchAhrefLinks = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client.id,
      page: 1,
      limit: 10,
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.id ) {
      fetchAhrefsLinks(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['ahrefsLinks']);
    }
  }, [selectedClient, fetchAhrefsLinks, reset3rdPartyStore]);

  return retryFetchAhrefLinks;
};

// Semrush backlinks
export const useRetryFetchSemrushLinks = () => {
  const {
    fetchSemrushLinks,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchASemrushLinks = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      page: 1,
      limit: 10,
      resetCache: false,
    };
    if (data.id ) {
      fetchSemrushLinks(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['semrushLinks']);
    }
  }, [selectedClient, fetchSemrushLinks, reset3rdPartyStore]);

  return retryFetchASemrushLinks;
};

// semrush Traffic Competition or competitors analysis
export const useRetryFetchSemrushCompetitor = () => {
  const {
    fetchTrafficCompetition,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchSemrushCompetitor = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      resetCache: false,
    };
    if (data.id ) {
      fetchTrafficCompetition(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['semrushData']);
    }
  }, [selectedClient, fetchTrafficCompetition, reset3rdPartyStore]);

  return retryFetchSemrushCompetitor;
};

// Ahref RefDomains
export const useRetryFetchAhrefDomains = () => {
  const {
    fetchRefDomains,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchAhrefDomains = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      startDate: moment(seoStartDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(seoEndDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.id ) {
      fetchRefDomains(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['refdomains']);
    }
  }, [selectedClient, fetchRefDomains, reset3rdPartyStore]);

  return retryFetchAhrefDomains;
};

// Ahref Domain Rating History
export const useRetryFetchAhrefDomainRatingHistory = () => {
  const {
    fetchDomainRatingHistory,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchDomainRatingHistory = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      startDate: moment(seoStartDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(seoEndDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.id) {
      fetchDomainRatingHistory(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['domainRatingHistory']);
    }
  }, [selectedClient, fetchDomainRatingHistory, reset3rdPartyStore]);

  return retryFetchDomainRatingHistory;
};

// Org search position
export const useRetryFetchOrgSearchPosition = () => {
  const {
    fetchOrgSearchPositions,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchOrgSearchPosition = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client?.id,
      resetCache: false,
    };
    if (data.id) {
      fetchOrgSearchPositions(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['orgSearchPositions']);
    }
  }, [selectedClient, fetchOrgSearchPositions, reset3rdPartyStore]);

  return retryFetchOrgSearchPosition;
};

// rank ranger
export const useRetryFetchRankRanger = () => {
  const {
    fetchRankRanger,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchRankRanger = useCallback(() => {
    const client = selectedClient;
    const data = {
      campaignId: client?.rank_ranger?.campaign_id,
      domain: client?.domain,
      date: moment(startDate).format('YYYY-MM'),
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.campaignId) {
      fetchRankRanger(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['rankRangerData']);
    }
  }, [selectedClient, fetchRankRanger, reset3rdPartyStore]);

  return retryFetchRankRanger;
};

// Top keyword
export const useRetryFetchTopKeywords = () => {
  const {
    fetchTopKeywords,
    reset3rdPartyStore
  } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchTopKeywords = useCallback(() => {
    const client = selectedClient;
    const data = {
      id: client.id,
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: endDate,
      resetCache: false,
    };
    if (data.id) {
      fetchTopKeywords(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      reset3rdPartyStore(['topKeywords']);
    }
  }, [selectedClient, fetchTopKeywords, reset3rdPartyStore]);

  return retryFetchTopKeywords;
};

// Google campaign
export const useRetryFetchGoogleAds = () => {
  const {
    fetchCampainsReport,
    resetGoogleStore
  } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchGoogleAds = useCallback(() => {
    const client = selectedClient;
    const data = {
      customerId: client?.google_ads?.customer_id,
      startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      resetCache: false,
    };
    if (data.customerId && getStatus(client?.google_ads?.status)) {
      fetchCampainsReport(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      resetGoogleStore(['campaignsReport'])
    }
  }, [selectedClient, fetchCampainsReport, resetGoogleStore]);

  return retryFetchGoogleAds;
};

// FB insights
export const useRetryFetchFbInsights = () => {
  const { fetchAccountInsights, resetMetaStore } = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchFbInsights = useCallback(async () => {
    const data = {
      clientId: selectedClient.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
    };
    if (
      selectedClient.facebook_ads.ads_id &&
      ['activated', 'active'].includes(selectedClient.facebook_ads.status)
    ) {
      try {
        await Promise.all([fetchAccountInsights(data)]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore([
        'accountInsights',
        'adInsights',
        'adsetInsights',
      ]);
    }
  }, [selectedClient, fetchAccountInsights, resetMetaStore]);

  return retryFetchFbInsights;
};

// FB campaign
export const useRetryFetchFbCampaign = () => {
  const { resetMetaStore, fetchCampaignInsights} = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchFbCampaign = useCallback(async () => {
    const data = {
      clientId: selectedClient.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
    };
    if (
      selectedClient.facebook_ads.ads_id &&
      ['activated', 'active'].includes(selectedClient.facebook_ads.status)
    ) {
      try {
        await Promise.all([fetchCampaignInsights(data)]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore(['campaignInsights']);
    }
  }, [selectedClient, fetchCampaignInsights, resetMetaStore]);

  return retryFetchFbCampaign;
};

// FB adset insights
export const useRetryFetchFbAdsetInsights = () => {
  const { resetMetaStore, fetchAdsetInsights} = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchFbAdsetInsights = useCallback(async () => {
    const data = {
      clientId: selectedClient.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
    };
    if (
      selectedClient.facebook_ads.ads_id &&
      ['activated', 'active'].includes(selectedClient.facebook_ads.status)
    ) {
      try {
        await Promise.all([fetchAdsetInsights(data)]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore(['adsetInsights']);
    }
  }, [selectedClient, fetchAdsetInsights, resetMetaStore]);

  return retryFetchFbAdsetInsights;
};

// FB ad insights
export const useRetryFetchFbAdInsights = () => {
  const { resetMetaStore, fetchAdInsights} = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchFbAdInsights = useCallback(async () => {
    const data = {
      clientId: selectedClient.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
    };
    if (
      selectedClient.facebook_ads.ads_id &&
      ['activated', 'active'].includes(selectedClient.facebook_ads.status)
    ) {
      try {
        await Promise.all([fetchAdInsights(data)]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore(['adInsights']);
    }
  }, [selectedClient, fetchAdInsights, resetMetaStore]);

  return retryFetchFbAdInsights;
};

// fetchDemographics
export const useRetryFetchFbDemographics = () => {
  const { resetMetaStore, fetchDemographics} = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchFbDemographics = useCallback(async () => {
    const data = {
      clientId: selectedClient.id,
      since: startDate,
      until: moment(endDate).format('YYYY-MM-DD'),
      timeIncrement: 'monthly',
      level: 'campaign',
      limit: 100,
    };
    if (
      selectedClient.facebook_ads.ads_id &&
      ['activated', 'active'].includes(selectedClient.facebook_ads.status)
    ) {
      try {
        await Promise.all([fetchDemographics(data)]);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      resetMetaStore(['demographics']);
    }
  }, [selectedClient, fetchDemographics, resetMetaStore]);

  return retryFetchFbDemographics;
};

// Bing
export const useRetryFetchBingCampaign = () => {
  const { fetchBingCampainsReport, resetBingStore} = useBingStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);

  const retryFetchBingCampaign = useCallback(async () => {
    const data = {
      account: 'devops@onlinemarketinggurus.com.au',
      accountId: '141883435',
      startDate: '2023-05-01',
      endDate: '2024-05-31',
      resetCache: true,
    };
    if (getStatus(selectedClient?.seo_bing?.status) && data.accountId) {
      fetchBingCampainsReport(data).catch((error: unknown) => {
        console.log(error, 'error');
      });
    } else {
      resetBingStore(['campaignsReport']);
    }
  }, [selectedClient, fetchBingCampainsReport, resetBingStore]);

  return retryFetchBingCampaign;
};