import { useMemo } from 'react';
import { Card } from '@tremor/react';
import Tooltip from '../../../HelpTooltip';
import Icon from '../../../../assets/icons/SvgComponent';
import useClientStore from '../../../../store/useClientStore';
import useGoogleStore from '../../../../store/useGoogleStore';
import AnalyticsTable from '../../AnalyticsTable';
import MobileView from './mobileView';

const QualityScoreOverviewPPC = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useGoogleStore((state) => state);
  const keywordsResponse = campaignsReport?.keywordsView;

  const qualityScoreReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Keyword',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Keyword' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit pl-1 sm:pl-4">
                <div>Keyword</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Keyword' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit pl-1 sm:pl-4">
                <div>Keyword</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Keyword' || headerId === 'Keyword') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit pl-1 sm:pl-4">
                <div>Keyword</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[15vw] pl-1 sm:pl-4 h-fit">
            <div className="truncate w-[20vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Quality Score',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Quality Score' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Quality Score</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Quality Score' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Quality Score</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Quality Score' || headerId === 'Quality Score') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[8vw] h-fit">
                <div>Quality Score</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'quality_score',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[8vw] h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Creative Exp.',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Creative Exp.' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Creative Exp.</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Creative Exp.' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Creative Exp.</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Creative Exp.' || headerId === 'Creative Exp.') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Creative Exp.</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'creative_exp',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Landing Page Exp.',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Landing Page Exp.' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Landing Page Exp.</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Landing Page Exp.' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Landing Page Exp.</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Landing Page Exp.' ||
            headerId === 'Landing Page Exp.'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Landing Page Exp.</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'landing_page_exp',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {props.getValue()}
          </div>
        ),
      },
      {
        id: 'Exp. CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Exp. CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Exp. CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Exp. CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Exp. CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Exp. CTR' || headerId === 'Exp. CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Exp. CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr_exp',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {props.getValue()}
          </div>
        ),
      },

      // {
      //   id: 'Relevance',
      //   header: (props) => {
      //     const headerId = props?.table?.options?.state?.sorting[0]?.id;
      //     const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
      //     if (headerId === 'Relevance' && sortDirection === false) {
      //       return (
      //         <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
      //           <div>Relevance</div>
      //           <Icon name="ChevronUp" color="#808DA1" size={20} />
      //         </div>
      //       );
      //     }

      //     if (headerId === 'Relevance' && sortDirection === true) {
      //       return (
      //         <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
      //           <div>Relevance</div>
      //           <Icon name="ChevronDown" color="#808DA1" size={20} />
      //         </div>
      //       );
      //     }

      //     if (headerId !== 'Relevance' || headerId === 'Relevance') {
      //       return (
      //         <div className="flex justify-center items-center gap-2 font-inter w-[8vw] h-fit">
      //           <div>Relevance</div>
      //           <Icon name="Sort" />
      //         </div>
      //       );
      //     }
      //   },
      //   accessorKey: 'relevance',
      //   cell: (props: any) => (
      //     <div className="flex justify-center font-inter w-[8vw] h-fit">
      //       {props.getValue()}
      //     </div>
      //   ),
      // },
    ];

    let loading = true;

    const data = (keywordsResponse || []).map((resource: any) => {
      return {
        name: resource?.keyword,
        clicks: resource?.clicks,
        creative: resource?.historical_creative_quality_score,
        creative_exp: resource?.historical_creative_quality_score_label,
        landing_page: resource?.historical_landing_page_quality_score,
        quality_score: Number(resource?.historical_quality_score) || 0,
        landing_page_exp: resource?.historical_landing_page_quality_score_label,
        ctr_exp: resource?.historical_search_predicted_ctr_label,
      };
    });

    if (keywordsResponse) {
      loading = false;
    }

    return { tableHeader, data, loading };
  }, [keywordsResponse]);

  return (
    <>
      <div className="block sm:hidden">
        <MobileView
          campaignsReport={campaignsReport}
          qualityScoreReportData={qualityScoreReportData}
        />
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-between items-center">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
            Quality Score Overview
          </h2>
          <div className="sm:pr-4">
            <Tooltip
              content={
                selectedClient?.google_ads?.customer_id
                  ? `Customer ID: ${selectedClient?.google_ads?.customer_id}`
                  : 'N/A'
              }
              position="top"
              textAlign="text-center"
            >
              <div className="pt-1">
                <Icon name="googleAd" size={20} />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="mt-8">
          {qualityScoreReportData?.data?.length === 0 &&
          !campaignsReport.loading ? (
            <Card className="card-item px-1 sm:px-6">
              <div className="flex flex-col justify-center w-full items-center my-8 h-[148px]">
                <div className="mb-8">
                  <Icon name="NoDataAdGroup" />
                </div>
                <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                  No quality scores available at this moment.
                </p>
                <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
                  Stay informed with ongoing trends and insights in ad quality.
                  Our dedicated section ensures you're updated with crucial
                  developments and strategies for maintaining high-quality
                  scores.
                </p>
              </div>
            </Card>
          ) : (
            <AnalyticsTable
              data={qualityScoreReportData?.data}
              columns={qualityScoreReportData?.tableHeader}
              loading={campaignsReport.loading}
              totalDocs={
                qualityScoreReportData?.data
                  ? qualityScoreReportData?.data.length
                  : 'N/A'
              }
              sortByColumnId="Creative"
              pagination={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default QualityScoreOverviewPPC;
