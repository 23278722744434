import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';

import useClientStore from '../../../store/useClientStore';
import useMetaStore from '../../../store/useMetaStore';
import useAuthStore from '../../../store/useAuthStore';
import { FadeLoader } from 'react-spinners';

import { getAuthData, login } from '../../../utils/fbSdk';

interface FBModalProps {
  onClose?: () => void;
  data: any;
}

const FBModal: React.FC<FBModalProps> = ({ onClose, data }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const [saveError, setSaveError] = useState(false);
  const { setFBAuth, fbAuth, user } = useAuthStore((state) => state);
  const { activateClientFbAcc, fbAdsAccounts, fetchFbAdsAccounts } =
    useMetaStore((state) => state);
  const errorMessage = fbAdsAccounts[0]?.error;
  const adsAccounts = !errorMessage
    ? fbAdsAccounts?.map((item: any) => {
        return { label: item.name, id: item.id };
      })
    : [];
  const formattedAccounts = adsAccounts?.map((item: any) => ({
    value: item.id,
    label: item.label,
  }));
  const fb_acc_id = selectedClient?.facebook_ads?.ads_id || '';
  const fb_acc_label =
    (adsAccounts &&
      adsAccounts?.filter((item: any) => item.id === fb_acc_id)[0]?.label) ||
    'Select Account';
  const [selectedAcc, setSelectedAcc] = useState({
    label: fb_acc_label,
    value: fb_acc_id,
  });

  const defaultStatus = data?.status === 'activated' ? true : false;
  const [status, setStatus] = useState<boolean>(defaultStatus);

  useEffect(() => {
    if (fbAuth) {
      getFBAccounts(fbAuth);
    } else {
      checkFbStatus();
    }
  }, []);

  useEffect(() => {
    setSelectedAcc({ label: fb_acc_label, value: fb_acc_id });
  }, [fb_acc_label]);

  const getFBAccounts = (params: any) => {
    fetchFbAdsAccounts(params);
  };

  const handleSave = (e: any) => {
    // if (isDisabled) {
    //   setSaveError("Permission Denied!");
    //   return;
    // }
    e.preventDefault();
    if (selectedAcc.value) {
      const data = {
        accessToken: fbAuth.accessToken,
        clientId: selectedClient.id,
        accountId: selectedAcc.value,
        status: status ? 'activated' : 'inactive',
      };
      activateClientFbAcc(data);
      onClose && onClose();
      setTimeout(() => {
        fetchClient(selectedClient?.id);
      }, 500);
    }
  };

  const checkFbStatus = async () => {
    const res = await getAuthData();
    if (res.status === 'connected') {
      const { accessToken, userID } = res.authResponse;
      const params = {
        accessToken,
        userID,
      };
      setFBAuth(params);
      getFBAccounts(params);
    } else {
      const res = await login();
      const accessToken = res?.authResponse?.accessToken;
      const userID = res?.authResponse?.userID;
      const params = {
        accessToken,
        userID,
      };
      setFBAuth(params);
      getFBAccounts(params);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-200 rounded-lg p-2">
            <Icon name="Meta" color={mode === 'dark' ? '#fff' : iconColor} />
          </div>
          <div className="text-[24px] dark:text-white">Facebook Ad</div>
        </div>
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] flex gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        {adsAccounts && adsAccounts.length <= 0 ? (
          <div>
            <button
              onClick={checkFbStatus}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-[30%]"
            >
              Login to Facebook
            </button>
            <div className="flex justify-between mt-12 border-t pt-12">
              <div className="dark:text-white">Integration Status</div>
              <div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    defaultChecked={status}
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <div className="w-28 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-[5.5rem] rtl:peer-checked:after:-translate-x-[5.5rem] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                  <div
                    className={`w-28 h-6 absolute top-1 left-[30%] text-xs ${status ? 'text-white' : ''}`}
                  >
                    {status ? 'Enabled' : 'Disabled'}
                  </div>
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="mt-[24px] pt-[24px] border-t mb-[16px]">
              <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
                Facebook Account
              </p>
              <ReactSelect
                options={formattedAccounts}
                value={selectedAcc}
                handleOnChange={setSelectedAcc}
                placeholder="Select Account"
              />
            </div>
          </div>
        )}

        {adsAccounts && adsAccounts.length > 0 ? (
          <div>
            <button
              onClick={(e) => handleSave(e)}
              disabled={!selectedAcc.value || adsAccounts.length <= 0}
              className="text-white bg-[#005C9F] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Connect
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FBModal;
