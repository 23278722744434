import { useMemo } from 'react';
import { Card } from '@tremor/react';
import { formatNumberWithCommas } from '../../../utils/common';
import AnalyticsTable from '../AnalyticsTable';
import Tooltip from '../../HelpTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import InfoTooltip from '../AnalyticsInfoTooltip';
interface IProps {
  isLoading?: boolean;
  data?: any;
  error?: string;
  propertyId?: string;
  retryMethod?: () => void;
}

const Pages = ({ isLoading, data, error, propertyId, retryMethod }: IProps) => {
  const pageResponse = data?.pages || [];
  const pagesReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Page',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Page' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Page' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Page' || headerId === 'Page') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[36vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate max-w-[150px] sm:max-w-[36vw] overflow-hidden dark:text-white">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Impressions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impressions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[10vw] h-fit">
                <div>Impressions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impressions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[10vw] h-fit">
                <div>Impressions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impressions' || headerId === 'Impressions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[10vw] h-fit">
                <div>Impressions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[10vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'Position',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Position' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Position' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Position' || headerId === 'Position') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'position',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
    ];

    const data = (pageResponse || []).map((resource: any) => {
      return {
        page: resource?.page,
        impressions: resource?.impressions,
        clicks: resource?.clicks,
        ctr: resource?.ctr,
        position: resource?.position,
      };
    });

    return { tableHeader, data };
  }, [pageResponse]);

  const renderHeading = () => {
    return (
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center mb-4">
          <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
            {data?.pages?.label ? data?.pages?.label : `Pages`}
          </h2>
          <InfoTooltip
            content={data?.pages?.info ? data?.pages?.info : `Pages`}
            position="top"
          >
            <Icon name="InfoCircle" size={14} />
          </InfoTooltip>
        </div>
        <Tooltip
          content={propertyId ? propertyId : 'N/A'}
          position="top"
          textAlign="text-center"
        >
          <div className="pt-1">
            <Icon name="googleSearch" size={20} />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <Card className="mt-10 dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
      {renderHeading()}
      <AnalyticsTable
        data={pagesReportData?.data ? pagesReportData?.data : []}
        columns={pagesReportData?.tableHeader}
        loading={data?.loading}
        totalDocs={pagesReportData?.data ? pagesReportData?.data.length : 'N/A'}
        sortByColumnId="Impressions"
        error={error}
        retryMethod={retryMethod}
      />
    </Card>
  );
};

export default Pages;
