import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

const dynamicSubReason = (cancelReason) => {
  switch (cancelReason) {
    case 'Death In The Market':
      return [
        { value: 'In House', label: 'In House' },
        { value: 'Business Windup', label: 'Business Windup' },
        { value: 'Aquisition', label: 'Aquisition' },
        { value: 'Regulatory Change', label: 'Regulatory Change' },
        {
          value: 'Personal Circumstances Changed',
          label: 'Personal Circumstances Changed',
        },
      ];
      break;
    case 'Price':
      return [
        { value: 'Too Expensive', label: 'Too Expensive' },
        { value: 'Budget Re-Allocated', label: 'Budget Re-Allocated' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        {
          value: 'Insufficient Budget',
          label: 'Insufficient Budget for Successful Advertising',
        },
      ];
      break;
    case 'Product':
      return [
        { value: 'Insufficient ROI', label: 'Insufficient ROI' },
        { value: 'Speed of Service', label: 'Speed of Service' },
        { value: 'Quality of Product', label: 'Quality of Product' },
        { value: 'Results Achieved', label: 'Results Achieved' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        { value: 'Error in Service', label: 'Error in Service' },
        { value: 'Poor Performance', label: 'Poor Performance' },
        {
          value: 'Incorrect Service Line Sold',
          label: 'Incorrect Service Line Sold',
        },
      ];
      break;
    case 'People':
      return [
        { value: 'Personality Clash', label: 'Personality Clash' },
        { value: 'Change of POC', label: 'Change of POC' },
        { value: 'Responsiveness', label: 'Responsiveness' },
      ];
      break;
    case 'Other':
      return [
        { value: 'Website Not Active', label: 'Website Not Active' },
        {
          value: 'CMS Platform Suitability',
          label: 'CMS Platform Suitability',
        },
        { value: 'Management Only', label: 'Management Only (Do Not Use)' },
      ];
      break;
    case 'Service Swap':
      return [
        { value: 'N/A', label: 'N/A' }
      ];
      break;  
  }
};

// SEO CANCEL EXPORT
export const SEOCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.seo_google.start_date !== '') {
    startDate = client.seo_google.start_date;
  } else {
    startDate = '';
  }
  if (client.seo_google.end_date !== '') {
    endDate = client.seo_google.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'seo_google',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelRankRanger: '',
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.seo_google.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelBudget,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelRankRanger,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          SEO GOOGLE Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When should the client be removed from Rank Ranger?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRankRanger')}
                value={cancelRankRanger}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PPC CANCEL EXPORT
export const PPCCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.ppc_google.start_date !== '') {
    startDate = client.ppc_google.start_date
  } else {
    startDate = '';
  }
  if (client.ppc_google.end_date !== '') {
    endDate = client.ppc_google.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'ppc_google',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.ppc_google.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PPC GOOGLE Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// SEO MICROSOFT CANCEL EXPORT
export const SEOBINGCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.seo_bing.start_date !== '') {
    startDate = client.seo_bing.start_date;
  } else {
    startDate = '';
  }
  if (client.seo_bing.end_date !== '') {
    endDate = client.seo_bing.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'seo_bing',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelRankRanger: '',
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.seo_bing.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelBudget,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelRankRanger,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Microsoft SEO Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When should the client be removed from Rank Ranger?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRankRanger')}
                value={cancelRankRanger}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PPC MICROSOFT CANCEL EXPORT
export const PPCBINGCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_bing.start_date !== '') {
    startDate = client.social_bing.start_date
  } else {
    startDate = '';
  }
  if (client.social_bing.end_date !== '') {
    endDate = client.social_bing.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_bing',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_bing.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Microsoft Ads Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// REM cancel EXPORT
export const REMCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.display.start_date !== '') {
    startDate = client.display.start_date
  } else {
    startDate = '';
  }
  if (client.display.end_date !== '') {
    endDate = client.display.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'display',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.display.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Remarketing Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// META cancel EXPORT
export const METACancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_meta.start_date !== '') {
    startDate = client.social_meta.start_date
  } else {
    startDate = '';
  }
  if (client.social_meta.end_date !== '') {
    endDate = client.social_meta.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_meta',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_meta.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          META Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PINTEREST cancel EXPORT
export const PINTERESTCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_pinterest.start_date !== '') {
    startDate = client.social_pinterest.start_date
  } else {
    startDate = '';
  }
  if (client.social_pinterest.end_date !== '') {
    endDate = client.social_pinterest.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_pinterest',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_pinterest.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PINTEREST Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// TIKTOK cancel EXPORT
export const TIKTOKCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_tiktok.start_date !== '') {
    startDate = client.social_tiktok.start_date
  } else {
    startDate = '';
  }
  if (client.social_tiktok.end_date !== '') {
    endDate = client.social_tiktok.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_tiktok',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_tiktok.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          TIKTOK Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// LinkedIn cancel EXPORT
export const LINKEDINCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_linkedin.start_date !== '') {
    startDate = client.social_linkedin.start_date
  } else {
    startDate = '';
  }
  if (client.social_linkedin.end_date !== '') {
    endDate = client.social_linkedin.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_linkedin',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_linkedin.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          LINKEDIN Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Creative cancel EXPORT
export const CREATIVECancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_creative.start_date !== '') {
    startDate = client.social_creative.start_date
  } else {
    startDate = '';
  }
  if (client.social_creative.end_date !== '') {
    endDate = client.social_creative.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_creative',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_creative.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          CREATIVE Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Tactical cancel EXPORT
export const TACTICALCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_tactical.start_date !== '') {
    startDate = client.social_tactical.start_date
  } else {
    startDate = '';
  }
  if (client.social_tactical.end_date !== '') {
    endDate = client.social_tactical.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_tactical',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_tactical.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          TACTICAL Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Tactical cancel EXPORT
export const PROGRAMMATICCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.social_programmatic.start_date !== '') {
    startDate = client.social_programmatic.start_date
  } else {
    startDate = '';
  }
  if (client.social_programmatic.end_date !== '') {
    endDate = client.social_programmatic.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'social_programmatic',
    cancelRequestDate: '',
    cancelEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    cancelAds: '',
    cancelAdsDate: '',
    cancelAdsNotes: '',
    contractFinishDate: endDate,
    cancelBudget: client.social_programmatic.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelUnder30days,
    cancelAds,
    cancelAdsDate,
    cancelAdsNotes,
    cancelBudget,
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PROGRAMMATIC Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelAdsDate')}
                      value={cancelAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelAdsNotes')}
                      value={cancelAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEBDEV cancel EXPORT
export const WEBDEVCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.web_maintenance.start_date !== '') {
    startDate = client.web_maintenance.start_date
  } else {
    startDate = '';
  }
  if (client.web_maintenance.end_date !== '') {
    endDate = client.web_maintenance.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'web_maintenance',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.web_maintenance.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelBudget
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Maintenance Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Maintenance Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEBHOST cancel EXPORT
export const WEBHOSTCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.web_hosting.start_date !== '') {
    startDate = client.web_hosting.start_date
  } else {
    startDate = '';
  }
  if (client.web_hosting.end_date !== '') {
    endDate = client.web_hosting.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'web_hosting',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.web_hosting.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelBudget
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Hosting Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEB LANDING cancel EXPORT
export const WEBLANDINGCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.web_landing.start_date !== '') {
    startDate = client.web_landing.start_date
  } else {
    startDate = '';
  }
  if (client.web_landing.end_date !== '') {
    endDate = client.web_landing.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'web_landing',
    cancelHostingDate: '',
    cancelHostingEffectDate: '',
    cancelMaintenanceDate: '',
    cancelMaintenanceEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.web_landing.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelBudget
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Landing Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEB PROJECT cancel EXPORT
export const WEBPROJECTCancel = ({ parentCallback, client }) => {
  var startDate, endDate, finalAsClient, finalDaysLeft;
  if (client.web_project.start_date !== '') {
    startDate = client.web_project.start_date
  } else {
    startDate = '';
  }
  if (client.web_project.end_date !== '') {
    endDate = client.web_project.end_date;
  } else if(client.contract_finish_date !== ''){
    endDate = client.contract_finish_date;
  }else{
    endDate = '';
  }
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [cancelValues, setValues] = useState({
    type: 'cancel',
    service: 'web_project',
    cancelHostingDate: '',
    cancelHostingEffectDate: '',
    cancelMaintenanceDate: '',
    cancelMaintenanceEffectDate: '',
    cancelReason: '',
    cancelSubReason: '',
    cancelAmComments: '',
    cancelSaveComments: '',
    cancelClientComments: '',
    cancelDoneBetterComments: '',
    cancelNoteComments: '',
    cancelStartDate: startDate,
    cancelContractLeft: '',
    cancelContractLeftTip: finalDaysLeft,
    cancelReactivate: '',
    cancelReactivateDate: '',
    cancelReactivateNotes: '',
    cancelUnder30days: false,
    contractFinishDate: endDate,
    cancelBudget: client.web_project.revenue,
    cancelTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    cancelRequestDate,
    cancelEffectDate,
    cancelReason,
    cancelSubReason,
    cancelAmComments,
    cancelSaveComments,
    cancelClientComments,
    cancelDoneBetterComments,
    cancelNoteComments,
    cancelStartDate,
    cancelContractLeft,
    cancelContractLeftTip,
    cancelReactivate,
    cancelReactivateDate,
    cancelReactivateNotes,
    contractFinishDate,
    cancelBudget
  } = cancelValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...cancelValues, [name]: value });

    if (cancelRequestDate !== '' && cancelEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(cancelEffectDate) - Date.parse(cancelRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...cancelValues, [name]: value, cancelUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...cancelValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(cancelValues);
  }, [cancelValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Project Cancel Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of cancel request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelRequestDate')}
                value={cancelRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelEffectDate')}
                value={cancelEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the cancel
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                  { value: 'Service Swap', label: 'Service Swap'}
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('cancelSubReason')}
                  options={dynamicSubReason(cancelReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelAmComments')}
                value={cancelAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelSaveComments')}
                value={cancelSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('cancelClientComments')}
                value={cancelClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelDoneBetterComments')}
                value={cancelDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('cancelNoteComments')}
                value={cancelNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('cancelStartDate')}
                value={cancelStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('cancelContractLeft')}
                value={cancelContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('cancelReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {cancelReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('cancelReactivateDate')}
                      value={cancelReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('cancelReactivateNotes')}
                      value={cancelReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={cancelBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
