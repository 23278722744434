import { useState } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import GoogleAd from '../../assets/images/googleAd.png';
import GoogleSEO from '../../assets/images/googleSEO.png';
import BingSEO from '../../assets/images/bingSEO.png';
import BingAds from '../../assets/images/bingAds.png';
import MetaAds from '../../assets/images/facebookAds.png';
import TiktokAds from '../../assets/images/tiktokAds.png';
import LinkedInAds from '../../assets/images/linkedInAds.png';
import Pinterest from '../../assets/images/pinterest.png';

type ServicesHeaderProps = {
  selectedClient?: any;
};

const Services: React.FC<ServicesHeaderProps> = ({ selectedClient }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : '#001C44';

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const connectedApps = [
    {
      title: 'Google SEO',
      description: `Enhance website visibility on Google search results through optimization techniques.`,
      icon: GoogleSEO,
      details: selectedClient?.seo_google,
      requested: true,
    },
    // {
    //   title: 'Bing SEO',
    //   description: `Improve your websites ranking on Bing's search engine results page.`,
    //   icon: BingSEO,
    //   requested: true,
    // },
    {
      title: 'Google Ads',
      description: `Create, manage, and track paid advertising campaigns on Googles platform.`,
      icon: GoogleAd,
      details: selectedClient?.ppc_google,
      requested: true,
    },
    {
      title: 'Microsoft Ads',
      description: `Develop and monitor targeted advertising campaigns on Bing's network.`,
      icon: BingAds,
      details: selectedClient?.social_bing,
      requested: false,
    },
    {
      title: 'Meta Ads',
      description: `Manage advertising campaigns effectively across Facebook and Instagram platforms`,
      icon: MetaAds,
      details: selectedClient?.social_meta,
      requested: true,
    },
    {
      title: 'Tiktok Ads',
      description: `Launch and track advertising campaigns targeting TikTok's youthful audience.`,
      icon: TiktokAds,
      details: selectedClient?.social_tiktok,
      requested: false,
    },
    {
      title: 'LinkedIn Ads',
      description: `Develop targeted advertising campaigns for a professional audience on LinkedIn`,
      icon: LinkedInAds,
      details: selectedClient?.social_linkedin,
      requested: false,
    },
    {
      title: 'Pinterest',
      description: `Create and analyze visual advertising campaigns on Pinterest platform.`,
      icon: Pinterest,
      details: selectedClient?.social_pinterest,
      requested: true,
    },
  ];

  return (
    <div className="w-full bg-white p-[26px]  mb-[32px] dark:bg-gray-800 rounded-lg">
      <div className="flex justify-between mb-8">
        <div>
          <h1 className="text-xl font-semibold dark:text-[#fff]">Services</h1>
        </div>
      </div>
      {connectedApps?.map((app, index) => (
        <div key={index} className="border-b border-gray-200 py-4">
          <div className="flex justify-between items-center ">
            {/* App details content */}
            <div className="flex gap-2 justify-between items-center">
              <div className="bg-gray-100 p-2 rounded-lg">
                <img src={app.icon} alt={app.title} width={30} />
              </div>
              <div className="flex flex-col justify-center gap-1">
                <h1 className="text-base font-semibold dark:text-white">
                  {app.title}
                </h1>
                <p className="text-xs font-base text-gray-600 dark:text-gray-400">
                  {app.description}
                </p>
              </div>
            </div>
            {app?.details?.status === 'deactivated' ||
            app?.details?.status === 'inactive' ? (
              <div>
                <button
                  type="button"
                  className="flex gap-2 text-white bg-[#D92D20] hover:bg-[#D92D20] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Deactivated
                  <Icon name="AlertCircle" color="#fff" />
                </button>
              </div>
            ) : app?.details?.status === 'activated' ||
              app?.details?.status === 'active' ? (
              <div className="flex gap-2">
                {expandedIndex !== index ? (
                  <>
                    <button
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      className="flex gap-2 py-2.5 px-5 text-sm font-medium text-[#005C9F] focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      View more
                    </button>
                    <button
                      type="button"
                      className="flex gap-2 text-white bg-[#005C9F] hover:bg-[#005C9F] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Activated
                      <Icon name="RequestedCheck" color="#fff" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      className="flex gap-2 py-2.5 px-5 text-sm font-medium text-[#005C9F] focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Collapse
                    </button>
                    <button
                      type="button"
                      className="flex gap-2 text-white bg-[#005C9F] hover:bg-[#005C9F] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Activated
                      <Icon name="RequestedCheck" color="#fff" />
                    </button>
                  </>
                )}
              </div>
            ) : app?.details?.status === 'requested' ? (
              <button
                type="button"
                className="flex gap-2 items-center text-[#005C9F] bg-[#E6EFF5] hover:bg-white hover:border font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-300 dark:hover:bg-blue-100"
              >
                Activated
                <Icon name="RequestedBell" color="#005C9F" />
              </button>
            ) : null}
          </div>
          {expandedIndex === index && (
            <div className="mt-2 transition-transform duration-300 ease-in-out  overflow-hidden">
              <div className="font-inter text-xs text-gray-600 dark:text-gray-400 px-[55px] flex gap-4">
                <p>
                  Budget:{' '}
                  {app.details?.budget ? `$${app.details?.budget}` : 'N/A'}
                </p>
                <p>
                  Revenue:{' '}
                  {app.details?.revenue ? `$${app.details?.revenue}` : 'N/A'}
                </p>
                <p>
                  Start Date:{' '}
                  {app.details?.start_date ? app.details?.start_date : 'N/A'}
                </p>
                <p>
                  End Date:{' '}
                  {app.details?.end_date ? app.details?.end_date : 'N/A'}
                </p>
                <p>
                  Cancellation Date:{' '}
                  {app.details?.cancellation_date
                    ? app.details?.cancellation_date
                    : 'N/A'}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default Services;
