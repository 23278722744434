export type PageName =
  | 'dashboard'
  | 'analytics'
  | 'goals'
  | 'reports'
  | 'settings'
  | 'chat'
  | 'feedback'
  | 'referrals'
  | 'home';
export const getPageTabs = (page: PageName, service?: string) => {
  const lowercase = page.toLocaleLowerCase();
  // Set default service for "reports" if not provided
  if (lowercase === 'reports' && !service) {
    service = 'seo';
  }

  if (lowercase === 'dashboard')
    return [
      // { label: 'Overview', path: '#overview' },
      // { label: 'Communication', path: '#communication' },
      // { label: "Wins", path: "wins" },
      // { label: 'Tasks', path: '#tasks' },
      // { label: "News", path: "news" },
      // { label: 'Services', path: '#services' },
    ];
  else if (lowercase === 'analytics') return [];
  else if (lowercase === 'goals')
    return [
      //   { label: "Goals", path: "goals" },
      //   { label: "Reports", path: "reports" },
      //   { label: "Settings", path: "settings" },
    ];
  else if (lowercase === 'client details')
    return [
      { label: 'Users', path: 'users' },
      { label: 'Teams', path: 'teams' },
      { label: 'Reporting', path: 'reporting' },
      { label: 'Integrations', path: 'integrations' },
      { label: 'Services', path: 'services' },
      { label: 'Changes', path: 'changes' },
    ];
  else if (lowercase === 'client access')
    return [
      { label: 'all clients', path: 'client-access/all-clients' },
      { label: 'send request', path: 'client-access/send-request' },
      { label: 'pending request', path: 'client-access/pending-request' },
      { label: 'completed request', path: 'client-access/completed-request' },
    ];
  else if (lowercase === 'reports' && service === 'seo')
    return [
      // { label: 'Overview', path: '#overview' },
      // { label: 'Traffic and Conversion', path: '#traffic-and-conversion' },
      // { label: 'Keyword ranking', path: '#keywords-ranking' },
      // { label: 'Worked Completed & Planned', path: '#worked-completed' },
      // { label: 'Link Building', path: '#link-building' },
    ];
  else if (
    lowercase === 'reports' &&
    (service === 'ppc' || service === 'social')
  )
    return [
      // { label: 'Overview', path: '#overview' },
      // { label: 'Opportunities', path: '#opportunities' },
      // { label: 'Performance', path: '#performance' },
      // { label: 'Worked Completed & Planned', path: '#worked-completed' },
    ];
  else if (lowercase === 'settings')
    return [
      { label: 'My Details', path: 'settings/my-details' },
      // { label: "Profile Notification", path: "settings/profile-notification" },
      { label: 'Team', path: 'settings/team' },
      // { label: 'Plans Billing', path: 'settings/billing' },
      { label: 'Services', path: 'settings/services' },
    ];
  else if (lowercase === 'chat')
    return [
      //   { label: "Overview", path: "overview" },
      //   { label: "Goals", path: "goals" },
      //   { label: "Reports", path: "reports" },
      //   { label: "Settings", path: "settings" },
    ];
  else if (lowercase === 'feedback')
    return [
      //   { label: "Overview", path: "overview" },
      //   { label: "Goals", path: "goals" },
      //   { label: "Reports", path: "reports" },
      //   { label: "Settings", path: "settings" },
    ];
  else if (lowercase === 'home')
    return [
      // { label: 'Tasks', path: '#teamworkTasks' },
      // { label: 'News', path: '#news' },
      // { label: 'Revenue Under Manager', path: '#revenueManager' },
      // { label: 'Customers', path: '#customers' },
    ];
  else if (lowercase === 'omg settings')
    return [
      { label: 'Profile', path: 'omg-settings/profile' },
      // { label: 'Logs', path: 'omg-settings/logs' },
      { label: 'Tokens', path: 'omg-settings/security' },
      // { label: 'Syncing', path: 'omg-settings/syncing' },
      { label: 'Change Requests', path: 'omg-settings/change-requests' },
    ];
  else return [];
};

export const getPageTitle = (pathname: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let activePage: string;
  if (pathname === '/performance') {
    activePage = 'performance';
  } else if (pathname.includes('/performance-insights')) {
    activePage = 'performance-insights';
  } else if (pathname.includes('/client/analytics/')) {
    activePage = 'client analytics';
  } else if (pathname.includes('/client/details/')) {
    activePage = 'client details';
  } else {
    activePage = pathname.split('/')[1].replace('-', ' ');
  }
  return activePage;
};
