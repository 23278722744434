import moment from 'moment';
import {
  typeObj,
  cycleObj,
} from '../components/ReportTanStackTable/staticData';

export const getReportStatus = (type) => {
  let statusOptions = [];
  if (type === 'scheduler') {
    statusOptions = [
      { label: 'Approved', id: 'approved' },
      { label: 'Scheduled', id: 'scheduled' },
      { label: 'Sent', id: 'sent' },
    ];
  } else if (type === 'reporting') {
    statusOptions = [
      { label: 'Draft', id: 'draft' },
      { label: 'Progress', id: 'in_progress' },
      { label: 'Rejected', id: 'rejected' },
    ];
  } else if (type === 'approval') {
    statusOptions = [
      { label: 'Review', id: 'review' },
      { label: 'Validated', id: 'validated' },
      { label: 'Rejected', id: 'rejected' },
    ];
  }

  return statusOptions;
};

export const getHealthStatus = (form) => {
  const defaultHealthStatus = [
    { val: 'gray', role: 'am' },
    { val: 'gray', role: 'const' },
  ];
  const formStatus = form?.health_status || [];
  const statusLength = formStatus.length;

  if (statusLength === 1) {
    const [status] = formStatus;
    if (status?.role?.includes('con')) {
      return [{ val: 'gray', role: 'am' }, status];
    } else {
      return [status, { val: 'gray', role: 'const' }];
    }
  } else if (statusLength === 2) {
    return formStatus;
  } else {
    return defaultHealthStatus;
  }
};

export const isApprovalUser = (user) => {
  const staticEmails = ['simon@onlinemarketinggurus.com.au'];
  if (
    ['exec', 'admin', 'fo', 'gad', 'ad', 'psm'].includes(user?.gurulytics_role?.id) ||
    staticEmails.includes(user?.email)
  ) {
    return true;
  } else return false;
};

export const isValidatorUser = (user) => {
  if (
    [
      'exec',
      'fo',
      'am',
      'ad',
      'gad',
      'seoman',
      'adsman',
      'socman',
      'admin',
      'psm',
    ].includes(user?.gurulytics_role?.id)
  ) {
    return true;
  } else return false;
};

export const getUserReportType = (user) => {
  if (['ppcman', 'ppccon'].includes(user?.user?.role?.id))
    return typeObj['ppc_google'];
  else if (['socman', 'soccon'].includes(user?.user?.role?.id))
    return typeObj['social_meta'];
  else return typeObj['seo_google'];
};

export const getCurrentCycle = () => {
  const currentDate = moment();
  const dayOfMonth = currentDate.date();
  const isMidmonth = dayOfMonth >= 10 && dayOfMonth <= 25;

  return isMidmonth ? cycleObj['Cycle 2'] : cycleObj['Cycle 1'];
};

export const getHealthStatusRole = (role) => {
  if (['seocon', 'ppccon', 'soccon'].includes(role)) return role;
  else return 'am';
};

export const getTypePrefix = (type) => {
  const underscoreIndex = type.indexOf('_');
  if (underscoreIndex !== -1) {
    return type.substring(0, underscoreIndex);
  } else {
    // Handle cases where no underscore is found
    return type;
  }
};
