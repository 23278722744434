import { requests } from "./ApiService";

export const ChangeRequest = {
  getChanges: (filters) =>
    requests.get(
      `/api/v1/change-requests?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&perms=${filters.perms}&sort_by=${filters.sortBy}&sort_order=${filters.sortOrder}`
    ),
  getHistory: (filters) =>
    requests.get(
      `/api/v1/change-requests/history?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&sort_by=${filters.sortBy}&sort_order=${filters.sortOrder}`
  ),
  getChangeReports: (filters) =>
    requests.get(
      `/api/v1/change-requests/reports?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&sort_by=${filters.sortBy}&sort_order=${filters.sortOrder}`
  ),
  getChangeHistoryReports: (filters) =>
    requests.get(
      `/api/v1/change-requests/history-reports?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`
  ),
  addChange: (data) => requests.post('/api/v1/change-requests/add', data),
  approveChange: (data) =>
    requests.patch(`/api/v1/change-requests/approve`, data),
  removeChange: (data) =>
    requests.delete('/api/v1/change-requests/remove', data),
};

export const NotifierSettings = {
  getNotifiers: () => requests.get(`/api/v1/notifier-staff`),
  amendNotifiers: (data:any) => requests.post('/api/v1/notifier-staff/amend', data),
  syncIDs: (data:any) => requests.post('/api/v1/notifier-staff/sync', data),
};