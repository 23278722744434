import React, { useState, useMemo, useEffect } from 'react';
import GoalsImg from '../../assets/images/goals.png';
import Icon from '../../assets/icons/SvgComponent';
import CustomModal from '../../components/CustomModal';
import CreateGoalsModal from '../../components/Modals/Goals';
import SearchBar from '../../components/Search';
import ReactSelect from '../../components/ReactSelect';
import TansStackTableV2 from '../../components/TansStackTableV2';
import GoalCard from '../../components/Goals/GoalCard';
import { generateColumns } from './column';
import moment from 'moment';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Card, Grid, Col } from '@tremor/react';
import useGoalStore from '../../store/useGoalStore';
import { useLocation } from 'react-router-dom';
import {
  frequency,
  getStatus,
  getStatusMetric,
  metrics,
  statuses,
} from './constants';

const Goals: React.FC = () => {
  const location = useLocation();
  const { fetchGoals, goals, loading } = useGoalStore((state) => state);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [mount, setMount] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [openCreateGoals, setOpenCreateGoals] = useState(false);
  // const { selectedClient } = useClientStore((state) => state);
  const [goal, setGoal] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [filterGoal, setFilterGoal] = useState<any>({});
  const [selectedGoal, setSelectedGoal] = useState<any>({});
  // console.log(selectedClient,'selectedClient')

  const editGoal = (item: any) => {
    setSelectedGoal(item);
    setOpenCreateGoals(true);
  };

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        editGoal,
      }),
    [sortingColumn, sortingDirection, editGoal]
  );

  const chartdata = [
    {
      date: '15 Jan',
      Running: 167,
    },
    {
      date: '22 Jan',
      Running: 125,
    },
    {
      date: '29 Jan',
      Running: 156,
    },
    {
      date: '5 Feb',
      Running: 165,
    },
    {
      date: '12 Feb',
      Running: 153,
    },
  ];

  const handleSearch = () => {
    handleFilterChange();
  };

  const handleFilterChange = async (filter?: any) => {
    const payload = {
      limit,
      page,
      search: searchText,
      metric: filterGoal?.metric || filter?.metric,
      frequencyType: filterGoal?.frequencyType || filter?.frequencyType,
      status: filterGoal?.status || filter?.status,
      client_id: filter?.client_id,
    };
    fetchGoals(payload);
  };

  useEffect(() => {
    if (searchText === '' && mount) {
      handleFilterChange();
    }
  }, [searchText]);

  useEffect(() => {
    if(filterGoal)
      handleFilterChange(filterGoal);
  },[filterGoal])

  // useEffect(() => {
  //   handleFilterChange()
  // },[]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageUrl = queryParams.get('page');

    setMount(true);
    setPage(Number(pageUrl) === 0 ? 1 : Number(pageUrl));
  }, [location]);

  useEffect(() => {
    if (mount) {
      handleFilterChange();
    }
  }, [page, limit]);

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      const currentDate = moment(new Date());
      const tableData = data?.map((item) => {
        const baseDate: any = moment(
          item.frequency[0]?.targetDate,
          'DD-MM-YYYY'
        );

        return {
          ...item,
          number: {
            goalValue: item.frequency[0].goalValue,
            currentValue: item.frequency[0].currentValue,
          },
          goalLimit: {
            startDate: item?.goalStartDate,
            endDate: item?.goalEndDate,
          },
          client: item?.client_id?.name || "",
          goalSetValue: item.frequency[0].goalValue,
          achieveGoalBy: moment(baseDate).format('MMMM YYYY'),
          dateUntilTarget: baseDate.diff(currentDate, 'days') || 0,
          metricDisplay: getStatusMetric(item.metric),
          status: getStatus(item.status),
          goalName: item.name,
        };
      });
      return tableData;
    };
    const formattedData = goals?.data?.length > 0 && formatData(goals?.data);
    return formattedData;
  }, [goals]);

  return (
    <>
      {goals?.loading ? (
        <div className="px-10 py-4">
          <Card className="h-[86dvh]">
            <div className="flex items-center justify-center gap-[8rem] h-full">
              <div className="text-[#001C44] flex flex-col gap-4">
                <div>
                  <Icon name="target" />
                </div>
                <h1 className="text-[34px] font-bold">Set and Track Goal</h1>
                <p className="max-w-[500px]">
                  Have trouble setting and tracking goals for your business,
                  your team or yourself? Set time-bound, numeric goals and track
                  progress automatically.
                </p>
                <div className="font-bold">
                  <button
                    className="rounded-full bg-[#D0EDFF] py-2 px-4 text-[16px] mr-2"
                    onClick={() => setOpenCreateGoals(true)}
                  >
                    Create your first goal
                  </button>
                  <button className="rounded-full border border-1 py-2 px-4 text-[16px] hover:bg-[#D0EDFF]">
                    Explore Knowledge Articles
                  </button>
                </div>
              </div>
              <div>
                <img src={GoalsImg} alt="Goals" />
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <div className="px-10 py-2 rounded-lg">
          <div
            className={`bg-white flex justify-between gap-2 py-4 px-6 ${!gridView ? 'rounded-t-[12px]' : 'rounded-[12px]'}`}
          >
            <div className="flex gap-3">
              <SearchBar
                width="w-full"
                placeholder="Search goal"
                value={searchText}
                onChange={setSearchText}
                onSearch={handleSearch}
              />

              <div className="w-[140px]">
                <ReactSelect
                  options={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...metrics
                  ]}
                  placeholder="Metrics"
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        metric: selectedOption.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="w-[140px]">
                <ReactSelect
                  options={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...frequency,
                  ]}
                  placeholder="Date Range"
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        frequencyType: selectedOption.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="w-[140px]">
                <ReactSelect
                  options={[
                    {
                      label: 'All',
                      value: '',
                    },
                    ...statuses,
                  ]}
                  placeholder="Status"
                  handleOnChange={(selectedOption: any) => {
                    setFilterGoal((prev) => {
                      return {
                        ...prev,
                        status: selectedOption.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <button
                className="text-white text-sm bg-[#005C9F] px-2 rounded-lg"
                onClick={() => setOpenCreateGoals(true)}
              >
                Create Goal
              </button>
              <div
                onClick={() => setGridView(false)}
                className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${!gridView && 'bg-[#808EA280]'}`}
              >
                <Icon name="TableView" size={16} />
              </div>
              <div
                onClick={() => setGridView(true)}
                className={`cursor-pointer p-[10px] border border-gray-300 rounded-md h-fit ${gridView && 'bg-[#808EA280]'}`}
              >
                <Icon name="GridView" size={16} />
              </div>
            </div>
          </div>
          {gridView ? (
            <Grid
              numItems={1}
              numItemsSm={1}
              numItemsMd={2}
              numItemsLg={3}
              className="mt-8 gap-4"
            >
              {formattedDataMemo?.map((item, index) => (
                <Col key={index}>
                  <GoalCard data={item} chartdata={chartdata} index={index} />
                </Col>
              ))}
            </Grid>
          ) : (
            <TansStackTableV2
              data={!formattedDataMemo ? [] : formattedDataMemo}
              columns={columns}
              totalPages={goals?.total_pages}
              page={page}
              setPage={setPage}
              loading={loading}
              totalDocs={goals?.total_docs}
              limit={limit}
              setLimit={setLimit}
              stickyColumn="Goal Name"
            />
          )}
        </div>
      )}

      <CustomModal
        open={openCreateGoals}
        onClose={() => {
          setSelectedGoal({});
          setOpenCreateGoals(false);
        }}
      >
        <CreateGoalsModal
          selectedGoal={selectedGoal}
          onClose={() => {
            setSelectedGoal({});
            setOpenCreateGoals(false);
          }}
          handleFilterChange={() => handleFilterChange()}
          setGoal={() => {
            setGoal(true);
            setOpenCreateGoals(false);
          }}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Goals;
