import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { getStatusBadgeColor } from '../../../utils/common';
import { toast } from 'react-toastify';
import Alert from '../../Alert';

import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import Icon from '../../../assets/icons/SvgComponent';
import useClientStore from '../../../store/useClientStore';
import { StylesConfig } from 'react-select';
import moment from 'moment';
import { Goal } from '../../../api/goal';
import 'react-datepicker/dist/react-datepicker.css';
import {
  capitalized,
  frequency,
  getStatusMetric,
  metrics,
} from '../../../pages/Goals/constants'

interface IProps {
  selectedGoal?: any;
  onClose?: any;
  setGoal?: () => void;
  handleFilterChange?: () => void;
}

const CreateGoalsModal: React.FC<IProps> = ({
  selectedGoal,
  onClose,
  setGoal,
  handleFilterChange,
}) => {
  const currentDate = moment();
  const currYear = currentDate.year();
  // const { selectedDomain, setDomain, user } = useAuthStore(
  //   (state) => state
  // );

  const { mode } = useThemeStore((state: any) => state);
  const { fetchClients, clients } = useClientStore((state) => state);

  const today = new Date();
  // const [date, setDate] = useState(today);
  // const [formData, setFormData] = useState<any>({});
  const [currentYear, setCurrentYear] = useState<any>(currYear);

  const iconColor = mode === 'dark' ? 'white' : 'black';
  // const [ data, setData ] = useState({})
  const [goalStartDate, setGoalStartDate] = useState(today);
  const [goalEndDate, setGoalEndDate] = useState<any>(
    moment(today).add(7, 'days')
  );
  const [goalName, setGoalName] = useState(null);
  const [goalValue, setGoalValue] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select metric',
    value: '',
  });
  const [frequencyType, setFrequencyType] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select metric',
    value: '',
  });
  const [basicFrequencyType, setBasicFrequencyType] = useState([]);
  const [advancedFrequencyType, setAdvancedFrequencyType] = useState('weekly');
  const [advancedFrequencyValues, setAdvancedFrequencyValues] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [alert, setAlert] = useState(null);

  const [client_id, setClientId] = useState('');
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [lastQuery, setLastQuery] = useState('');

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: any) => void) => {
      if (inputValue.length > 2 && inputValue !== lastQuery) {
        setLastQuery(inputValue);

        const filters = {
          status: 'all',
          search: inputValue,
          page: 1,
          limit: 100,
        };

        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(() => {
          fetchClients(filters)
            .then((response: any) => {
              const options =
                response?.data?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                  id: item.id,
                  cycle: item.cycle,
                })) || [];
              callback(options);
            })
            .catch((error: any) => {
              console.error('Error fetching clients:', error);
              callback([]);
            });
        }, 500); // 500ms debounce time
      } else {
        callback([]);
      }
    },
    [lastQuery, fetchClients]
  );

  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      ...{
        borderRadius: '8px',
        border: '1px solid #D1D5DB',
        opacity: 1,
        cursor: 'pointer',
        fontSize: '14px',
        scrollbarWidth: 'thin',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.data.isDisabled ? '#6D8080' : '',
        fontSize: '14px',
        cursor: 'pointer',
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  useEffect(() => {
    if (!selectedGoal?.id && frequencyType.value === 'advanced_setup') {
      const startDate = moment(goalStartDate, 'DD-MM-YYYY');
      const endDate = moment(goalEndDate, 'DD-MM-YYYY');
      const totalDays = endDate.diff(startDate, 'days');
      if (advancedFrequencyType === 'weekly') {
        // Calculate the number of complete weeks
        const fullWeeks = Math.floor(totalDays / 7);
        // Check if there are remaining days that would count as an additional week
        const additionalWeek = totalDays % 7 > 0 ? 1 : 0;
        const totalWeeks = fullWeeks + additionalWeek;
        const advancedWeeklyFrequencyValues = [];
        for (let i = 0; i < totalWeeks; i++) {
          advancedWeeklyFrequencyValues.push({
            label: `W${(i + 1).toString().padStart(2, '0')}`,
            type: 'weekly',
            goalValue: '',
            currentValue: '0',
            targetDate: moment(startDate).add(i, 'weeks').format('YYYY-MM-DD'),
            status: 'started',
          });
        }
        setAdvancedFrequencyValues(advancedWeeklyFrequencyValues);
      }
      if (!selectedGoal?.id && advancedFrequencyType === 'monthly') {
        const totalMonths = endDate.diff(startDate, 'months') + 1;
        const advancedMonthlyFrequencyValues = [];
        for (let i = 0; i < totalMonths; i++) {
          advancedMonthlyFrequencyValues.push({
            label: `${moment().add(i, 'months').format('MMMM')} ${moment().add(i, 'months').year()}`,
            type: 'monthly',
            goalValue: '',
            currentValue: '0',
            targetDate: moment(startDate).add(i, 'months').format('YYYY-MM-DD'),
            status: 'started',
          });
        }
        setAdvancedFrequencyValues(advancedMonthlyFrequencyValues);
      }
      if (!selectedGoal?.id && advancedFrequencyType === 'quarterly') {
        const totalQuarters = endDate.diff(startDate, 'quarters') + 1;
        const advancedQuarterlyFrequencyValues = [];
        for (let i = 0; i < totalQuarters; i++) {
          advancedQuarterlyFrequencyValues.push({
            label: `Q${moment(startDate).add(i, 'quarters').quarter()} ${moment(startDate).add(i, 'quarters').year()}`,
            type: 'quarterly',
            goalValue: '',
            currentValue: '0',
            targetDate: moment(startDate)
              .add(i, 'quarters')
              .format('YYYY-MM-DD'),
            status: 'started',
          });
        }
        setAdvancedFrequencyValues(advancedQuarterlyFrequencyValues);
      }
    }
  }, [
    goalStartDate,
    goalEndDate,
    advancedFrequencyType,
    frequencyType,
    selectedGoal,
  ]);

  useEffect(() => {
    const startDate = moment(goalStartDate, 'DD-MM-YYYY');
    if (frequencyType.value === 'weekly') {
      const currentWeek = startDate.week();
      setBasicFrequencyType([
        {
          label: currentWeek,
          type: 'weekly',
          goalValue: goalValue,
          currentValue: '0',
          targetDate: moment(startDate).add(7, 'days').format('YYYY-MM-DD'),
          status: 'started',
        },
      ]);
    }
    if (frequencyType.value === 'monthly') {
      const currentMonth = currentDate.month();

      setBasicFrequencyType([
        {
          label: moment().month(currentMonth).format('MMMM'),
          type: 'monthly',
          goalValue: goalValue,
          currentValue: '0',
          targetDate: moment(startDate)
            .month(currentMonth)
            .endOf('month')
            .format('YYYY-MM-DD'),
          status: 'started',
        },
      ]);
    }
    if (frequencyType.value === 'quarterly') {
      const currentQuarter = currentDate.quarter();
      setBasicFrequencyType([
        {
          label: `Q${currentQuarter}`,
          type: 'quarterly',
          goalValue,
          currentValue: '0',
          targetDate: moment()
            .year(Number(currentYear))
            .quarter(currentQuarter)
            .endOf('quarter')
            .format('YYYY-MM-DD'),
          status: 'started',
        },
      ]);
    }
    // if(frequencyType.value === 'yearly') {
    //   setBasicFrequencyType([
    //     {label: currYear, type: 'yearly', goalValue, currentValue: '0', targetDate: moment().year(Number(currYear)).endOf('year').format('YYYY-MM-DD'), status: 'started'}
    //   ])
    // }
  }, [goalStartDate, goalEndDate, goalValue, frequencyType?.value]);

  const handleAdvancedFrequencyValues = (index, newValue) => {
    const updatedValues = advancedFrequencyValues.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          goalValue: newValue,
        };
      }
      return item;
    });
    setAdvancedFrequencyValues(updatedValues);
  };

  useEffect(() => {
    if (selectedGoal?.id) {
      // metric
      setSelectedMetric({
        label: getStatusMetric(selectedGoal?.metric),
        value: selectedGoal?.metric,
      });
      // name
      setGoalName(selectedGoal?.name || '');
      // start date
      setGoalStartDate(selectedGoal?.goalStartDate || today);
      setGoalEndDate(selectedGoal?.goalEndDate || today);

      setClientId(selectedGoal?.client_id);

      if (selectedGoal?.frequencyType !== 'advanced_setup') {
        setAdvancedFrequencyType(selectedGoal?.frequency[0]?.type);
        setGoalValue(selectedGoal?.frequency[0]?.goalValue);
        setFrequencyType({
          label: capitalized(selectedGoal?.frequency[0]?.type),
          value: selectedGoal?.frequency[0]?.type,
        });
      } else {
        setFrequencyType({
          label: '',
          value: 'advanced_setup',
        });
        setAdvancedFrequencyValues(selectedGoal?.frequency);
      }
      // setFrequencyType({ label: '', value: selectedGoal?.frequencyType});
    }
  }, [selectedGoal]);

  const createGoal = async () => {
    setSubmit(true);

    // Check if all required fields are filled
    if (
      goalName &&
      selectedMetric?.value &&
      frequencyType.value &&
      goalStartDate &&
      goalEndDate
    ) {
      const data = {
        name: goalName,
        client_id,
        goalStartDate: moment(goalStartDate).format('YYYY-MM-DD'),
        goalEndDate: moment(goalEndDate).format('YYYY-MM-DD'),
        metric: selectedMetric?.value,
        source: 'ga4',
        frequencyType: frequencyType.value,
        frequency:
          frequencyType.value === 'advanced_setup'
            ? advancedFrequencyValues
            : basicFrequencyType,
        alert,
      };

      try {
        if (selectedGoal?.id) {
          const update = await Goal.update(selectedGoal?.id, data);
          if (update) {
            setGoal();
            handleFilterChange();
            toast.success(
              <Alert
                icon=""
                title="Goal Update"
                message="Goal has been updated successfully"
              />,
              {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } else {
          const result = await Goal.create(data);
          if (result) {
            setGoal();
            handleFilterChange();
            toast.success(
              <Alert
                icon=""
                title="Goal Created"
                message="Goal has been created successfully"
              />,
              {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      } catch (error) {
        toast.error(
          <Alert
            icon=""
            title="Error"
            message="An error occurred while creating or updating the goal."
          />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      toast.error(
        <Alert
          icon=""
          title="Form Incomplete"
          message="Please fill in all required fields."
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <div className="h-screen w-screen flex justify-end">
      <div
        className="bg-white relative w-[40dvw] p-6  text-[#001C44] flex flex-col justify-start"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h1 className="text-[24px] font-[700]">
            {selectedGoal?.id ? 'Edit Goal' : 'Goals Setup'}
          </h1>
          <div className="block sm:hidden cursor-pointer" onClick={onClose}>
            <Icon name="Xclose" />
          </div>
        </div>
        <TabGroup className="">
          {selectedGoal?.id ? (
            <div className="flex justify-between w-full items-center mb-4 mt-4">
              <TabList variant="solid">
                <Tab>Details</Tab>
                <Tab disabled={selectedGoal?.id ? false : true}>History</Tab>
              </TabList>
            </div>
          ) : null}
          <TabPanels>
            <TabPanel>
              <div className="p-2 h-full flex flex-col justify-between">
                <div className="max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Client Name
                    </label>
                    <AsyncSelect
                      className="my-react-select-container"
                      classNamePrefix="my-react-select"
                      placeholder={'Search client'}
                      cacheOptions
                      loadOptions={loadOptions}
                      menuPortalTarget={document.body}
                      styles={customStyles}
                      defaultOptions
                      onChange={(e) => setClientId(e.id)}
                      noOptionsMessage={() => 'Type 3+ letters to search'}
                    />
                    {submit && !client_id && (
                      <span className="text-sm text-red-500">
                        Client is required
                      </span>
                    )}
                  </div>
                  <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Goal Title
                    </label>
                    <input
                      type="text"
                      value={goalName}
                      onChange={(e) => setGoalName(e.target.value)}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {submit && !goalName && (
                      <span className="text-sm text-red-500">
                        Goal title is required
                      </span>
                    )}
                  </div>
                  <div className="my-6 flex flex-row justify-start items-center gap-4 w-full">
                    <div className='w-1/2'>
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Start Date
                      </label>
                      <DatePicker
                        selected={moment(goalStartDate, 'YYYY-MM-DD').toDate()}
                        minDate={goalStartDate}
                        onChange={(date) => {
                          if (date !== null) {
                            setGoalStartDate(date);
                          }
                        }}
                        className="w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                      />
                    </div>
                    <div className='w-1/2'>
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        End Date
                      </label>
                      <DatePicker
                        selected={moment(goalEndDate, 'YYYY-MM-DD').toDate()}
                        minDate={goalStartDate}
                        startDate={goalStartDate}
                        onChange={(date) => {
                          if (date !== null) {
                            setGoalEndDate(date);
                          }
                        }}
                        className="react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer w-[22.3vh] dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                      />
                    </div>
                    <div></div>
                  </div>

                  {frequencyType.value === 'advanced_setup' ? (
                    <>
                      <div className="border border-[#808EA2] rounded-md py-2 px-4 flex flex-row justify-between items-center">
                        <span className="text-sm text-[#334969]">
                          Set Goal Values
                        </span>
                        <div>
                          <select
                            defaultValue={advancedFrequencyType}
                            onChange={(e) =>
                              setAdvancedFrequencyType(e.target.value)
                            }
                            className="rounded-md bg-[#D0EDFF] text-sm p-1"
                          >
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col mt-6">
                        {advancedFrequencyValues?.length > 0 &&
                          advancedFrequencyValues.map((item, index) => {
                            return (
                              <div
                                key={item.label}
                                className="mb-4 flex flex-row justify-between items-center"
                              >
                                <div className="text-sm block w-full max-w-[120px]">
                                  {item.label}
                                </div>
                                <input
                                  type="text"
                                  value={item.goalValue}
                                  onChange={(e) =>
                                    handleAdvancedFrequencyValues(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  className="w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                              </div>
                            );
                          })}
                        <div>
                          <span
                            onClick={() =>
                              setFrequencyType({ label: '', value: '' })
                            }
                            className="text-sm font-semibold text-[#001C44] cursor-pointer inline-block"
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-6">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Set Metric
                        </label>
                        <div className="flex w-full gap-2 items-center">
                          <div className="w-full">
                            <ReactSelect
                              options={metrics}
                              value={selectedMetric}
                              handleOnChange={(selectedOption: any) => {
                                setSelectedMetric(selectedOption);
                              }}
                            />
                          </div>

                          <div className="w-full">
                            <ReactSelect
                              options={[
                                ...frequency,
                                {
                                  label: 'Advanced Setup',
                                  value: 'advanced_setup',
                                },
                              ]}
                              value={frequencyType}
                              handleOnChange={(selectedOption: any) => {
                                setFrequencyType(selectedOption);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <input
                              type="text"
                              value={goalValue}
                              onChange={(e) => setGoalValue(e.target.value)}
                              className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                          </div>
                        </div>
                        {submit && !selectedMetric?.value && (
                          <span className="text-sm text-red-500">
                            Metric is required
                          </span>
                        )}
                      </div>
                    </>
                  )}

                  {/* <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Goal Owner
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div> */}

                  <div className="my-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Alert me when
                    </label>
                    <div className="flex items-center mb-4">
                      <input
                        type="radio"
                        name="alert"
                        checked={alert}
                        onClick={() => setAlert(true)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        My goal has been hit
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="alert"
                        checked={!alert}
                        onClick={() => setAlert(false)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        My goal probably won’t be hit (after 70% of the time)
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  className="absolute bottom-4 right-0 mr-4 w-[38vw] text-white bg-[#0029FF] text-[16px] font-[600] rounded-lg p-2"
                  onClick={createGoal}
                >
                  {selectedGoal?.id ? 'Save' : 'Create Goal'}
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="w-full mx-auto p-0">
                {selectedGoal?.frequency?.length > 0 ? (
                  <table className="w-full">
                    <thead>
                      <tr className="text-xs font-semibold">
                        <th className="p-2 text-[#001C44] text-left">Label</th>
                        <th className="p-2 text-[#001C44] text-center">Type</th>
                        <th className="p-2 text-[#001C44] text-center">
                          Current / Goal
                        </th>
                        <th className="p-2 text-[#001C44] text-center">
                          Target Date
                        </th>
                        <th className="p-2 text-[#001C44] text-center">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedGoal?.frequency.map((item, index) => (
                        <tr key={index} className="border-b text-sm">
                          <td className="p-2 text-[#001C44]">{item.label}</td>
                          <td className="p-2 capitalize text-[#001C44] text-center">
                            {item.type}
                          </td>
                          <td className="p-2 text-[#001C44] text-center">{`${item.currentValue} / ${item.goalValue}`}</td>
                          <td className="p-2 text-center">
                            {moment(item.targetDate).format('MMM D, YYYY')}
                          </td>
                          <td className="p-2">
                            <div
                              className={`text-sm flex justify-center w-full font-inter font-[500] ${getStatusBadgeColor(item?.status)?.color} dark:text-[#FFF] overflow-hidden px-4 py-1 rounded-full`}
                            >
                              {getStatusBadgeColor(item.status)?.text}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div
          className="hidden sm:flex absolute items-center transform -rotate-90 top-[50dvh] left-[-42px] z-50 bg-[#D0EDFF] py-1 px-2 rounded-md cursor-pointer"
          onClick={onClose}
        >
          <p className="text-[#001C44] font-bold">Close</p>
          <Icon name="Xclose" color="#001C44" />
        </div>
      </div>
    </div>
  );
};

export default CreateGoalsModal;
