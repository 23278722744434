export const getStatusMetric = (status) => {
  switch(status) {
    case 'organic_sessions': return 'Organic Sessions'
    case 'organic_conversions': return 'Organic Conversions'
    case 'organic_revenue': return 'Organic Revenue'
    case 'totalRevenue': return 'Total Revenue'
    default: return capitalized(status);
  }
}

export const capitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getStatus = (status) => {
  switch(status) {
    case 'at_risk':
    case 'Risk':
      return 'At Risk'
    case 'behind':
      return 'Behind'
    case 'on_track':
    case 'Tracked':
    case 'started':
      return 'On Track'
  }
}

export const statuses = [
  {
    label: 'On Track',
    value: 'on_track',
  },
  {
    label: 'At Risk',
    value: 'at_risk',
  },
  {
    label: 'Behind',
    value: 'behind',
  }
]

export const metrics = [
  {
    label: 'Organic Sessions',
    value: 'organic_sessions',
  },
  {
    label: 'Organic Conversions',
    value: 'organic_conversions'
  },
  {
    label: 'Organic Revenue',
    value: 'organic_revenue'
  },
  {
    label: 'Total Revenue',
    value: 'totalRevenue'
  },
  {
    label: 'Clicks',
    value: 'clicks',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  }
]

export const frequency = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  }
]