import React, { useState } from 'react';
import { Card, AreaChart, ProgressBar } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import { formatNumber, getStatusBadgeColor } from '../../utils/common';
import ImageIcon from '../ImageIcon';

interface GoalCardProps {
  data: any;
  chartdata: any;
  index: number;
}

const GoalCard: React.FC<GoalCardProps> = ({ data, chartdata, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Card className="relative">
      <div>
        <div className="flex justify-between">
          <div className="text-[#001C44] text-sm bg-[#D0EDFF] p-1 px-2 rounded">
            {data?.achieveGoalBy}
          </div>
          <div
            className={`text-xs ${getStatusBadgeColor(data?.status)?.color} p-1 px-2 rounded-full flex gap-2 items-center cursor-pointer`}
            onClick={toggleCard}
          >
            <div
              className={`w-2 h-2 ${getStatusBadgeColor(data?.status)?.bullet} rounded-full`}
            />
            {data?.status}
          </div>
        </div>
        <h1 className="mt-3 mb-1 text-[#001C44] text-[20px] font-bold">
          {data?.goalName}
        </h1>
        <p className="text-xs font-semibold text-[#001C4480]">{data?.metric}</p>
        <AreaChart
          className="mt-4 h-[200px]"
          data={chartdata}
          index="date"
          showLegend={false}
          categories={['Running']}
          colors={['blue']}
          yAxisWidth={30}
        />
        <div className="flex justify-between">
          <div className="text-[#001C44] text-xl font-bold">
            {formatNumber(data?.number)}
          </div>
          <div className={`text-md rounded-full`}>
            {formatNumber(data?.goalSetValue)}
          </div>
        </div>
        <ProgressBar
          value={data?.dateUntilTarget}
          color="teal"
          className="my-3"
        />
        <div className="text-sm">{`${data?.dateUntilTarget} days left`}</div>
        <div className="border-t border-dashed flex items-center gap-2 pt-4 mt-4">
          <div>
            <ImageIcon data={`Olivia Rhye`} size={9} textSize={'xs'} />
          </div>
          <div>
            <h1 className="text-sm text-[#001C44]">Olivia Rhye</h1>
            <p className="text-xs text-[#405573]">olivia@omg.com.au</p>
          </div>
        </div>
      </div>
      {isOpen && (
        <Card className="absolute top-16 right-[-20px] w-[18rem] p-3">
          <div className="flex justify-between border-b pb-4">
            <div>
              <div className="text-[#0029FF] text-sm">{data?.goalName}</div>
              <div className="text-[#001C44] text-sm">{data?.type}</div>
            </div>
            <div className="cursor-pointer" onClick={toggleCard}>
              <Icon name="Xclose" />
            </div>
          </div>

          <div
            className={`text-xs ${getStatusBadgeColor(data?.status)?.color} p-1 px-2 rounded mt-4`}
          >
            <div className="text-sm">{data?.status}</div>
            <div className="text-sm">Goal is likely to be hit</div>
          </div>
          <div className="my-4 text-xs text-[#001C44]">
            Observing the data derived from Google Analytics, we note
            interesting patterns in the Sessions metric. This metric refers to
            the number of individual sessions initiated by all users on your
            site. We can see a comparison between sessions achieved this month,
            last month, and month to date against the goal.
          </div>
          <div className="text-[#0029FF] text-sm font-bold mb-4">View More</div>
          <div className="border-t border-b py-4">
            <div>
              <div className="text-[#001C44] text-sm">
                How to Further Improve
              </div>
              <ol className="list-decimal list-inside text-sm">
                <li className="my-2">
                  Investigate the factors leading to this shortfall in sessions
                  and attempt to address them.
                </li>
                <li className="my-2">
                  Consider implementing strategies to drive engagement and
                  increase the number of individual sessions.
                </li>
              </ol>
              <div className="text-[#0029FF] text-sm font-bold">View More</div>
            </div>
          </div>
          <button className="text-white bg-[#0029FF] text-md w-full rounded py-2 mt-2">
            View more Insights
          </button>
        </Card>
      )}
    </Card>
  );
};

export default GoalCard;
