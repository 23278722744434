import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

const dynamicSubReason = (nonStartReason) => {
  console.log('ENTERED: ', nonStartReason);
  switch (nonStartReason) {
    case 'Death In The Market':
      return [
        { value: 'In House', label: 'In House' },
        { value: 'Business Windup', label: 'Business Windup' },
        { value: 'Aquisition', label: 'Aquisition' },
        { value: 'Regulatory Change', label: 'Regulatory Change' },
        {
          value: 'Personal Circumstances Changed',
          label: 'Personal Circumstances Changed',
        },
      ];
      break;
    case 'Price':
      return [
        { value: 'Too Expensive', label: 'Too Expensive' },
        { value: 'Budget Re-Allocated', label: 'Budget Re-Allocated' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        {
          value: 'Insufficient Budget',
          label: 'Insufficient Budget for Successful Advertising',
        },
      ];
      break;
    case 'Product':
      return [
        { value: 'Insufficient ROI', label: 'Insufficient ROI' },
        { value: 'Speed of Service', label: 'Speed of Service' },
        { value: 'Quality of Product', label: 'Quality of Product' },
        { value: 'Results Achieved', label: 'Results Achieved' },
        {
          value: 'Expectation Misallignment',
          label: 'Expectation Misallignment',
        },
        { value: 'Error in Service', label: 'Error in Service' },
        { value: 'Poor Performance', label: 'Poor Performance' },
        {
          value: 'Incorrect Service Line Sold',
          label: 'Incorrect Service Line Sold',
        },
      ];
      break;
    case 'People':
      return [
        { value: 'Personality Clash', label: 'Personality Clash' },
        { value: 'Change of POC', label: 'Change of POC' },
        { value: 'Responsiveness', label: 'Responsiveness' },
      ];
      break;
    case 'Other':
      return [
        { value: 'Website Not Active', label: 'Website Not Active' },
        {
          value: 'CMS Platform Suitability',
          label: 'CMS Platform Suitability',
        },
        { value: 'Management Only', label: 'Management Only (Do Not Use)' },
      ];
      break;
  }
};

// SEO NON START EXPORT
export const SEONonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.seo_google.start_date !== '') {
    startDate = client.seo_google.start_date;
  } else {
    startDate = '';
  }
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'seo_google',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartRankRanger: '',
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.seo_google.end_date,
    nonStartBudget: client.seo_google.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartBudget,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartRankRanger,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          SEO GOOGLE DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When should the client be removed from Rank Ranger?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRankRanger')}
                value={nonStartRankRanger}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PPC NON START EXPORT
export const PPCNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.ppc_google.start_date !== '') {
    startDate = client.ppc_google.start_date
  } else {
    startDate = '';
  }
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'ppc_google',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.ppc_google.end_date,
    nonStartBudget: client.ppc_google.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PPC GOOGLE DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the nonStart
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// SEO NON START EXPORT
export const SEOBINGNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.seo_bing.start_date !== '') {
    startDate = client.seo_bing.start_date;
  } else {
    startDate = '';
  }
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'seo_bing',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartRankRanger: '',
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.seo_bing.end_date,
    nonStartBudget: client.seo_bing.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartBudget,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartRankRanger,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Microsoft SEO DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'People', label: 'People' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When should the client be removed from Rank Ranger?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRankRanger')}
                value={nonStartRankRanger}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PPC NON START EXPORT
export const PPCBINGNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_bing.start_date !== '') {
    startDate = client.social_bing.start_date
  } else {
    startDate = '';
  }
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_bing',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_bing.end_date,
    nonStartBudget: client.social_bing.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Microsoft Ads DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the nonStart
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// REM NON START EXPORT
export const REMNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.display.start_date !== '') {
    startDate = client.display.start_date
  } else {
    startDate = '';
  }
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'display',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.display.end_date,
    nonStartBudget: client.display.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Remarketing DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// META NON START EXPORT
export const METANonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_meta.start_date !== '') {
    startDate = client.social_meta.start_date
  } else {
    startDate = '';
  }
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_meta',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_meta.end_date,
    nonStartBudget: client.social_meta.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          META DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PINTEREST NON START EXPORT
export const PINTERESTNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_meta.start_date !== '') {
    startDate = client.social_meta.start_date
  } else {
    startDate = '';
  }
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_pinterest',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_pinterest.end_date,
    nonStartBudget: client.social_pinterest.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PINTEREST DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// TIKTOK NON START EXPORT
export const TIKTOKNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_tiktok.start_date !== '') {
    startDate = client.social_tiktok.start_date
  } else {
    startDate = '';
  }
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_tiktok',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_tiktok.end_date,
    nonStartBudget: client.social_tiktok.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          TIKTOK DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// LinkedIn NON START EXPORT
export const LINKEDINNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_linkedin.start_date !== '') {
    startDate = client.social_linkedin.start_date
  } else {
    startDate = '';
  }
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_linkedin',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_linkedin.end_date,
    nonStartBudget: client.social_linkedin.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          LINKEDIN DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Creative NON START EXPORT
export const CREATIVENonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_creative.start_date !== '') {
    startDate = client.social_creative.start_date
  } else {
    startDate = '';
  }
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_creative',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_creative.end_date,
    nonStartBudget: client.social_creative.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          CREATIVE DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Tactical NON START EXPORT
export const TACTICALNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_tactical.start_date !== '') {
    startDate = client.social_tactical.start_date
  } else {
    startDate = '';
  }
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_tactical',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_tactical.end_date,
    nonStartBudget: client.social_tactical.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          TACTICAL DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Tactical NON START EXPORT
export const PROGRAMMATICNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.social_programmatic.start_date !== '') {
    startDate = client.social_programmatic.start_date
  } else {
    startDate = '';
  }
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'social_programmatic',
    nonStartRequestDate: '',
    nonStartEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    nonStartAds: '',
    nonStartAdsDate: '',
    nonStartAdsNotes: '',
    contractFinishDate: client.social_programmatic.end_date,
    nonStartBudget: client.social_programmatic.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartUnder30days,
    nonStartAds,
    nonStartAdsDate,
    nonStartAdsNotes,
    nonStartBudget,
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          PROGRAMMATIC DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should the ads be on or off?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartAds')}
                options={[
                  { value: 'on', label: 'On' },
                  { value: 'off', label: 'Off' },
                  {
                    value: 'Leave as is - No action required',
                    label: 'Leave as is - No action required',
                  },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartAds === 'off' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should they be turned off?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartAdsDate')}
                      value={nonStartAdsDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartAdsNotes')}
                      value={nonStartAdsNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEBDEV NON START EXPORT
export const WEBDEVNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.web_maintenance.start_date !== '') {
    startDate = client.web_maintenance.start_date
  } else {
    startDate = '';
  }
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'web_maintenance',
    nonStartHostingDate: '',
    nonStartHostingEffectDate: '',
    nonStartMaintenanceDate: '',
    nonStartMaintenanceEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.web_maintenance.end_date,
    nonStartBudget: client.web_maintenance.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartBudget
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Maintenance Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Maintenance DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEBHOST NON START EXPORT
export const WEBHOSTNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.web_hosting.start_date !== '') {
    startDate = client.web_hosting.start_date
  } else {
    startDate = '';
  }
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'web_hosting',
    nonStartHostingDate: '',
    nonStartHostingEffectDate: '',
    nonStartMaintenanceDate: '',
    nonStartMaintenanceEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.web_hosting.end_date,
    nonStartBudget: client.web_hosting.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartBudget
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Hosting DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEB LANDING NON START EXPORT
export const WEBLANDINGNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.web_landing.start_date !== '') {
    startDate = client.web_landing.start_date
  } else {
    startDate = '';
  }
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'web_landing',
    nonStartHostingDate: '',
    nonStartHostingEffectDate: '',
    nonStartMaintenanceDate: '',
    nonStartMaintenanceEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.web_landing.end_date,
    nonStartBudget: client.web_landing.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartBudget
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Landing DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// WEB LANDING NON START EXPORT
export const WEBPROJECTNonStart = ({ parentCallback, client }) => {
  var startDate, finalAsClient, finalDaysLeft;
  if (client.web_project.start_date !== '') {
    startDate = client.web_project.start_date
  } else {
    startDate = '';
  }
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(startDate);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [errors, setError] = useState({
    error: '',
    true: 0,
  });
  const [nonStartValues, setValues] = useState({
    type: 'non start',
    service: 'web_project',
    nonStartHostingDate: '',
    nonStartHostingEffectDate: '',
    nonStartMaintenanceDate: '',
    nonStartMaintenanceEffectDate: '',
    nonStartReason: '',
    nonStartSubReason: '',
    nonStartAmComments: '',
    nonStartSaveComments: '',
    nonStartClientComments: '',
    nonStartDoneBetterComments: '',
    nonStartNoteComments: '',
    nonStartStartDate: startDate,
    nonStartContractLeft: '',
    nonStartContractLeftTip: finalDaysLeft,
    nonStartReactivate: '',
    nonStartReactivateDate: '',
    nonStartReactivateNotes: '',
    nonStartUnder30days: false,
    contractFinishDate: client.web_project.end_date,
    nonStartBudget: client.web_project.revenue,
    nonStartTimeAsClient: finalAsClient,
  });
  const { error } = errors;
  const {
    type,
    service,
    nonStartRequestDate,
    nonStartEffectDate,
    nonStartReason,
    nonStartSubReason,
    nonStartAmComments,
    nonStartSaveComments,
    nonStartClientComments,
    nonStartDoneBetterComments,
    nonStartNoteComments,
    nonStartStartDate,
    nonStartContractLeft,
    nonStartContractLeftTip,
    nonStartReactivate,
    nonStartReactivateDate,
    nonStartReactivateNotes,
    contractFinishDate,
    nonStartBudget
  } = nonStartValues;

  const handleChange = (name) => (event) => {
    setError({ ...error, error: '', true: 0 });
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...nonStartValues, [name]: value });

    if (nonStartRequestDate !== '' && nonStartEffectDate !== '') {
      let dateDiff = Math.floor(
        (Date.parse(nonStartEffectDate) - Date.parse(nonStartRequestDate)) /
          86400000
      );
      if (dateDiff < 30) {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: true });
        setError({
          ...error,
          error:
            'By submitting this request, you acknowledge that the Hosting Request Date and Last Day are less than 30 days apart. The approver will be notified of this.',
          true: 1,
        });
      } else {
        setValues({ ...nonStartValues, [name]: value, nonStartUnder30days: false });
        setError({ ...error, error: '', true: 0 });
      }
    }
  };

  const handleSelectChange = (name) => (event) => {
    console.log(name, event.value);
    setValues({ ...nonStartValues, [name]: event.value });
  };

  const onError = (e) => {
    if (error) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(nonStartValues);
  }, [nonStartValues]);

  return (
    <Fragment>
      <div className="mb-[40px]">
        <div className="mb-6 text-[16px] text-[#005C9F]">
          Web Project DNS Details
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Date of request
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartRequestDate')}
                value={nonStartRequestDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When is the last day?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartEffectDate')}
                value={nonStartEffectDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What is the reason for the non start
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReason')}
                options={[
                  {
                    value: 'Death In The Market',
                    label: 'Death In The Market',
                  },
                  { value: 'Price', label: 'Price' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Other', label: 'Other' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReason !== '' ? (
                <ReactSelect
                  handleOnChange={handleSelectChange('nonStartSubReason')}
                  options={dynamicSubReason(nonStartReason)}
                  placeholder="PLEASE SELECT"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Briefly explain your choices above
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartAmComments')}
                value={nonStartAmComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How you tried to save the account?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartSaveComments')}
                value={nonStartSaveComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What did the client say?
            </p>
            <div>
              <textarea
                onInput={handleChange('nonStartClientComments')}
                value={nonStartClientComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex gap-8 mb-4">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              What do you think we could've done better?
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartDoneBetterComments')}
                value={nonStartDoneBetterComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
          <div className="w-[66%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Additional notes
            </p>
            <div className="text-[#7C7E7F]">
              <textarea
                onInput={handleChange('nonStartNoteComments')}
                value={nonStartNoteComments}
                className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter a description..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              When was the start date?
            </p>
            <div>
              <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('nonStartStartDate')}
                value={nonStartStartDate}
                type="date"
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              How long is left in the contract?
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                onInput={handleChange('nonStartContractLeft')}
                value={nonStartContractLeft}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-6">
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Should we attempt to reactivate?
            </p>
            <div>
              <ReactSelect
                handleOnChange={handleSelectChange('nonStartReactivate')}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                placeholder="PLEASE SELECT"
              />
              {nonStartReactivate === 'Yes' ? (
                <Fragment>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    When should we reach out to the client?
                  </p>
                  <div className="text-[#7C7E7F]">
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onInput={handleChange('nonStartReactivateDate')}
                      value={nonStartReactivateDate}
                      className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0"
                    />
                  </div>
                  <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                    Further reactivation notes:
                  </p>
                  <div className="text-[#7C7E7F]">
                    <textarea
                      onInput={handleChange('nonStartReactivateNotes')}
                      value={nonStartReactivateNotes}
                      className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter a description..."
                    />
                  </div>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Service amount
            </p>
            <div className="text-[#7C7E7F]">
              <input
                type="text"
                disabled
                value={nonStartBudget}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="0"
              />
            </div>
          </div>
          <div className="w-[32%]">
            <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
              Contract finish date?
            </p>
            <div>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('contractFinishDate')}
                className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={contractFinishDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
