type LabelIdOption = {
  label: string;
  id: string | boolean;
};

interface Option {
  label: string;
  id: string | boolean;
}

interface StatusObject {
  [key: string]: Option;
}

export const clientStatusObj: StatusObject = {
  ['Current Client']: {
    label: 'Current',
    id: 'Current Client',
  },
  ['Past Client']: {
    label: 'Past',
    id: 'Past Client',
  },
};

// export const clientStatusObj: { [key: string]: { label: string; id: string; } } = {
//   "Current Client": {
//     label: "Current",
//     id: "Current Client",
//   },
//   "Past Client": {
//     label: "Past",
//     id: "Past Client",
//   },
// };
export const roleOptions = [
  { label: 'All', id: 'all' },
  { label: 'Account Manager', id: 'am' },
  { label: 'Consultant', id: 'cons' },
];
export const statusObj = {
  in_progress: { label: 'Progress', id: 'in_progress' },
  draft: { label: 'Draft', id: 'draft' },
  rejected: { label: 'Rejected', id: 'rejected' },
  review: { label: 'Review', id: 'review' },
  validated: { label: 'Validated', id: 'validated' },
  approved: { label: 'Approved', id: 'approved' },
  scheduled: { label: 'Scheduled', id: 'scheduled' },
  sent: { label: 'Sent', id: 'sent' },
  canceled: { label: 'Canceled', id: 'canceled' },
  all: { label: 'All', id: 'all' },
};
export const healthStatusObj = {
  all: { label: 'All', id: 'all' },
  red: { label: 'Red', id: 'red' },
  amber: { label: 'Amber', id: 'amber' },
  green: { label: 'Green', id: 'green' },
};

export const HealthStatuOptions = [
  { label: 'All', id: 'all' },
  { label: 'Red', id: 'red' },
  { label: 'Amber', id: 'amber' },
  { label: 'Green', id: 'green' },
];
export const cycleObj = {
  ['all']: { label: 'All', id: 'all' },
  ['Cycle 1']: { label: 'Cycle 1', id: 'Cycle 1' },
  ['Cycle 2']: { label: 'Cycle 2', id: 'Cycle 2' },
};

export const cycleOptions = [
  { label: 'All', id: 'all' },
  { label: 'Cycle 1', id: 'Cycle 1' },
  { label: 'Cycle 2', id: 'Cycle 2' },
];
export const typeOptions = [
  { label: 'All', id: 'all' },
  { label: 'Google SEO', id: 'seo_google' },
  { label: 'Bing SEO', id: 'seo_bing' },
  { label: 'Google Ads', id: 'ppc_google' },
  { label: 'Microsoft Ads', id: 'ppc_bing' },
  { label: 'Meta', id: 'social_meta' },
  { label: 'LinkedIn', id: 'social_linkedin' },
  { label: 'Tiktok', id: 'social_tiktok' },
  { label: 'Pinterest ', id: 'social_pinterest' },
  { label: 'Remarketing', id: 'display' },
];
export const typeObj = {
  all: { label: 'All', id: 'all' },
  seo_google: { label: 'Google SEO', id: 'seo_google' },
  seo_bing: { label: 'Bing SEO', id: 'seo_bing' },
  ppc_google: { label: 'Google Ads', id: 'ppc_google' },
  ppc_bing: { label: 'Microsoft Ads', id: 'ppc_bing' },
  social_meta: { label: 'Meta', id: 'social_meta' },
  social_linkedin: { label: 'LinkedIn', id: 'social_linkedin' },
  social_tiktok: { label: 'Tiktok', id: 'social_tiktok' },
  social_pinterest: { label: 'Pinterest', id: 'social_pinterest' },
  display: { label: 'Remarketing', id: 'display' },
};
export const isSeomissingOptions = [
  { label: 'All Reports', id: 'all' },
  { label: 'Missing Content', id: 'missing_content' },
  { label: 'Missing Links', id: 'missing_links' },
  { label: 'Missing Dashthis', id: 'missing_dashthis' },
  { label: 'Missing Strategy', id: 'missing_strategy' },
];

export const missingOptions = [
  { label: 'All Reports', id: 'all' },
  { label: 'Missing Content', id: 'missing_content' },
  { label: 'Missing Links', id: 'missing_links' },
  { label: 'Missing Dashthis', id: 'missing_dashthis' },
  { label: 'Missing Rank Ranger', id: 'missing_rankranger' },
  { label: 'Missing Strategy', id: 'missing_strategy' },
  { label: 'Missing Outreach', id: 'missing_outreach' },
];
export const missingOption = {
  all: { label: 'All Reports', id: 'all' },
  missing_content: { label: 'Missing Content', id: 'missing_content' },
  missing_links: { label: 'Missing Links', id: 'missing_links' },
  missing_dashthis: { label: 'Missing Dashthis', id: 'missing_dashthis' },
  missing_rankranger: {
    label: 'Missing Rank Ranger',
    id: 'missing_rankranger',
  },
  missing_strategy: { label: 'Missing Strategy', id: 'missing_strategy' },
  missing_outreach: { label: 'Missing Outreach', id: 'missing_outreach' },
};
export const clientsOptions: LabelIdOption[] = [
  { label: 'All Clients', id: true },
  { label: 'My Clients', id: false },
];
export const clientsObj: { [key: string]: LabelIdOption } = {
  false: { label: 'My Clients', id: false },
  true: { label: 'All Clients', id: true },
};
export const clientStatusOptions = [
  { label: 'Current', id: 'Current Client' },
  { label: 'Past', id: 'Past Client' },
];
export const statusColor = {
  draft: 'gray',
  in_progress: '#ff8c00',
  sent: '#00DD6F',
  review: '#FD801C',
  approved: '#29DB78',
  validated: '#2FB4F9',
  rejected: '#ED3434',
  scheduled: '#38B6F5',
  canceled: '#ED3434',
};
export const textColor = {
  draft: 'white',
  in_progress: 'white',
  sent: 'white',
  review: 'white',
  approved: 'white',
  validated: 'white',
  rejected: 'white',
  scheduled: 'white',
  canceled: 'white',
};
export const circleColor = {
  gray: 'gray',
  green: 'green',
  amber: '#FFBF00',
  red: 'red',
};
