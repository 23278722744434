import Icon from '../../assets/icons/SvgComponent';
import { useState, useEffect } from 'react';
import Tooltip from '../HelpTooltip';
import api from '../../api';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils/common';

type ClientHeaderProps = {
  selectedClient?: any;
  handleSyncSuccess: () => void;
  isLoading?: boolean;
};

const ClientHeader: React.FC<ClientHeaderProps> = ({
  selectedClient,
  handleSyncSuccess,
  isLoading,
}) => {
  const accountCountry = selectedClient?.hubspot?.account_country;
  const [hubspotSyncing, setHubspotSyncing] = useState(false);
  const [renderSection, setRenderSection] = useState(0);
  const [ppcGoogle, setPpcGoogle] = useState(false);
  const [seoGoogle, setSeoGoogle] = useState(false);
  const [socialBing, setSocialBing] = useState(false);
  const [socialLinkedIn, setSocialLinkedIn] = useState(false);
  const [socialMeta, setSocialMeta] = useState(false);
  const [socialPinterest, setSocialPinterest] = useState(false);
  const [socialTiktok, setSocialTiktok] = useState(false);
  const [loadPage, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  }, []);

  if (
    selectedClient?.ppc_google?.status === 'active' ||
    selectedClient?.ppc_google?.status === 'cancelling' ||
    selectedClient?.ppc_google?.status === 'paused' ||
    selectedClient?.ppc_google?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setPpcGoogle(true);
    }
  }
  if (
    selectedClient?.seo_google?.status === 'active' ||
    selectedClient?.seo_google?.status === 'cancelling' ||
    selectedClient?.seo_google?.status === 'paused' ||
    selectedClient?.seo_google?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSeoGoogle(true);
    }
  }
  if (
    selectedClient?.social_bing?.status === 'active' ||
    selectedClient?.social_bing?.status === 'cancelling' ||
    selectedClient?.social_bing?.status === 'paused' ||
    selectedClient?.social_bing?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialBing(true);
    }
  }
  if (
    selectedClient?.social_linkedin?.status === 'active' ||
    selectedClient?.social_linkedin?.status === 'cancelling' ||
    selectedClient?.social_linkedin?.status === 'paused' ||
    selectedClient?.social_linkedin?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialLinkedIn(true);
    }
  }
  if (
    selectedClient?.social_meta?.status === 'active' ||
    selectedClient?.social_meta?.status === 'cancelling' ||
    selectedClient?.social_meta?.status === 'paused' ||
    selectedClient?.social_meta?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialMeta(true);
    }
  }
  if (
    selectedClient?.social_pinterest?.status === 'active' ||
    selectedClient?.social_pinterest?.status === 'cancelling' ||
    selectedClient?.social_pinterest?.status === 'paused' ||
    selectedClient?.social_pinterest?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialPinterest(true);
    }
  }
  if (
    selectedClient?.social_tiktok?.status === 'active' ||
    selectedClient?.social_tiktok?.status === 'cancelling' ||
    selectedClient?.social_tiktok?.status === 'paused' ||
    selectedClient?.social_tiktok?.status === 'activated'
  ) {
    if (renderSection <= 3) {
      setRenderSection(renderSection + 1);
      setSocialTiktok(true);
    }
  }

  const syncHubspotClients = async () => {
    const params = {
      country: accountCountry,
      company_id: selectedClient?.hubspot?.company_id,
    };
    setHubspotSyncing(true);
    const res = await api.HubSpot.syncHubspotClients(params);
    if (!res.error) {
      setHubspotSyncing(false);
      handleSyncSuccess();
    }
  };

  return (
    <>
      {isLoading || loadPage ? (
        <div className="flex justify-between mb-[14px] animate-pulse">
          <div className="flex gap-2">
            <div>
              <div className="w-[324px] h-8 bg-gray-300 rounded mb-2"></div>
              <div className="w-24 h-5 bg-gray-300 rounded"></div>
            </div>
          </div>
          <div className="flex justify-between items-start">
            <button
              onClick={syncHubspotClients}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Sync with Hubspot
            </button>
            <Link
              target="_blank"
              to={`https://app.hubspot.com/contacts/1590785/company/${selectedClient?.hubspot?.company_id}`}
            >
              {' '}
              <button className="ml-2 py-2 px-2.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                View in Hubspot
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col items-start gap-2 ">
            <div className="flex items-center gap-x-2">
              <div className="max-w-[40vw]">
                <Tooltip content={selectedClient?.name} position="right">
                  <div className="max-w-[40vw] truncate cursor-pointer text-[24px] font-semibold dark:text-white">
                    {selectedClient?.name}
                  </div>
                </Tooltip>
              </div>
              <div className="">
                <a href={`https://${selectedClient?.domain}`} target="blank">
                  <Icon name="ArrowUpRight" size={22} color="#0029FF" />
                </a>
              </div>
            </div>
            {/* <div className="flex items-center gap-x-4">
              <div className="text-sm text-gray-500 dark:text-[#fff] ">
                {selectedClient?.domain}
              </div>
              <div
                className={`text-left text-xs border border-1 rounded-lg w-fit p-1 ${category === 'Lead Gen' ? 'text-[#0029FF] border-[#0029FF]' : category === null ? 'text-gray-400 border-gray-400' : 'text-[#88CB3C] border-[#88CB3C]'}`}
              >
                {category}
              </div>
            </div> */}
          </div>

          <div className="flex justify-between items-start">
            <button
              onClick={syncHubspotClients}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Sync with Hubspot
            </button>
            <Link
              target="_blank"
              to={`https://app.hubspot.com/contacts/1590785/company/${selectedClient?.hubspot?.company_id}`}
            >
              {' '}
              <button className="ml-2 py-2 px-2.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                View in Hubspot
              </button>
            </Link>
          </div>
        </div>
      )}
      {hubspotSyncing && (
        <div className="absolute top-20 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Icon name="Loading" />
        </div>
      )}
      {!loadPage && (
        <div className="flex gap-2 items-center">
          {selectedClient?.category_type && (
            <div className="uppercase text-xs font-normal bg-[#B2BBC740] py-1 px-2 rounded-md w-fit">
              {selectedClient?.category_type}
            </div>
          )}
          <div className="text-xs text-white rounded-md py-1 px-2 bg-[#009EFF] w-fit">
            {selectedClient?.cycle}
          </div>
        </div>
      )}
      <div className="flex mb-[10px] mt-3">
        {isLoading || loadPage ? (
          // Skeleton Loader
          <div className="flex animate-pulse gap-4">
            <div className="w-[100px] h-[24px] bg-gray-300 rounded"></div>
            <div className="w-[100px] h-[24px] bg-gray-300 rounded"></div>
            <div className="w-[100px] h-[24px] bg-gray-300 rounded"></div>
          </div>
        ) : (
          <>
            {ppcGoogle && (
              <div className="flex items-center gap-2 border-r border-gray-400">
                <Icon name="Click" />
                <p className="text-[#005C9F] text-sm">GOOGLE ADS:</p>
                <p className="text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.ppc_google?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.ppc_google?.start_date
                        ? selectedClient?.ppc_google?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.ppc_google?.end_date
                     ? selectedClient?.ppc_google?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {seoGoogle && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="SearchRefractionIcon" />
                <p className="text-[#005C9F] text-sm">GOOGLE SEO:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.seo_google?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.seo_google?.start_date
                        ? selectedClient?.seo_google?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.seo_google?.end_date
                     ? selectedClient?.seo_google?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {socialBing && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="Globe03" />
                <p className="text-[#005C9F] text-sm">MICROSOFT ADS:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.social_bing?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.social_bing?.start_date
                        ? selectedClient?.social_bing?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.social_bing?.end_date
                     ? selectedClient?.social_bing?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {socialLinkedIn && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="Globe03" />
                <p className="text-[#005C9F] text-sm">LINKEDIN ADS:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.social_linkedin?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.social_linkedin?.start_date
                        ? selectedClient?.social_linkedin?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.social_linkedin?.end_date
                     ? selectedClient?.social_linkedin?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {socialMeta && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="Globe03" />
                <p className="text-[#005C9F] text-sm">META ADS:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.social_meta?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.social_meta?.start_date
                        ? selectedClient?.social_meta?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.social_meta?.end_date
                     ? selectedClient?.social_meta?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {socialPinterest && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="Globe03" />
                <p className="text-[#005C9F] text-sm">PINTEREST:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.social_pinterest?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.social_pinterest?.start_date
                        ? selectedClient?.social_pinterest?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.social_pinterest?.end_date
                     ? selectedClient?.social_pinterest?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {socialTiktok && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <Icon name="Globe03" />
                <p className="text-[#005C9F] text-sm">PINTEREST:</p>
                <p className="pr-1 text-[#4A4B4C]">
                  ${formatNumber(selectedClient?.social_tiktok?.revenue)}
                </p>
                <div className="pr-1">
                  <Tooltip
                    position="bottom"
                    content={`Start Date: ${
                      selectedClient?.social_tiktok?.start_date
                        ? selectedClient?.social_tiktok?.start_date
                        : 'N/A'
                    }
                 End Date: ${
                   selectedClient?.social_tiktok?.end_date
                     ? selectedClient?.social_tiktok?.end_date
                     : 'N/A'
                 }`}
                  >
                    <Icon name="HelpIcon" />
                  </Tooltip>
                </div>
              </div>
            )}
            {!isLoading && !loadPage && renderSection === 0 && (
              <div className="flex items-center gap-2 border-r pl-2 border-gray-400">
                <p className="text-[#005C9F] text-sm pr-2">No Services</p>
              </div>
            )}
          </>
        )}

        {/* <div className="flex items-center gap-2 pl-2 border-[#80AECF]">
          <div className="flex items-center justify-center gap-1 border-2  border-gray-400 text-gray-800 text-xs font-bold  px-1.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
            <Icon
              name="RefreshIcon"
              color={mode === 'dark' ? '#fff' : '#80AECF'}
            />
            <span className="text-[#005C9F] dark:text-white">
              {selectedClient?.cycle}
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default ClientHeader;
