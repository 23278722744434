import React from 'react';
import {
  Card,
  Grid,
  Col,
  LineChart,
  BarChart,
  Tab,
  TabGroup,
  TabList,
  BarList,
  TabPanels,
  TabPanel,
  DonutChart,
  Legend,
} from '@tremor/react';
import ReactSelect from '../../components/ReactSelect';
import SearchBar from '../../components/Search';

const PerformanceInsights = () => {
  const chartdata = [
    {
      date: 'Jan 2024',
      green: 800,
      amber: 600,
      red: 100,
    },
    {
      date: 'Feb 2024',
      green: 700,
      amber: 400,
      red: 200,
    },
    {
      date: 'Mar 2024',
      green: 600,
      amber: 800,
      red: 300,
    },
    {
      date: 'Apr 2024',
      green: 200,
      amber: 500,
      red: 400,
    },
    {
      date: 'May 2024',
      green: 732,
      amber: 722,
      red: 500,
    },
    {
      date: 'Jun 2024',
      green: 390,
      amber: 100,
      red: 600,
    },
    {
      date: 'Jul 2024',
      green: 420,
      amber: 600,
      red: 700,
    },
    {
      date: 'Aug 2024',
      green: 800,
      amber: 340,
      red: 800,
    },
    {
      date: 'Sep 2024',
      green: 789,
      amber: 666,
      red: 600,
    },
    {
      date: 'Oct 2024',
      green: 555,
      amber: 444,
      red: 700,
    },
    {
      date: 'Nov 2024',
      green: 333,
      amber: 222,
      red: 500,
    },
    {
      date: 'Dec 2024',
      green: 777,
      amber: 111,
      red: 400,
    },
  ];

  const barData = [
    {
      name: 'Jun 2023',
      'Google Ads': 100,
      'Google SEO': 80,
      Meta: 60,
      'Microsoft Ads': 40,
    },
    {
      name: 'Jul 2023',
      'Google Ads': 80,
      'Google SEO': 40,
      Meta: 60,
      'Microsoft Ads': 100,
    },
    {
      name: 'Aug 2023',
      'Google Ads': 40,
      'Google SEO': 60,
      Meta: 100,
      'Microsoft Ads': 80,
    },
    {
      name: 'Sep 2023',
      'Google Ads': 100,
      'Google SEO': 40,
      Meta: 80,
      'Microsoft Ads': 60,
    },
  ];

  const datahero = [
    { name: 'Google SEO', value: 240 },
    { name: 'Google Ads', value: 189 },
    { name: 'Meta Ads', value: 212 },
    { name: 'Microsoft Ads', value: 127 },
  ];

  const leadGenData = [
    {
      clientName: 'Viola Amherd',
      type: 'SEO',
      value: 100,
      clientType: 'Lead Gen',
    },
    {
      clientName: 'Albert Rösert',
      type: 'SEO',
      value: 100,
      clientType: 'Lead Gen',
    },
    {
      clientName: 'Alain Berset',
      type: 'SEO',
      value: 100,
      clientType: 'Lead Gen',
    },
    {
      clientName: 'Jane Doe',
      type: 'SEO',
      value: 100,
      clientType: 'Lead Gen',
    },
  ];

  const ecommerceData = [
    {
      clientName: 'John Doe',
      type: 'SEO',
      value: 20,
      clientType: 'Ecommerce',
    },
    {
      clientName: 'Clarisse La Rue',
      type: 'SEO',
      value: 20,
      clientType: 'Ecommerce',
    },
    {
      clientName: 'Annabeth Chase',
      type: 'SEO',
      value: 10,
      clientType: 'Ecommerce',
    },
    {
      clientName: 'Claire O’reily',
      type: 'SEO',
      value: 10,
      clientType: 'Ecommerce',
    },
  ];

  const dataFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();

  const dataHasPercentFormatter = (number: number) =>
    Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: 0,
    }).format(number / 100);

  return (
    <div className="pl-10 pr-3 py-4">
      <Card className="dark:bg-[#222732]">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={1}
          numItemsLg={2}
          className="gap-4"
        >
          <Col>
            <Card>
              <Grid
                className="gap-4 mb-4"
                numItems={1}
                numItemsSm={1}
                numItemsMd={3}
                numItemsLg={3}
              >
                <Col numColSpanLg={2}>
                  <SearchBar
                    value=""
                    placeholder="Elliot Rhodes"
                    width="w-full"
                  />
                </Col>
                <Col numColSpanLg={1}>
                  <ReactSelect
                    value={`All Services`}
                    options={[
                      { value: 'green', label: 'Green' },
                      { value: 'amber', label: 'Amber' },
                      { value: 'red', label: 'Red' },
                    ]}
                    handleOnChange={(selectedOption: any) => {
                      console.log(selectedOption);
                    }}
                    placeholder="All Status"
                  />
                </Col>
              </Grid>
              <LineChart
                className="h-80"
                data={chartdata}
                index="date"
                categories={['green', 'amber', 'red']}
                colors={['green', 'amber', 'rose']}
                valueFormatter={dataFormatter}
                startEndOnly
                yAxisWidth={30}
                onValueChange={(v) => console.log(v)}
              />
            </Card>
          </Col>
          <Col>
            <Card>
              <div className="mb-4">
                <SearchBar value="" placeholder="Search ..." width="w-full" />
              </div>
              <BarChart
                className=""
                data={barData}
                startEndOnly
                index="name"
                categories={['Google Ads', 'Google SEO', 'Meta', 'Microsoft Ads']}
                colors={['amber', '#000', '#0029FF', '#009EFF']}
                valueFormatter={dataHasPercentFormatter}
                yAxisWidth={48}
              />
            </Card>
          </Col>
        </Grid>
      </Card>
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={4}
        className="gap-4 mt-4"
      >
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Top Performance
            </div>
            <div className="mt-4">
              <ReactSelect
                value={`All Services`}
                options={[
                  { value: 'green', label: 'Google Ads' },
                  { value: 'amber', label: 'Google SEO' },
                  { value: 'red', label: 'Meta' },
                  { value: 'red', label: 'Microsoft Ads' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Services"
              />
            </div>
            <TabGroup defaultIndex={1}>
              <TabList variant="line">
                <Tab value="1">Lead Gen</Tab>
                <Tab value="2">Ecommerce</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {leadGenData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p className="font-[600] text-sm dark:text-white">
                          {data.clientName}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {data.type}
                        </p>
                      </div>
                      <div className="text-[#3CCB7F] text-m">{data.value}%</div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.clientType}
                      </div>
                    </div>
                  ))}
                </TabPanel>
                <TabPanel>
                  {ecommerceData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p className="font-[600] text-sm dark:text-white">
                          {data.clientName}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {data.type}
                        </p>
                      </div>
                      <div className="text-[#FF0000] text-m">{data.value}%</div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.clientType}
                      </div>
                    </div>
                  ))}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px]  h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Under Performance
            </div>
            <div className="mt-4">
              <ReactSelect
                value={`All Services`}
                options={[
                  { value: 'green', label: 'Google Ads' },
                  { value: 'amber', label: 'Google SEO' },
                  { value: 'red', label: 'Meta' },
                  { value: 'red', label: 'Microsoft Ads' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Services"
              />
            </div>
            <TabGroup defaultIndex={1}>
              <TabList variant="line">
                <Tab value="1">Lead Gen</Tab>
                <Tab value="2">Ecommerce</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {leadGenData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p className="font-[600] text-sm dark:text-white">
                          {data.clientName}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {data.type}
                        </p>
                      </div>
                      <div className="text-[#3CCB7F] text-m">{data.value}%</div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.clientType}
                      </div>
                    </div>
                  ))}
                </TabPanel>
                <TabPanel>
                  {ecommerceData.map((data, index) => (
                    <div
                      key={index}
                      className="border-b flex w-full justify-between items-center"
                    >
                      <div className="w-[8vw] py-2">
                        <p className="font-[600] text-sm dark:text-white">
                          {data.clientName}
                        </p>
                        <p className="font-light text-[#6B7280] text-sm">
                          {data.type}
                        </p>
                      </div>
                      <div className="text-[#FF0000] text-m">{data.value}%</div>
                      <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                        {data.clientType}
                      </div>
                    </div>
                  ))}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Lead Gen
            </div>
            <div className="my-4">
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            <BarList
              data={datahero}
              className="mx-auto max-w-sm"
              color="teal"
            />
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Ecommerce
            </div>
            <div className="mt-4">
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            <div className="flex justify-center mt-4">
              <DonutChart
                data={datahero}
                variant="pie"
                valueFormatter={dataFormatter}
                onValueChange={(v) => console.log(v)}
              />
            </div>
            <div className="mt-4 w-full flex justify-center">
              <Legend
                categories={[
                  'Google SEO',
                  'Google Ads',
                  'Meta Ads',
                  'Microsoft Ads',
                ]}
                colors={['blue', 'cyan', 'indigo', 'violet', 'fuchsia']}
                className="max-w-[14vw] text-center"
              />
            </div>
          </Card>
        </Col>
      </Grid>
    </div>
  );
};

export default PerformanceInsights;
