import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Api from '../../../api';
import { typeOptions } from '../../ReportTanStackTable/staticData';
interface WeeklyProps {
  closeModal: () => void;
  status?: any;
  type?: any;
  service?: any;
  integration?: any;
}
const serviceOptions = typeOptions.map((option) => ({
  value: option.id,
  label: option.label,
}));

const statusOptions = [
  { label: 'All Status', value: 'all' },
  { label: 'My Clients', value: 'My Clients' },
  { label: 'Current', value: 'Current Client' },
  { label: 'Paused', value: 'Paused' },
  { label: 'Past', value: 'Past Client' },
  { label: 'Lead', value: 'Lead' },
  { label: 'Lost Lead', value: 'Lost Lead' },
  { label: 'Referral Partner', value: 'Referral Partner' },
  { label: 'Deleted', value: 'deleted' },
];

const typesOptions = [
  { label: 'All Type', value: 'all' },
  { label: 'Lead Gen', value: 'Lead Gen' },
  { label: 'E-Commerce', value: 'Ecommerce' },
  { label: 'Varied', value: 'Varied' },
  { label: 'Whitelabel', value: 'Whitelabel' },
];

const reportOptions = [
  { label: 'All Reports', value: 'all' },
  { label: 'Missing Integration', value: 'missing_integration' },
  { label: 'Integrated', value: 'integrated' },
];

const ExportModal: React.FC<WeeklyProps> = ({
  closeModal,
  status,
  type,
  service,
  integration,
}) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<{
    label: string;
    value: string;
  } | null>(status ? status : statusOptions[0]);

  const [selectedType, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(type ? type : typesOptions[0]);
  const [selectedService, setSelectedService] = useState<{
    label: string;
    value: string;
  } | null>(service ? service : { label: 'All Services', value: 'all' });
  const [selectedIntegration, setSelectedIntegration] = useState<{
    label: string;
    value: string;
  } | null>(integration ? integration : reportOptions[0]);

  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  useEffect(() => {
    const flattenedData = exportData.map((item) => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      const params = {
        status: selectedStatus?.value,
        category_type: selectedType?.value,
        service: selectedService?.value,
        integration: selectedIntegration?.value,
        page: 1,
        limit: 10000,
      };

      try {
        const res = await Api.Client.getClients(params);
        if (!res.error && res.data.length > 0) {
          setExportData(res.data);
          setDownloadReady(true);
        } else {
          setDownloadReady(false);
          setErrorMessage(
            'No data available to download. Please update the filters.'
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  function flattenObject(obj: any) {
    const am =
      obj !== undefined && obj?.users?.length > 0
        ? obj?.users
            .filter((user) => user.role === 'am')
            .map((user) => user.first_name + ' ' + user.last_name)
        : 'None';
    const seoCon =
      obj !== undefined && obj?.users?.length > 0
        ? obj?.users
            .filter((user) => user.role === 'seocon')
            .map((user) => user.first_name + ' ' + user.last_name)
        : 'None';

    const ppcCon =
      obj !== undefined && obj?.users?.length > 0
        ? obj?.users
            .filter((user) => user.role === 'ppccon')
            .map((user) => user.first_name + ' ' + user.last_name)
        : 'None';

    const socialCon =
      obj !== undefined && obj?.users?.length > 0
        ? obj?.users
            .filter((user) => user.role === 'socon')
            .map((user) => user.first_name + ' ' + user.last_name)
        : 'None';

    let flatObject = {
      name: obj?.name,
      domain: obj?.domain,
      campaignType: obj?.category_type,
      accountManager: am,
      services: obj?.service,
      seoConsultant: seoCon,
      ppcConsultant: ppcCon,
      socialConsultant: socialCon,
      ahrefs: obj?.ahrefs?.status,
      bing: obj?.bing?.status,
      facebookAds: `status: ${obj?.facebook_ads?.status}
        id: ${obj?.facebook_ads?.ads_id}`,
      googleAds: `status: ${obj?.google_ads?.status}
        id: ${obj?.google_ads?.customer_id}`,
      googleAnalytics: `status: ${obj?.google_analytics?.ga4?.status}
        id: ${obj?.google_analytics?.ga4?.account_id}`,
      googleConsole: `status: ${obj?.google_search_console?.status}
        siteUrl: ${obj?.google_search_console?.site_url}`,
      semrush: obj?.semrush?.status,
      rankRanger: obj?.rank_ranger?.status,
    };
    return flatObject;
  }
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col w-full h-[300px] sm:h-[350px] sm:w-[800px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>

        <div className="flex sm:justify-between flex-wrap sm:flex-nowrap gap-2">
          <div>
            <ReactSelect
              options={statusOptions}
              value={selectedStatus}
              placeholder="All Status"
              onChange={(e) => {
                setSelectedStatus(e);
                setDownloadReady(false);
              }}
              className="w-44"
            />
          </div>
          <div>
            <ReactSelect
              options={typesOptions}
              value={selectedType}
              placeholder="All Type"
              onChange={(e) => {
                setSelectedType(e);
                setDownloadReady(false);
              }}
              className="w-40"
            />
          </div>
          <div>
            <ReactSelect
              options={serviceOptions}
              placeholder="All Services"
              value={selectedService}
              onChange={(e) => {
                setSelectedService(e);
                setDownloadReady(false);
              }}
              className="w-[150px]"
            />
          </div>

          <div>
            <ReactSelect
              options={reportOptions}
              placeholder="All"
              value={selectedIntegration}
              isDisabled={selectedService?.value === 'all' ? true : false}
              onChange={(e) => {
                setSelectedIntegration(e);
                setDownloadReady(false);
              }}
              className="w-[200px]"
            />
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center `}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
              data={processedData}
              headers={headers}
              asyncOnClick={true}
              filename={`client-${moment(new Date()).format('YYYY-MM')}.csv`}
              className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Export'}
            </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ExportModal;
