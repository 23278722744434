import { FC, useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import API from '../../../api';
import useClientStore from '../../../store/useClientStore';
import Alert from '../../Alert';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchDropdown from '../../SearchDropdown';

interface GoogleConsoleProps {
  onClose?: () => void;
  data: any;
}

const GoogleConsole: FC<GoogleConsoleProps> = ({ onClose, data }) => {
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAuthorizedAccount, setSelectedAuthorizedAccount] = useState<
    string | undefined
  >(selectedClient.google_search_console?.account_email);

  const [authorizedGoogleAccounts, setAuthorizedGoogleAccounts] = useState<
    any[]
  >([]);
  const [GSearchUrls, setGSearchUrls] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [clientSiteUrl, setClientSiteUrl] = useState(selectedClient.google_search_console?.siteUrl);
  
  const formattedOptions = GSearchUrls.map((item) => ({
    ...item,
    isDisabled: item?.permissionLevel === "siteUnverifiedUser",
  }))

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (clientSiteUrl ) {
      const data = {
        google_search_console: {
          ...selectedClient.google_search_console,
          account_email:selectedAuthorizedAccount,
          siteUrl: clientSiteUrl,
          site_url: clientSiteUrl,
          status: 'activated',
        },
      };
      setIsLoading(true);
      updateClient(selectedClient?.id, data);
      fetchClient(selectedClient?.id);
      setIsLoading(false);
      toast.success(
        <Alert
          icon=""
          title={`GOOGLE SEARCH CONSOLE UPDATE`}
          message={`Account Updated`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      toast.error(
        <Alert
          icon=""
          title={`GOOGLE SEARCH CONSOLE UPDATE`}
          message={`Account Email and Site URL is required`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  useEffect(() => {
    getGoogleAuthorizedAccounts();
  }, []);

  useEffect(() => {
    if (selectedAuthorizedAccount) {
      setIsLoading(true);
      fetchGSearchSiteUrl();
      setIsLoading(false);
    }
  }, [selectedAuthorizedAccount]);

  const getGoogleAuthorizedAccounts = async () => {
    setIsLoading(true);
    try {
      const authorizedAccounts = await API.Analytics.getAuthorizedAccounts();
      setIsLoading(false);
      setAuthorizedGoogleAccounts(authorizedAccounts);
    } catch (error) {
      setError(true);
    }
  };

  const fetchGSearchSiteUrl = async () => {
    try {
      const siteUrls = await API.Analytics.getGSearchSiteUrls({
        account_email: selectedAuthorizedAccount,
      });
      setGSearchUrls(siteUrls);
    } catch (error) {
      setError(true);
    }
  };
  const handleSelectedGoogleEmail = (option) => {
    setSelectedAuthorizedAccount(option.value);
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between">
        <div className="flex gap-[8px] items-center">
          <div className="bg-gray-100 p-2 rounded-lg">
            <img src={data.image} alt="i" width={36} height={36} />
          </div>
          <div className="text-[24px] dark:text-white">
            Google Search Console
          </div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] flex gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="mt-[24px] pt-[24px] border-t mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Google Account Email
            </p>
            <SearchDropdown
          labelKey="id"
          valueKey="id"
          onSelect={(value) => handleSelectedGoogleEmail(value)}
          defaultValue={selectedAuthorizedAccount}
          placeholder="Select Google Email"
          options={authorizedGoogleAccounts}
        />
          </div>
          <div className="">
            <p className="mt-[6px] text-sm text-[#001C44] dark:text-white">
              Site URL
            </p>
            <SearchDropdown
          labelKey="siteUrl"
          valueKey="siteUrl"
          onSelect={(site) => setClientSiteUrl(site.value)}
          defaultValue={clientSiteUrl}
          placeholder="Select Site URL"
          options={formattedOptions}
        />
          </div>
        </div>
        <div>
          <button
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={(e) => handleClick(e)}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default GoogleConsole;
