import Icon from '../../assets/icons/SvgComponent';
import ReactSelect from '../../components/ReactSelect';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmStatus from '../../components/Modals/UserModal/confirmationStatus';
import Alert from '../../components/Alert';
import { useThemeStore } from '../../store/useThemeStore';
import TanStackTable from '../../components/TanStackTable';
import { getRoleName } from '../../utils';
import ImageIcon from '../../components/ImageIcon';
import EditUserModal from '../../components/Modals/UserModal/editUserModal';
import CustomModal from '../../components/CustomModal';
import { useState, useMemo } from 'react';
import useAuthStore from '../../store/useAuthStore';
import api from '../../api';
import { useNavigate } from 'react-router-dom';

interface UserProps {
  selectedClient?: any;
  updateUserStatus?: any;
  isLoading?: any;
}

const User: React.FC<UserProps> = ({
  selectedClient,
  updateUserStatus,
  isLoading,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const { loginAsUser, loginAsClient, user } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [clientDetails, setClientDetails] = useState(true);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState({});

  const type = (selectedUser) => {
    if (selectedUser === undefined) {
      return '';
    }
    if (selectedUser?.gurulytics_role === 'own' || 'stf') {
      return 'users';
    } else {
      return 'omg';
    }
  };

  const handleLoginAs = async (id) => {
    try {
      const data = await api.User.getUser(id);

      if (user.role.level === 'omg') {
        loginAsClient(data);
        navigate(`/dashboard`);
      } else {
        // Handle the case where user.role.level is not 'omg'
        alert('Invalid user role level');
      }
    } catch (error) {
      console.log(error);
      toast.error(
        <Alert
          icon=""
          title={`Client Error`}
          message={`Sorry, this user doesn't have any clients. Add a client to proceed`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleToggleStatus = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    updateUserStatus({ ids: id, status: newStatus });
    toast.success(
      <Alert
        icon=""
        title={`CLIENT USER STATUS`}
        message={`Client User's status been changed successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setConfirmationStatus(false);
    setConfirmStatus({});
  };

  let formattedUsers = useMemo(() => {
    if (
      !selectedClient ||
      !selectedClient?.users ||
      !Array.isArray(selectedClient?.users)
    ) {
      return [];
    }
    return selectedClient?.users
      ?.filter((item: any) => ['own', 'stf'].includes(item.role))
      .map((user: any) => ({
        nameAndEmail: {
          data: {
            name: user?.last_name
              ? `${user.first_name} ${user.last_name}`
              : user?.first_name || '',
          },
          name:
            `${user?.first_name} ${user.last_name ? user.last_name : ''}` || '',
          email: user?.email || '',
        },
        role: user?.role || '',
        isEnabled: user,
      }));
  }, [selectedClient]);

  const columns = [
    // {
    //   id: 'Select',
    //   header: ({ table }: any) => {
    //     return (
    //       <div className="flex items-center justify-center w-[40px]">
    //         <IndeterminateCheckbox
    //           {...{
    //             checked: table.getIsAllPageRowsSelected(),
    //             indeterminate: table.getIsSomeRowsSelected(),
    //             onChange: table.getToggleAllRowsSelectedHandler(),
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    //   cell: ({ row }: any) => {
    //     return (
    //       <div className="flex items-center justify-center w-[40px]">
    //         <IndeterminateCheckbox
    //           {...{
    //             checked: row.getIsSelected(),
    //             disabled: !row.getCanSelect(),
    //             indeterminate: row.getIsSomeSelected(),
    //             onChange: row.getToggleSelectedHandler(),
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      id: 'Name and Email',
      header: () => (
        <div className="w-full flex flex-start pl-3">Name and Email</div>
      ),
      accessorKey: 'nameAndEmail',
      cell: (props: any) => {
        return (
          <div className="text-sm  flex gap-2 items-center w-[200px] sm:min-w-[40vw]  ml-2">
            <div>
              <ImageIcon
                data={props.getValue()?.data}
                size={8}
                textSize={'xs'}
              />
            </div>
            <div>
              <div className="font-semibold flex justify-start">
                {props.getValue()?.name}
              </div>
              <div className="font-base flex justify-start">
                {props.getValue()?.email}
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   id: 'Clients',
    //   header: () => <div className="w-full flex flex-start ">Clients</div>,
    //   accessorKey: 'clients',
    //   cell: (props: any) => (
    //     <div className="flex gap-2 justify-start cursor-pointer">
    //       {Array.isArray(props.getValue()) && props.getValue().length ? (
    //         props
    //           .getValue()
    //           .map(({ client }: any, index: number) => (
    //             <img
    //               className={
    //                 props.getValue()
    //                   ? `w-6 h-6 rounded-full bg-[#2fa8e0] dark:bg-[#fff]`
    //                   : `hidden`
    //               }
    //               src={client?.image}
    //               alt="CLIENT"
    //               key={index}
    //             />
    //           ))
    //       ) : (
    //         <span>N/A</span>
    //       )}
    //     </div>
    //   ),
    // },
    {
      id: 'Role',
      header: 'Role',
      accessorKey: 'role',
      cell: (props: any) => (
        <div className="flex items-center gap-2 justify-center w-[200px] sm:w-full">
          <span className="text-xs rounded-full border max-w-fit bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000] font-inter">
            {getRoleName(props.getValue())}
          </span>
        </div>
      ),
    },
    {
      id: 'Status',
      header: () => (
        <div className="w-[100px] sm:w-[4vw] flex justify-center">Status</div>
      ),
      accessorKey: 'isEnabled',
      cell: (props: any) => (
        <div className="flex items-center gap-2 justify-center w-[100px] sm:w-[4vw]">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              defaultChecked={props.getValue()?.status === 'active'}
              value={props.getValue()?.status}
              onClick={() => {
                setConfirmStatus(props.getValue());
                setConfirmationStatus(true);
              }}
            />
            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
          </label>
        </div>
      ),
    },
    {
      id: 'Action',
      header: () => <div className="w-[3vw] flex justify-center">Action</div>,
      accessorKey: 'isEnabled',
      cell: (props: any) => (
        <div className="flex gap-4 w-[3vw] justify-center cursor-pointer">
          <div
            onClick={() => {
              setSelectedUser(props.getValue());
              setOpenEditModal(true);
            }}
          >
            <Icon
              name="UserEditIcon"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
          <div onClick={() => handleLoginAs(props.getValue()?.id)}>
            <Icon
              name="Login"
              size={20}
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-white p-[20px]  mb-[32px] dark:bg-gray-800 rounded-lg">
      <div className="pt-2">
        <TanStackTable
          data={formattedUsers}
          columns={columns}
          totalPages={1}
          loading={isLoading}
          page={1}
          setPage={1}
          tableHeight="h-[60dvh]"
          setSelectedRow={setSelectedRow}
        />
      </div>
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditUserModal
          user={selectedUser}
          type=""
          clientDetails={clientDetails}
          onClose={() => setOpenEditModal(false)}
        />
      </CustomModal>
      <CustomModal
        open={confirmationStatus}
        onClose={() => setConfirmationStatus(false)}
      >
        <ConfirmStatus
          user={confirmStatus}
          onClose={() => setConfirmationStatus(false)}
          action={handleToggleStatus}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default User;
