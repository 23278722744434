import { create } from 'zustand';
// import Api from '../api';
import { devtools } from 'zustand/middleware';
import Api from '../api';

interface IGoalStore {
  goals: any;
  message: '',
  error: false,
  loading: false,
  errorMessages: '',
  fetchGoals: (data: any) => void
}

const useGoalStore = create<IGoalStore>(
  devtools((set) => ({
    goals: [],
    message: '',
    error: false,
    loading: false,
    errorMessages: '',
    fetchGoals: async (data) => {
      set(() => ({ loading: true }));
      const response = await Api.Goal.getGoals(data);
      set(() => ({ goals: response, loading: false }));
    },
  })) as any
);


export default useGoalStore;