type Props = {
  data?: any;
  size?: any;
  textSize?: any;
  customStyle?: string;
};

const ImageIcon: React.FC<Props> = ({ data, size, textSize, customStyle }) => {
  const getClientInitials = (name: string) => {
    const nameParts = (name?.length && name?.split(' ').filter(Boolean)) || [];
    let initials = '';

    if (nameParts.length > 0) {
      initials += nameParts[0][0];

      if (nameParts.length > 1) {
        if (nameParts[1].includes('(')) {
          initials += nameParts[1][1];
        } else {
          initials += nameParts[1][0];
        }
      }
    }

    return initials;
  };

  return (
    <div className={`w-${size} h-${size} rounded-full overflow-hidden`}>
      {data?.profile_pic ? (
        <div>
          <img
            src={data?.profile_pic}
            alt={data?.first_name}
            className="object-fit"
          />
        </div>
      ) : data.image ? (
        <img src={data.image} alt={data.first_name} className="object-fit" />
      ) : data ? (
        <div
          className={`bg-[#345578] flex items-center justify-center w-full h-full text-${textSize} uppercase ${customStyle ? customStyle : 'text-white'}`}
        >
          {data.first_name &&
            data.last_name &&
            getClientInitials(`${data.first_name} ${data.last_name}`)}
          {data && data.name
            ? getClientInitials(data.name)
            : getClientInitials(data)}
        </div>
      ) : (
        <div className="text-[10px] flex justify-center items-center w-full h-full bg-[#345578] text-white">
          N/A
        </div>
      )}
    </div>
  );
};

export default ImageIcon;
