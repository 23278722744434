import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import useClientStore from '../../../store/useClientStore';
import useGoogleStore from '../../../store/useGoogleStore';
import API from '../../../api';
import { Button } from '@tremor/react';
interface GA4ModalProps {
  onClose?: () => void;
  data: any;
}

const templateOptions = [
  { value: 'Lead Gen', label: 'Lead-Gen Dashboard' },
  { value: 'Ecommerce', label: 'E-Commerce Dashboard' },
];

const GA4Modal: React.FC<GA4ModalProps> = ({ onClose, data }) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { selectedClient, fetchClient, updateClient } = useClientStore(
    (state) => state
  );
  const [isLoading, setIsLoading] = useState(false);
  const { accounts, fetchAccounts, fetchGA4Accounts, ga4Accounts } =
    useGoogleStore((state) => state);
  const defaultStatus = data?.status === 'activated' ? true : false;
  const [status, setStatus] = useState<boolean>(defaultStatus);
  const [authorizedGoogleAccounts, setAuthorizedGoogleAccounts] = useState([]);

  // default value for selectedAuthorizedAccount email
  const [selectedAuthorizedAccount, setSelectedAuthorizedAccount] = useState({
    label: data.data?.account_email,
    value: data.data?.account_email,
  });
  // default value for selectedAccount account_id
  const [selectedAccount, setSelectedAccount] = useState({
    label: data.data?.account_id,
    value: data.data?.account_id,
    webProperties: [],
  });
  // default value for selectedGA4 property_id
  const [selectedGA4, setSelectedGA4] = useState({
    label: data.data?.property_id,
    value: data.data?.property_id,
  });


  // all accounts for selectedAuthorizedAccount email
  const formattedAccounts = accounts.map((item: any) => ({
    value: item.id,
    label: `${item.name}(${item.id})`,
    webProperties: item.webProperties.map((webProperty: any) => ({
      value: webProperty.id,
      label: `${webProperty.name}(${webProperty.id})`,
    })),
  }));


  const formattedGA4Accounts =
    ga4Accounts &&
    ga4Accounts?.map((item: any) => ({
      value: item.name.replace('properties/', ''),
      label: `${item.displayName}(${item.name})`,
    }));

  const [selectedDashboard, setSelectedDashboard] = useState({
    label: selectedClient.category_type,
    value: selectedClient.category_type,
  });

  useEffect(() => {
    getGoogleAuthorizedAccounts();
  }, []);

  useEffect(() => {
    if (selectedAuthorizedAccount?.value?.includes('@')) {
      setIsLoading(true);
      fetchAccounts(selectedAuthorizedAccount.value).then((res: any) => {
        setIsLoading(false);
      });
    }
  }, [selectedAuthorizedAccount]);

  useEffect(() => {
    if (selectedAccount.value) {
      setIsLoading(true);
      fetchGA4Accounts(
        selectedAuthorizedAccount.value,
        selectedAccount.value
      ).then((res: any) => {
        setIsLoading(false);
      });
    }
  }, [selectedAccount.value]);

  const getGoogleAuthorizedAccounts = async () => {
    setIsLoading(true);
    const authorizedAccounts = await API.Analytics.getAuthorizedAccounts();
    setIsLoading(false);
    if (authorizedAccounts[0]?.error) {
      /* Error */     // show error message 
    } else {
      const data = authorizedAccounts.map((item: any) => ({
        value: item.id,
        label: item.id,
      }));
      setAuthorizedGoogleAccounts(data);
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (selectedGA4?.value) {
      const data = {
        category_type: selectedDashboard.value,
        google_analytics: {
          ga4: {
            property_id: selectedGA4.value,
            account_id: selectedAccount.value,
            account_email: selectedAuthorizedAccount.value,
            status: status ? 'activated' : 'inactive',
          },
        },
      };
      setIsLoading(true);
      updateClient(selectedClient?.id, data).then((res: any) => {
        setIsLoading(false);
        fetchClient(selectedClient?.id);
        onClose && onClose();
      });
    } else {
     // show error message 
    }
  };
  const handleSyncData = () => {
    if (selectedAuthorizedAccount?.value?.includes('@')) {
      setIsLoading(true);
      fetchAccounts(selectedAuthorizedAccount.value, true).then((res: any) => {
        setIsLoading(false);
      });
    }
  }
  const handleClientSelect = (option: any) => {
    setSelectedAccount(option);
    setSelectedGA4({ label: '', value: '' });
  };

  const handleSelectedGoogleEmail = (option: any) => {
    setSelectedAuthorizedAccount(option);
    setSelectedAccount({ label: '', value: '', webProperties: [] });
    setSelectedGA4({ label: '', value: '' });
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[32vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between">
        <div className="flex gap-[8px] items-center">
          <div className="w-[34px] h-[34px]">
            <Icon
              name="GoogleAnalytics"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
          <div className="text-[24px] dark:text-white">Google Analytics 4</div>
        </div>
        {isLoading && (
          <div className="absolute right-16 top-5">
            <Icon name="loading" />
          </div>
        )}
        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div
        className="absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] flex gap-[8px] cursor-pointer"
        onClick={onClose}
      >
        <div>Close</div>
        <Icon name="Xclose" size={20} color="#005C9F" />
      </div>
      <div className="flex flex-col justify-between h-[98%]">
        <div>
          <div className="flex flex-col mt-[24px] pt-[24px] border-t mb-[16px]">
            <Button className=' self-end' size='xs' onClick={handleSyncData} disabled={!selectedAuthorizedAccount?.value}>Sync data</Button>
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Google Account Email
            </p>
            <ReactSelect
              options={authorizedGoogleAccounts}
              value={selectedAuthorizedAccount}
              placeholder="Select Account"
              handleOnChange={handleSelectedGoogleEmail}
              isDisabled={isLoading}
            />
          </div>
          <div className="mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Analytics Account
            </p>
            <ReactSelect
              options={formattedAccounts}
              value={selectedAccount}
              handleOnChange={handleClientSelect}
              placeholder="Select Account"
              isDisabled={isLoading}
            />
          </div>
          <div className="mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Property ID
            </p>
            <ReactSelect
              options={formattedGA4Accounts}
              placeholder="Select Property"
              value={selectedGA4}
              handleOnChange={setSelectedGA4}
              isDisabled={isLoading}
            />
          </div>
          <div className="mb-[16px]">
            <p className="mb-[6px] text-sm text-[#001C44] dark:text-white">
              Dashboard Template
            </p>
            <ReactSelect
              options={templateOptions}
              value={selectedDashboard}
              handleOnChange={(option: any) => setSelectedDashboard(option)}
              placeholder="Select Template"
            />
          </div>
          <div className="flex justify-between mt-12 border-t pt-12">
            <div className="dark:text-white">Integration Status</div>
            <div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  defaultChecked={status}
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
                <div className="w-28 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-[5.5rem] rtl:peer-checked:after:-translate-x-[5.5rem] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]" />
                <div
                  className={`w-28 h-6 absolute top-1 left-[30%] text-xs ${status ? 'text-white' : ''}`}
                >
                  {status ? 'Enabled' : 'Disabled'}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSave}
            className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default GA4Modal;
