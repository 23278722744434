import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Api from '../api';

const initialStatus = {
  loading: false,
  errorMsg: '',
  isSuccessful: true,
};
const initialStatus2 = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};

const useBingStore = create(
  devtools((set) => ({
    campaignsReport: initialStatus2,
    bingAds: initialStatus2,
    bingAdsClientUser: initialStatus2,
    fetchBingAdsClientsUser: async (email) => {
      try {
        set(() => ({ bingAdsClientUser: initialStatus2 }));
        const response = await Api.Bing.getBingAdsClientUser(email);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set((state) => ({ ...state, bingAdsClientUser: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { data: response, loading: false };
          set(() => ({ bingAdsClientUser: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({ bingAdsClientUser: initialStatus }));
        throw error;
      }
    },
    fetchBingAdsUserAccount: async (data) => {
      try {
        set(() => ({ bingAds: initialStatus2 }));
        const response = await Api.Bing.getBingAdsUserAccount(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set((state) => ({ ...state, bingAds: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { data: response, loading: false };
          set(() => ({ bingAds: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({ bingAds: initialStatus }));
        throw error;
      }
    },
    fetchBingCampainsReport: async (data) => {
      set(() => ({ campaignsReport: initialStatus2 }));
      const response = await Api.Bing.getCampaignReports(data);
      if (response[0]?.error) {
        const resData = {
          ...response,
          loading: false,
          error: response[0]?.error,
        };
        set(() => ({ campaignsReport: resData }));
      } else {
        const resData = { ...response, loading: false, error: '' };
        set(() => ({
          campaignsReport: resData,
        }));
      }
    },
    resetBingStore: (types) => {
      if (!Array.isArray(types)) {
        console.error('resetStore Bing expects an array of types.');
        return;
      }
      set((state) =>
        types.reduce((acc, type) => {
          // Ensure the type exists in the state before trying to reset it
          if (state[type]) {
            acc[type] = { ...initialStatus }; // Spread to avoid mutation of initialStatus
          }
          return acc;
        }, {})
      );
    },
  }))
);

export default useBingStore;
