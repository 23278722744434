import React from 'react';
import Tooltip from '../../HelpTooltip';
import { Card, Grid, Col } from '@tremor/react';

interface IProps {
  links?: any;
}

const LinkDetails = ({ links }: IProps) => {

  const totalCost = links?.link_items?.reduce((sum, item) => {
    return sum + (Number(item.delivered_link_cost) || 0);
  }, 0);

  const hasDeliveredLinkCost = links?.link_items?.some(
    (item) => item.delivered_link_cost !== undefined
  );

  return (
    <div className="w-full h-[45dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
      {hasDeliveredLinkCost && (
        <div className="mb-3 font-bold dark:text-white">
          Total Cost: {totalCost}
        </div>
      )}
      <Grid numItems={1} className="p-1 gap-4">
        {links?.link_items?.map((data, index) => (
          <Col key={index}>
            <Card>
              <div className="flex justify-between text-sm">
                <div className="mb-2 font-bold"> {data?.link_item_type}</div>
                <div className="mb-2">
                  <span className="capitalize">{data?.link_status}</span>
                </div>
              </div>

              <Grid numItems={2} className="text-xs">
                <Col className="border-r-2 border-[#0029FF] mr-2 pr-2">
                  <div className="font-semibold mb-2">Requested</div>
                  <div className="mb-2">
                    <div className="truncate">
                      <span className="font-semibold">Anchor Texts 1: </span>
                      {data?.requested_anchor_text1 || ' N/A'}
                    </div>
                    <div className="truncate">
                      <span className="font-semibold">Anchor Texts 2:</span>
                      {data?.requested_anchor_text2 || ' N/A'}
                    </div>
                    <div className="truncate">
                      <span className="font-semibold">Anchor Texts 3:</span>
                      {data?.requested_anchor_text3 || ' N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 1: </span>
                        {data.requested_backlink1 || ' N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 1: </span>
                        {data.requested_backlink2 || ' N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 1: </span>
                        {data.requested_backlink3 || ' N/A'}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="truncate">
                      <span className="font-semibold">Link: </span>
                      {data.requested_link || ' N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="truncate">
                      <span className="font-semibold">DR: </span>
                      {data.requested_dr || ' N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Site Traffic: </span>
                    {data?.requested_site_traffic || 'N/A'}
                  </div>

                  <div className="mb-2">
                    <span className="font-semibold">Link Cost: </span>
                    {data?.requested_link_cost || 'N/A'}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Link Price: </span>
                    {data?.requested_link_price || 'N/A'}
                  </div>
                </Col>
                <Col>
                  <div className="font-semibold mb-2">Delivered</div>
                  <div className="mb-2">
                    <div>
                      <span className="font-semibold">Anchor Texts 1: </span>
                      {data?.delivered_anchor_text1 || 'N/A'}
                    </div>
                    <div>
                      <span className="font-semibold">Anchor Texts 2: </span>
                      {data?.delivered_anchor_text2 || 'N/A'}
                    </div>
                    <div>
                      <span className="font-semibold">Anchor Texts 3: </span>
                      {data?.delivered_anchor_text3 || 'N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 1:</span>
                        {data.delivered_backlink1
                          ? data.delivered_backlink1
                          : ' N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 2:</span>
                        {data.delivered_backlink2
                          ? data.delivered_backlink2
                          : ' N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="truncate">
                        <span className="font-semibold">Backlinks 3:</span>
                        {data.delivered_backlink3
                          ? data.delivered_backlink3
                          : ' N/A'}
                      </div>
                    </div>
                  </div>

                  <div className="mb-2 ">
                    <div className="truncate">
                      <span className="font-semibold">Link: </span>
                      {data.delivered_link || ' N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="truncate">
                      <span className="font-semibold">DR: </span>
                      {data.delivered_dr || ' N/A'}
                    </div>
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Site Traffic:</span>
                    {data?.delivered_site_traffic || 'N/A'}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Link Cost: </span>
                    {data?.delivered_link_cost || 'N/A'}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Link Price: </span>
                    {data?.delivered_link_price || 'N/A'}
                  </div>
                </Col>
              </Grid>
            </Card>
          </Col>
        ))}
      </Grid>
    </div>
  );
};

export default LinkDetails;
