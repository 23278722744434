import { Grid, Col } from '@tremor/react';
import DonutBarGroup from '../../DonutCardChart';
import useClientStore from '../../../../store/useClientStore';
import useBingStore from '../../../../store/useBingStore';

const BingDeviceOverview = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);

  const devicesResponse = campaignsReport?.campaignDevices;

  // Devices
  const clicks = devicesResponse?.map((item) => ({
    name: item.DeviceType ?? 'Other',
    value: item?.Clicks,
  }));
  const conversions = devicesResponse?.map((item) => ({
    name: item.DeviceType ?? 'Other',
    value: item?.Conversions,
  }));
  const costs = devicesResponse?.map((item) => ({
    name: item.DeviceType ?? 'Other',
    value: item?.Spend,
  }));
  const impressions = devicesResponse?.map((item) => ({
    name: item.DeviceType ?? 'Other',
    value: item?.Impressions,
  }));
  return (
    <>
      <h2 className="hidden sm:block title-text text-base sm:text-[1.85rem] pb-1">
        Devices Overview
      </h2>
      <div className="w-full flex flex-col gap-8">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={1}
          numItemsLg={2}
          className="gap-4 mt-4"
        >
          <Col>
            <DonutBarGroup
              data={clicks}
              trendTxt={false}
              title={`Clicks`}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={['Computer', 'Smartphone', 'Tablet', 'Other']}
              legendCustomClass="max-w-[150px]"
              info={`Clicks per device`}
              loading={campaignsReport?.loading}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
            />
          </Col>
          <Col>
            <DonutBarGroup
              data={conversions}
              trendTxt={false}
              title={`Conversions`}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={['Computer', 'Smartphone', 'Tablet', 'Other']}
              legendCustomClass="max-w-[150px]"
              info={`Conversions per device`}
              loading={campaignsReport?.loading}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
            />
          </Col>
          <Col>
            <DonutBarGroup
              data={costs}
              trendTxt={false}
              title={`Cost`}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={['Computer', 'Smartphone', 'Tablet', 'Other']}
              legendCustomClass="max-w-[150px]"
              info={`Cost per device`}
              loading={campaignsReport?.loading}
              isDollar={true}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
            />
          </Col>
          <Col>
            <DonutBarGroup
              data={impressions}
              trendTxt={false}
              title={`Impressions`}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={['Computer', 'Smartphone', 'Tablet', 'Other']}
              legendCustomClass="max-w-[150px]"
              info={`Impressions per device`}
              loading={campaignsReport?.loading}
              icon="BingAnalyticsTab"
              propertyId={`Client ID: ${selectedClient?.id}`}
            />
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default BingDeviceOverview;
