import React from 'react';
import { AreaChart, Card } from '@tremor/react';
import moment from 'moment';
import { formatNumber } from '../../../utils/common';

interface IProps {
  performance?: any;
  type?: string;
}

const Charts: React.FC<IProps> = ({ performance, type }) => {
  const ppcClicksData =
    performance?.client?.googleAds?.dailyMetrics?.clicks?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        Clicks:
          performance?.client?.googleAds?.dailyMetrics?.clicks?.values[index],
      })
    );

  const ppcConversionRateData =
    performance?.client?.googleAds?.dailyMetrics?.conversionRate?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        'Conversion Rate':
          performance?.client?.googleAds?.dailyMetrics?.conversionRate?.values[
            index
          ],
      })
    );

  const ppcConversionsData =
    performance?.client?.googleAds?.dailyMetrics?.conversions?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        Conversions:
          performance?.client?.googleAds?.dailyMetrics?.conversions?.values[
            index
          ],
      })
    );

  const ppcConversionsValueData =
    performance?.client?.googleAds?.dailyMetrics?.conversions_value?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        'Conversions Value':
          performance?.client?.googleAds?.dailyMetrics?.conversions_value
            ?.values[index],
      })
    );

  const ppcCostData =
    performance?.client?.googleAds?.dailyMetrics?.cost?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        Cost: performance?.client?.googleAds?.dailyMetrics?.cost?.values[index],
      })
    );

  const ppcCostPerConversionData =
    performance?.client?.googleAds?.dailyMetrics?.costPerConversion?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        'Cost Per Conversion':
          performance?.client?.googleAds?.dailyMetrics?.costPerConversion
            ?.values[index],
      })
    );

  const ppcCPCData =
    performance?.client?.googleAds?.dailyMetrics?.cpc?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        CPC: performance?.client?.googleAds?.dailyMetrics?.cpc?.values[index],
      })
    );

  const ppcCTRData =
    performance?.client?.googleAds?.dailyMetrics?.ctr?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        CTR: performance?.client?.googleAds?.dailyMetrics?.ctr?.values[index],
      })
    );

  const ppcImpressionsData =
    performance?.client?.googleAds?.dailyMetrics?.impressions?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        Impressions:
          performance?.client?.googleAds?.dailyMetrics?.impressions?.values[
            index
          ],
      })
    );

  const ppcROASData =
    performance?.client?.googleAds?.dailyMetrics?.roas?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        ROAS: performance?.client?.googleAds?.dailyMetrics?.roas?.values[index],
      })
    );

  const organicConversionsData =
    performance?.organicConversions?.dailyMetrics?.dates?.map(
      (date: string, index: number) => ({
        date: moment(date).format('MMM DD'),
        'Organic Conversions':
          performance?.organicConversions?.dailyMetrics?.values[index],
      })
    );
  const revenueData = performance?.organicRevenue?.dailyMetrics?.dates?.map(
    (date: string, index: number) => ({
      date: moment(date).format('MMM DD'),
      'Organic Revenue':
        performance?.organicRevenue?.dailyMetrics?.values[index],
    })
  );
  const organicUsersData = performance?.organicUsers?.dailyMetrics?.dates?.map(
    (date: string, index: number) => ({
      date: moment(date).format('MMM DD'),
      'Organic Users': performance?.organicUsers?.dailyMetrics?.values[index],
    })
  );
  const newUsersData = performance?.newUsers?.dailyMetrics?.dates?.map(
    (date: string, index: number) => ({
      date: moment(date).format('MMM DD'),
      'New Users': performance?.newUsers?.dailyMetrics?.values[index],
    })
  );

  return (
    <>
      {type === 'ppc_google' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 p-1">
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.clicks)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcClicksData}
              index="date"
              yAxisWidth={40}
              categories={['Clicks']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.conversion_rate)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcConversionRateData}
              index="date"
              yAxisWidth={40}
              categories={['Conversion Rate']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.conversions)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcConversionsData}
              index="date"
              yAxisWidth={40}
              categories={['Conversions']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.conversions_value)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcConversionsValueData}
              index="date"
              yAxisWidth={40}
              categories={['Conversions Value']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.cost)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcCostData}
              index="date"
              yAxisWidth={40}
              categories={['Cost']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.costPerConversion)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcCostPerConversionData}
              index="date"
              yAxisWidth={40}
              categories={['Cost Per Conversion']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.cpc)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcCPCData}
              index="date"
              yAxisWidth={40}
              categories={['CPC']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.ctr)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcCTRData}
              index="date"
              yAxisWidth={40}
              categories={['CTR']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.impressions)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcImpressionsData}
              index="date"
              yAxisWidth={40}
              categories={['Impressions']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {formatNumber(performance?.client?.googleAds?.roas)}
            </span>
            <AreaChart
              className="h-72 "
              data={ppcROASData}
              index="date"
              yAxisWidth={40}
              categories={['ROAS']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 p-1">
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {performance?.organicConversions?.current}
            </span>
            <AreaChart
              className="h-72 "
              data={organicConversionsData}
              index="date"
              yAxisWidth={40}
              categories={['Organic Conversions']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {performance?.organicRevenue?.current} $
            </span>
            <AreaChart
              className="h-72 "
              data={revenueData}
              index="date"
              yAxisWidth={40}
              categories={['Organic Revenue']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {performance?.organicUsers?.current}
            </span>
            <AreaChart
              className="h-72 "
              data={organicUsersData}
              index="date"
              yAxisWidth={40}
              categories={['Organic Users']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
          <Card className="relative p-4">
            <span
              className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
            >
              {newUsersData?.length !== 0 ? performance?.newUsers?.current : ''}
            </span>
            <AreaChart
              className="h-64 mt-4"
              data={newUsersData}
              index="date"
              yAxisWidth={40}
              categories={['New Users']}
              colors={['indigo', 'cyan']}
              valueFormatter={(value) => `${value}`}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default Charts;
