import React, { useEffect, useState, useRef } from 'react';
import { Card, Grid, Col } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent';
import General from './General';
import ImageIcon from '../../ImageIcon';
import LinkDetails from './LinkDetails';
import useLinkBriefStore from '../../../store/useLinkBriefStore';
import moment from 'moment';
import { useThemeStore } from '../../../store/useThemeStore';

interface IProps {
  link?: any;
  onClose?: () => void;
}

const LinksLookupModal = ({ link, onClose }: IProps) => {
  const { mode } = useThemeStore((state) => state);
  const { fetchAggregatedLinkBriefItem, briefItem } = useLinkBriefStore(
    (state) => state
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [height, setHeight] = useState<number | string>('0px');
  const contentRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const filter = {
      page: 1,
      limit: 10,
      id: link,
    };
    if (link) {
      fetchAggregatedLinkBriefItem(filter);
    }
  }, [link]);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => {
      if (prevIndex === index) {
        setHeight('0px');
        return null;
      } else {
        const contentHeight = contentRefs.current[index]?.scrollHeight ?? '0px';
        setHeight(`${contentHeight}px`);
        return index;
      }
    });
  };


  return (
    <div
      className="w-screen relative"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="absolute right-0 bg-[#fff] dark:bg-[#1F2937] p-6 z-[999] w-screen md:w-[60vw] h-screen">
        {briefItem?.loading ? (
          <div className="flex justify-center items-center h-screen">
            <Icon name="Loading" size={80} />
          </div>
        ) : (
          <>
            <div className="flex gap-2 items-center">
              {briefItem?.link_brief_items?.length > 0 && (
                <>
                  <div className="text-xl font-bold dark:text-white">
                    {briefItem?.link_brief_items[0]?.name}
                  </div>
                  <div className="text-sm bg-[#ADF8FF] font-bold px-3 py-1 rounded-full">
                    Cycle {briefItem?.link_brief_items[0]?.cycle}
                  </div>
                </>
              )}
            </div>
            {briefItem?.link_brief_items?.map((data, index) => (
              <div key={index} className="mb-6">
                <div className="my-8">
                  <div
                    className="flex justify-between items-center cursor-pointer p-4 border rounded-lg"
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="flex gap-2 items-center">
                      <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
                        <div className="flex flex-wrap gap-2 sm:flex-nowrap items-center text-sm">
                          <div className="text-gray-400 text-sm border-r pr-3">
                            <div className="text-black dark:text-white text-sm font-inter font-[600]">
                              {data?.link_brief_id?.brief_name}
                            </div>
                            <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                              Name
                            </div>
                          </div>
                          <div className="text-gray-400 text-sm border-r pr-3">
                            <div className="text-black dark:text-white text-sm font-inter font-[600] capitalize">
                              {data?.link_brief_id?.brief_type}
                            </div>
                            <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                              Type
                            </div>
                          </div>
                          <div className="text-gray-400 text-sm border-r pr-3">
                            <div className="text-black dark:text-white text-sm font-inter font-[600]">
                              {moment(data?.link_brief_id?.createdAt).format(
                                'MMMM D, YYYY h:mm A'
                              )}
                            </div>
                            <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                              Created At
                            </div>
                          </div>
                          <div className="text-gray-400 text-sm px-3 border-r">
                            <span
                              className={`text-[#000] text-sm font-inter font-[600] capitalize dark:text-white`}
                            >
                              {data?.link_brief_id?.brief_status}
                            </span>
                            <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                              Status
                            </div>
                          </div>
                          <div className="text-gray-400 text-sm px-3 border-r">
                            <div
                              className={`text-[#000] text-center text-sm font-inter font-[600] dark:text-white`}
                            >
                              {data?.link_brief_id?.cycle}
                            </div>
                            <div className="text-[10px] uppercase text-[#636466] font-inter font-[400]">
                              Cycle
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Icon
                      name={activeIndex === index ? 'ChevronUp' : 'ChevronDown'}
                    />
                  </div>
                  <div
                    ref={(el) => (contentRefs.current[index] = el)}
                    className={`overflow-hidden transition-all duration-500 ease-in-out ${
                      activeIndex === index ? 'opacity-100' : 'opacity-0'
                    }`}
                    style={{
                      maxHeight: activeIndex === index ? height : '0px',
                    }}
                  >
                    <div className="p-4 border h-[60dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                      <Grid numItems={2} className="gap-4 mb-3">
                        <Col>
                          <Card className="flex items-center gap-3 p-3">
                            <div>
                              <ImageIcon
                                data={data?.account_manager}
                                size={8}
                                textSize={'xs'}
                              />
                            </div>
                            <div>
                              <div className="text-black dark:text-white font-bold text-xs sm:text-sm">
                                {data?.account_manager}
                              </div>
                              <div className="text-[#7C7E7F] font-inter font-[400] text-xs">
                                Account Manager
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col>
                          <Card className="flex items-center gap-3 p-3">
                            <div>
                              <ImageIcon
                                data={data?.seo_consultant}
                                size={8}
                                textSize={'xs'}
                              />
                            </div>
                            <div>
                              <div className="text-black dark:text-white font-bold text-xs sm:text-sm">
                                {data?.seo_consultant}
                              </div>
                              <div className="text-[#7C7E7F] font-inter font-[400] text-xs">
                                Consultant
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Grid>

                      {/* <General links={data} /> */}
                      <LinkDetails links={data} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <div
          className="absolute top-6 right-6 cursor-pointer"
          onClick={onClose}
        >
          <Icon name="Xclose" />
        </div>
      </div>
    </div>
  );
};

export default LinksLookupModal;
