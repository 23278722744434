import { useMemo } from 'react';
import { Card } from '@tremor/react';
import { formatToCurrency, formatNumber } from '../../../../utils/common';
import Tooltip from '../../../HelpTooltip';
import Icon from '../../../../assets/icons/SvgComponent';
import useClientStore from '../../../../store/useClientStore';
import useGoogleStore from '../../../../store/useGoogleStore';
import AnalyticsTable from '../../AnalyticsTable';
import MobileView from './mobileView';

const AdGroupOverviewPPC = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useGoogleStore((state) => state);
  const adGroupsResponse = campaignsReport?.adGroups;

  const adGroupsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'AD Group',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'AD Group' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>AD Group</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'AD Group' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>AD Group</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'AD Group' || headerId === 'AD Group') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>AD Group</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[13vw] pl-4 h-fit">
            <div className="truncate w-[180px] sm:w-[13vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Impression',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impression' || headerId === 'Impression') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}%
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cpc',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions Rate',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions Rate' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions Rate' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Conversions Rate' ||
            headerId === 'Conversions Rate'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversion_rate',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {formatNumber(props.getValue())}%
          </div>
        ),
      },

      {
        id: 'ROAS',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'ROAS' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'ROAS' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'ROAS' || headerId === 'ROAS') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>ROAS</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'roas',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    let loading = true;

    const data = (adGroupsResponse || []).map((resource: any) => {
      return {
        name: resource?.name,
        clicks: Number(resource?.clicks) ?? 0,
        cost: Number(resource?.cost) ?? 0,
        conversions: Number(resource?.conversions) ?? 0,
        conversion_rate: Number(resource?.conversion_rate) ?? 0,
        impressions: Number(resource?.impressions) ?? 0,
        cpc: Number(resource?.cpc) ?? 0,
        roas: Number(resource?.roas) ?? 0,
        ctr: Number(resource?.ctr) ?? 0,
      };
    });

    if (adGroupsResponse) {
      loading = false;
    }

    return { tableHeader, data, loading };
  }, [adGroupsResponse]);
  return (
    <>
      <div className="block sm:hidden">
        <MobileView
          campaignsReport={campaignsReport}
          adGroupsReportData={adGroupsReportData}
        />
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-between items-center">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
            Ad Group Overview
          </h2>
          <div className="sm:pr-4">
            <Tooltip
              content={
                selectedClient?.google_ads?.customer_id
                  ? `Customer ID: ${selectedClient?.google_ads?.customer_id}`
                  : 'N/A'
              }
              position="top"
              textAlign="text-center"
            >
              <div className="pt-1">
                <Icon name="googleAd" size={20} />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="mt-8">
          {adGroupsReportData?.data?.length === 0 &&
          !campaignsReport.loading ? (
            <Card className="card-item px-1 sm:px-6">
              <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
                <div className="mb-8">
                  <Icon name="NoDataAdGroup" />
                </div>
                <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                  No Ad Groups currently available.
                </p>
                <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
                  Stay ahead by exploring the newest trends and updates in your
                  field. Our focused section delivers key insights and
                  significant advancements directly relevant to your ad
                  strategies
                </p>
              </div>
            </Card>
          ) : (
            <AnalyticsTable
              data={adGroupsReportData?.data}
              columns={adGroupsReportData?.tableHeader}
              loading={campaignsReport.loading}
              totalDocs={
                adGroupsReportData?.data
                  ? adGroupsReportData?.data.length
                  : 'N/A'
              }
              sortByColumnId="Cost"
              pagination={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdGroupOverviewPPC;
