import { requests } from './ApiService';
import { toQueryString } from '../utils/common';
import { Config } from '../config';
const API_ROOT = Config.LINK_BRIEF_API;

export const LinkBrief = {
  getBriefItems: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
      })}&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
  getBriefItem: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
        client_id: filters.id,
      })}&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
  getAggregatedBriefItems: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
      })}&aggregate=true&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
};
