import { BrowserRouter } from 'react-router-dom';
import React, { Fragment } from 'react';
import GlobalStyle from '../src/utils/globalStyles';
import '../src/index.css';
import Router from './routes';
import { productFruits } from 'product-fruits';
import useAuthStore from './store/useAuthStore';
import { isStaging } from './utils';
export default function App() {
  const authStore = useAuthStore();
  const username = authStore?.user?.email || 'NoEmail@example.com';
  if (isStaging()) {
    productFruits.init('kbx2gqLJZ3HQ7b24', 'en', { username: username });
  }
  
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <>
          <Router />
        </>
      </BrowserRouter>
    </>
  );
}
