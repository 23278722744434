import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../api';

interface InitialStatus {
  loading: boolean;
  errorMsg: string;
  isSuccessful: boolean;
}

const initialStatus: InitialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};

interface Filter {
  page?: number;
  limit?: number;
  id?: string; // Added clientID as an optional filter
  // Add more filters here
}

interface LinkBriefState {
  filters: Filter;
  linkBrief: any;
  aggregatedLinkBrief: any;
  briefItem: any;
  fetchLinkBrief: (filter: Filter) => Promise<any>;
  fetchAggregatedLinkBrief: (filter: Filter) => Promise<any>;
  fetchAggregatedLinkBriefItem: (filter: Filter) => Promise<any>;
}

const useLinkBriefStore = create<LinkBriefState>()(
  devtools((set) => ({
    filters: {
      page: 1,
      limit: 1,
      id: '', // Initialize clientID as an empty string or any default value
    },
    linkBrief: [],
    aggregatedLinkBrief: [],
    briefItem: {},
    fetchLinkBrief: async (filter: Filter) => {
      try {
        set(() => ({ linkBrief: { ...initialStatus } }));
        const response = await api.LinkBrief.getBriefItems(filter);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            errorMsg: response[0].error,
            isSuccessful: false,
          };
          set(() => ({ linkBrief: resData }));
          throw new Error(
            'Something went wrong! Please try again later. (LinkBrief Store)'
          );
        } else {
          const resData = { ...response, loading: false, isSuccessful: true };
          set(() => ({ linkBrief: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({
          linkBrief: {
            ...initialStatus,
            loading: false,
            isSuccessful: false,
            errorMsg: (error as Error).message,
          },
        }));
        throw error;
      }
    },
    fetchAggregatedLinkBrief: async (filter: Filter) => {
      try {
        set(() => ({ aggregatedLinkBrief: { ...initialStatus } }));
        const response = await api.LinkBrief.getAggregatedBriefItems(filter);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            errorMsg: response[0].error,
            isSuccessful: false,
          };
          set(() => ({ aggregatedLinkBrief: resData }));
          throw new Error(
            'Something went wrong! Please try again later. (LinkBrief Store)'
          );
        } else {
          const resData = { ...response, loading: false, isSuccessful: true };
          set(() => ({ aggregatedLinkBrief: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({
          aggregatedLinkBrief: {
            ...initialStatus,
            loading: false,
            isSuccessful: false,
            errorMsg: (error as Error).message,
          },
        }));
        throw error;
      }
    },
    fetchAggregatedLinkBriefItem: async (filter: any) => {
      try {
        set(() => ({ briefItem: { ...initialStatus } }));
        const response = await api.LinkBrief.getBriefItem(filter);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            errorMsg: response[0].error,
            isSuccessful: false,
          };
          set(() => ({ briefItem: resData }));
          throw new Error(
            'Something went wrong! Please try again later. (LinkBrief Store)'
          );
        } else {
          const resData = { ...response, loading: false, isSuccessful: true };
          set(() => ({ briefItem: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({
          briefItem: {
            ...initialStatus,
            loading: false,
            isSuccessful: false,
            errorMsg: (error as Error).message,
          },
        }));
        throw error;
      }
    },
  }))
);

export default useLinkBriefStore;
