import React, { useEffect } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import { useLocation } from 'react-router-dom';

interface PaginationProps {
  totalPages: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  totalDocs?: any;
}

const PaginationV2: React.FC<PaginationProps> = ({
  totalPages,
  page,
  setPage,
  limit,
  setLimit,
  totalDocs,
}) => {
  const location = useLocation();
  const { mode } = useThemeStore((state) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';

  useEffect(() => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('page', page?.toString());
    window.history.pushState({}, '', newUrl);
  }, [page]);

  const pages = [...Array(totalPages).keys()].map((num) => num + 1);

  const handlePreviousPage = () => {
    setPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(e.target.value));
  };

  if (totalPages <= 1) return null;

  return (
    <div className="w-full flex justify-center sm:justify-between items-center bg-white dark:bg-gray-800 py-2 rounded-b-md border-t border-gray-300">
      <div className="hidden sm:block font-inter text-xs font-[400] text-[#808DA1] dark:text-white">
        Results: {totalDocs}
      </div>
      <div className="flex items-center justify-between">
        <div className="hidden font-inter text-xs font-[400] text-[#808DA1] dark:text-white mr-2">
          <span>Rows per page:</span>
          <select
            value={limit}
            onChange={handleLimitChange}
            className="ml-2 p-1 rounded-md  dark:text-white"
          >
            {[10, 20, 30, 40, 50].map((limitOption) => (
              <option key={limitOption} value={limitOption}>
                {limitOption}
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleFirstPage}
            className="flex items-center justify-center select-none h-10 text-base font-medium text-[#808DA1] bg-white dark:hover:bg-gray-700 dark:hover:text-white dark:text-white dark:bg-[#1F2937]"
          >
            <Icon name="ChevronsLeftIcon" size={26} color={iconColor} />
          </button>
          <button
            onClick={handlePreviousPage}
            className="flex items-center justify-center select-none h-10 text-base font-medium text-[#808DA1] bg-white dark:hover:bg-gray-700 dark:hover:text-white dark:text-white dark:bg-[#1F2937]"
          >
            <Icon name="ChevronLeft" size={24} color={iconColor} />
          </button>
        </div>
        <div className="flex items-center">
          {page > 3 && totalPages !== 7 && (
            <>
              <button
                className="p-4 mr-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
                onClick={() => setPage(1)}
              >
                1
              </button>
              <span>...</span>
            </>
          )}
          {page > 2 && (
            <button
              className="p-4 ml-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
              onClick={() => setPage(page - 2)}
            >
              {page - 2}
            </button>
          )}
          {page > 1 && (
            <button
              className="p-4 ml-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
              onClick={() => setPage(page - 1)}
            >
              {page - 1}
            </button>
          )}
          <button
            className={`p-4 mx-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-blue-600 ${
              page === page
                ? 'bg-gray-200 cursor-not-allowed text-gray-900'
                : 'cursor-pointer text-gray-600'
            } hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white`}
          >
            {page}
          </button>
          {page < totalPages && (
            <button
              className="p-4 ml-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
              onClick={() => setPage(page + 1)}
            >
              {page + 1}
            </button>
          )}
          {page < totalPages - 1 && (
            <button
              className="p-4 ml-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
              onClick={() => setPage(page + 2)}
            >
              {page + 2}
            </button>
          )}
          {page < totalPages - 2 && totalPages !== 3 && (
            <>
              <span className="ml-1 dark:text-white">...</span>
              <button
                className="p-4 ml-1 text-xs text-center flex items-center justify-center rounded-full select-none h-8 w-8 text-[#808DA1] hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-pointer"
                onClick={() => setPage(totalPages)}
              >
                {totalPages}
              </button>
            </>
          )}
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={handleNextPage}
            className="flex items-center justify-center h-10 select-none text-base font-medium text-[#808DA1] bg-white dark:hover:text-white dark:text-white dark:bg-[#1F2937]"
          >
            <Icon name="ChevronRight" size={24} color={iconColor} />
          </button>
          <button
            onClick={handleLastPage}
            className="flex items-center justify-center select-none h-10 text-base font-medium text-[#808DA1] bg-white dark:hover:bg-gray-700 dark:hover:text-white dark:text-white dark:bg-[#1F2937]"
          >
            <Icon name="ChevronsRightIcon" size={26} color={iconColor} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationV2;
