// import Icon from '../../assets/icons/SvgComponent';
// import ClientHeader from '../../components/ClientDetails/header';
import ReactSelect from '../../components/ReactSelect';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../api';
import useClientStore from '../../store/useClientStore';
import useUserStore from '../../store/useUserStore';
import checkSubmission from './changeComponents/errorHandling.js';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Alert from '../../components/Alert';

//Import Change Types
import {
  SEOPause,
  PPCPause,
  SEOBINGPause,
  PPCBINGPause,
  REMPause,
  METAPause,
  PINTERESTPause,
  TIKTOKPause,
  LINKEDINPause,
  PROGRAMMATICPause,
  CREATIVEPause,
  TACTICALPause,
  WEBDEVPause,
  WEBHOSTPause,
  WEBLANDINGPause,
  WEBPROJECTPause,
} from './changeComponents/pauses.js';
import {
  SEOCancel,
  PPCCancel,
  SEOBINGCancel,
  PPCBINGCancel,
  REMCancel,
  METACancel,
  TIKTOKCancel,
  PINTERESTCancel,
  LINKEDINCancel,
  CREATIVECancel,
  TACTICALCancel,
  PROGRAMMATICCancel,
  WEBDEVCancel,
  WEBHOSTCancel,
  WEBLANDINGCancel,
  WEBPROJECTCancel,
} from './changeComponents/cancels.js';
import {
  SEONonStart,
  PPCNonStart,
  SEOBINGNonStart,
  PPCBINGNonStart,
  REMNonStart,
  METANonStart,
  TIKTOKNonStart,
  PINTERESTNonStart,
  LINKEDINNonStart,
  CREATIVENonStart,
  TACTICALNonStart,
  PROGRAMMATICNonStart,
  WEBDEVNonStart,
  WEBHOSTNonStart,
  WEBLANDINGNonStart,
  WEBPROJECTNonStart,
} from './changeComponents/non_starts.js';
import {
  SEOStopWork,
  PPCStopWork,
  SEOBINGStopWork,
  PPCBINGStopWork,
  REMStopWork,
  METAStopWork,
  PINTERESTStopWork,
  LINKEDINStopWork,
  TIKTOKStopWork,
  PROGRAMMATICStopWork,
  CREATIVEStopWork,
  TACTICALStopWork,
  WEBDEVStopWork,
  WEBHOSTStopWork,
  WEBPROJECTStopWork,
  WEBLANDINGStopWork,
} from './changeComponents/stopWork.js';
import {
  SEODelayBilling,
  PPCDelayBilling,
  SEOBINGDelayBilling,
  PPCBINGDelayBilling,
  REMDelayBilling,
  METADelayBilling,
  PINTERESTDelayBilling,
  TIKTOKDelayBilling,
  LINKEDINDelayBilling,
  PROGRAMMATICDelayBilling,
  CREATIVEDelayBilling,
  TACTICALDelayBilling,
  WEBDEVDelayBilling,
  WEBHOSTDelayBilling,
  WEBLANDINGDelayBilling,
  WEBPROJECTDelayBilling,
} from './changeComponents/delayBilling.js';
import {
  SEOReduceBilling,
  PPCReduceBilling,
  SEOBINGReduceBilling,
  PPCBINGReduceBilling,
  REMReduceBilling,
  METAReduceBilling,
  PINTERESTReduceBilling,
  TIKTOKReduceBilling,
  LINKEDINReduceBilling,
  PROGRAMMATICReduceBilling,
  CREATIVEReduceBilling,
  TACTICALReduceBilling,
  WEBDEVReduceBilling,
  WEBHOSTReduceBilling,
  WEBLANDINGReduceBilling,
  WEBPROJECTReduceBilling,
} from './changeComponents/reduceBilling.js';
import {
  SEORefund,
  PPCRefund,
  SEOBINGRefund,
  PPCBINGRefund,
  REMRefund,
  METARefund,
  PINTERESTRefund,
  TIKTOKRefund,
  LINKEDINRefund,
  PROGRAMMATICRefund,
  CREATIVERefund,
  TACTICALRefund,
  WEBDEVRefund,
  WEBHOSTRefund,
  WEBLANDINGRefund,
  WEBPROJECTRefund,
} from './changeComponents/refund.js';
import {
  SEOApplyCredit,
  PPCApplyCredit,
  SEOBINGApplyCredit,
  PPCBINGApplyCredit,
  REMApplyCredit,
  METAApplyCredit,
  PINTERESTApplyCredit,
  TIKTOKApplyCredit,
  LINKEDINApplyCredit,
  PROGRAMMATICApplyCredit,
  CREATIVEApplyCredit,
  TACTICALApplyCredit,
  WEBDEVApplyCredit,
  WEBHOSTApplyCredit,
  WEBLANDINGApplyCredit,
  WEBPROJECTApplyCredit,
} from './changeComponents/applyCredit.js';
import {
  SEOFlagged,
  PPCFlagged,
  SEOBINGFlagged,
  PPCBINGFlagged,
  REMFlagged,
  METAFlagged,
  PINTERESTFlagged,
  TIKTOKFlagged,
  LINKEDINFlagged,
  PROGRAMMATICFlagged,
  CREATIVEFlagged,
  TACTICALFlagged,
  WEBDEVFlagged,
  WEBHOSTFlagged,
  WEBLANDINGFlagged,
  WEBPROJECTFlagged,
} from './changeComponents/flagged.js';
import {
  SEORestart,
  PPCRestart,
  SEOBINGRestart,
  PPCBINGRestart,
  REMRestart,
  METARestart,
  PINTERESTRestart,
  TIKTOKRestart,
  LINKEDINRestart,
  PROGRAMMATICRestart,
  CREATIVERestart,
  TACTICALRestart,
  WEBDEVRestart,
  WEBHOSTRestart,
  WEBLANDINGRestart,
  WEBPROJECTRestart,
} from './changeComponents/restart.js';
import {
  AMReallocate,
  SEOReallocate,
  PPCReallocate,
} from './changeComponents/staffReallocate.js';

interface ChangeProps {
  selectedClient?: any;
  allStaff?: any;
}

const ChangeRequest: React.FC<ChangeProps> = ({ selectedClient, allStaff }) => {
  const { id } = useParams();
  const [request, setRequest] = useState<{
    label: string;
    value: string;
  } | null>(null);

  var serviceArray = [];
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [subCheckboxes, setSubCheckboxes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [liveServices, setLiveServices] = useState([]);
  const [staffValues, setStaffValues] = useState({
    full: allStaff,
    gadStaff: [],
  });

  const [changeValues, setChangeValues] = useState({
    changeType: '',
    changeSubType: [],
    services: [],
    am: [],
    seo_google: [],
    ppc_google: [],
    seo_bing: [],
    social_bing: [],
    display: [],
    social: [],
    social_meta: [],
    social_pinterest: [],
    social_tiktok: [],
    social_linkedin: [],
    social_tactical: [],
    social_creative: [],
    social_programmatic: [],
    web_maintenance: [],
    web_hosting: [],
    web_landing: [],
    web_project: [],
    lineManager: '',
    clientId: '',
    hubspotId: '',
    domain: '',
    clientStaff: '',
    accountManagerEmail: '',
    location: '',
    daysAsClient: '',
  });

  const checkboxesByOption: { [key: string]: string[] } = {
    reallocate: ['ACCOUNT MANAGER', 'SEO CONSULTANT', 'PPC CONSULTANT'],
    pause: liveServices,
    cancel: liveServices,
    nonstart: liveServices,
    stop: liveServices,
    delay: liveServices,
    reduce: liveServices,
    refund: liveServices,
    apply: liveServices,
    flagged: liveServices,
    restart: liveServices,
  };

  const subCheckboxesOptions: { [key: string]: string[] } = {
    'WEB DEV': ['WEB HOSTING', 'WEB MAINTENANCE', 'LANDING PAGES', 'PROJECT'],
    'SOCIAL MEDIA': [
      'META',
      'TIKTOK',
      'PINTEREST',
      'LINKEDIN',
      'CREATIVE',
      'PROGRAMMATIC',
      'TACTICAL',
    ],
  };

  const handleCheckboxChange = (value: string) => {
    if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );
      setChangeValues({
        ...changeValues,
        services: changeValues.services.filter((item) => item !== value),
      });
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
      setChangeValues({
        ...changeValues,
        services: [...changeValues.services, value],
      });
    }
  };

  const handleWebDevSubCheckboxChange = (value: string) => {
    if (subCheckboxes.includes(value)) {
      setSubCheckboxes(subCheckboxes.filter((item) => item !== value));
    } else {
      setSubCheckboxes([...subCheckboxes, value]);
    }
    if (changeValues.services.includes(value)) {
      setChangeValues({
        ...changeValues,
        services: changeValues.services.filter((item) => item !== value),
      });
    } else {
      setChangeValues({
        ...changeValues,
        services: [...changeValues.services, value],
      });
    }
  };

  const handleChildChange = (e) => {
    switch (e.service) {
      case 'am':
        setChangeValues({ ...changeValues, am: e });
        break;
      case 'seo_google':
        setChangeValues({ ...changeValues, seo_google: e });
        break;
      case 'ppc_google':
        setChangeValues({ ...changeValues, ppc_google: e });
        break;
      case 'seo_bing':
        setChangeValues({ ...changeValues, seo_bing: e });
        break;
      case 'social_bing':
        setChangeValues({ ...changeValues, social_bing: e });
        break;
      case 'display':
        setChangeValues({ ...changeValues, display: e });
        break;
      case 'social_meta':
        setChangeValues({ ...changeValues, social_meta: e });
        break;
      case 'social_pinterest':
        setChangeValues({ ...changeValues, social_pinterest: e });
        break;
      case 'social_tiktok':
        setChangeValues({ ...changeValues, social_tiktok: e });
        break;
      case 'social_linkedin':
        setChangeValues({ ...changeValues, social_linkedin: e });
        break;
      case 'social_creative':
        setChangeValues({ ...changeValues, social_creative: e });
        break;
      case 'social_tactical':
        setChangeValues({ ...changeValues, social_tactical: e });
        break;
      case 'social_programmatic':
        setChangeValues({ ...changeValues, social_programmatic: e });
        break;
      case 'web_maintenance':
        setChangeValues({ ...changeValues, web_maintenance: e });
        break;
      case 'web_hosting':
        setChangeValues({ ...changeValues, web_hosting: e });
        break;
      case 'web_landing':
        setChangeValues({ ...changeValues, web_landing: e });
        break;
      case 'web_project':
        setChangeValues({ ...changeValues, web_project: e });
        break;
    }
  };

  const submitChangeRequest = async () => {
    let errors = await checkSubmission(changeValues, selectedClient);
    console.log('>>> ERRORS >>> ', errors)
    if (errors.failed === true) {
      toast.error(<Alert icon="" title={`ERROR!`} message={errors.message} />, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoading(true);
      const response = await API.ChangeRequest.addChange({ changeValues });
      if (response.message) {
        setChangeValues({
          ...changeValues,
          changeType: '',
          changeSubType: [],
          services: [],
          am: [],
          seo_google: [],
          ppc_google: [],
          seo_bing: [],
          social_bing: [],
          display: [],
          social: [],
          social_meta: [],
          social_pinterest: [],
          social_tiktok: [],
          social_linkedin: [],
          social_tactical: [],
          social_creative: [],
          social_programmatic: [],
          web_maintenance: [],
          web_hosting: [],
          web_landing: [],
          web_project: [],
        });
        toast.success(
          <Alert icon="" title={`SUCCESS`} message={response.message} />,
          {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setIsLoading(false);
      } else if (response[0].error) {
        toast.error(
          <Alert icon="" title={`ERROR!`} message={response[0]?.error} />,
          {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setIsLoading(false);
      } else {
        toast.error(<Alert icon="" title={`ERROR!`} message={response} />, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      }
    }
  };

  const getClientDetails = async () => {
    const fetchedClient = await API.Client.getClient(id);
    setChangeValues({
      ...changeValues,
      hubspotId: fetchedClient.hubspot.company_id,
      domain: fetchedClient.domain,
      clientId: fetchedClient.id,
      clientStaff: fetchedClient.users,
    });

    selectedClient = fetchedClient;

      serviceArray.push({
        status: fetchedClient?.seo_google?.status,
        service: 'GOOGLE SEO',
      });
      serviceArray.push({
        status: fetchedClient?.ppc_google?.status,
        service: 'GOOGLE ADS',
      });
      serviceArray.push({
        status: fetchedClient?.seo_bing?.status,
        service: 'MICROSOFT SEO',
      });
      serviceArray.push({
        status: fetchedClient?.social_bing?.status,
        service: 'MICROSOFT ADS',
      });
      serviceArray.push({
        status: fetchedClient?.display?.status,
        service: 'REMARKETING',
      });
      serviceArray.push({
        status: fetchedClient?.social_meta?.status,
        service: 'META',
      });
      serviceArray.push({
        status: fetchedClient?.social_pinterest?.status,
        service: 'PINTEREST',
      });
      serviceArray.push({
        status: fetchedClient?.social_tiktok?.status,
        service: 'TIKTOK',
      });
      serviceArray.push({
        status: fetchedClient?.social_linkedin?.status,
        service: 'LINKEDIN',
      });
      serviceArray.push({
        status: fetchedClient?.social_tactical?.status,
        service: 'TACTICAL',
      });
      serviceArray.push({
        status: fetchedClient?.social_creative?.status,
        service: 'CREATIVE',
      });
      serviceArray.push({
        status: fetchedClient?.social_programmatic?.status,
        service: 'PROGRAMMATIC',
      });
      serviceArray.push({
        status: fetchedClient?.web_maintenance?.status,
        service: 'WEB MAINTENANCE',
      });
      serviceArray.push({
        status: fetchedClient?.web_hosting?.status,
        service: 'WEB HOSTING',
      });
      serviceArray.push({
        status: fetchedClient?.web_landing?.status,
        service: 'LANDING PAGES',
      });
      serviceArray.push({
        status: fetchedClient?.web_project?.status,
        service: 'PROJECT',
      });
      setLiveServices(serviceArray);
  };

  useEffect(() => {
    let staffArray = [];
    if (allStaff) {
      allStaff.map((key, i) => {
        if (key.gurulytics_role === 'exec' || key.gurulytics_role === 'gad') {
          staffArray.push({
            value: key.first_name + ' ' + key.last_name,
            label: key.first_name + ' ' + key.last_name,
          });
        }
      });
      setStaffValues({ ...staffValues, gadStaff: staffArray });
    }
    getClientDetails();
  }, [allStaff]);

  return (
    <div className="px-6 py-8 w-full font-inter bg-[#F9FAFB] dark:bg-[#191919] h-screen overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
      {/* <ClientHeader /> */}
      <div className="w-full bg-white dark:bg-gray-800 p-[26px] mb-[32px]">
        <div className="text-[14px] font-semibold font-gray-600 mb-[24px] dark:text-white">
          Change Request
        </div>
        <div className="flex  justify-between">
          <div className="w-[32%]">
            <div className="mb-5 text-[14px] dark:text-white">TYPES</div>
            <ReactSelect
              options={[
                //{ value: 'reallocate', label: 'REALLOCATE STAFF' },
                { value: 'pause', label: 'TEMPORARY PAUSE' },
                { value: 'cancel', label: 'CANCEL CLIENT' },
                { value: 'stop', label: 'STOP WORK' },
                { value: 'delay', label: 'DELAY BILLING' },
                { value: 'reduce', label: 'REDUCE BILLING' },
                { value: 'refund', label: 'REFUND' },
                { value: 'apply', label: 'APPLY CREDIT' },
                { value: 'flagged', label: 'FLAGGED' },
                { value: 'restart', label: 'RESTART' },
                { value: 'nonstart', label: 'DID NOT START' },
              ]}
              value={request}
              placeholder="CHANGE TYPE"
              handleOnChange={(selectedOption: any) => {
                setRequest(selectedOption);
                setChangeValues({
                  ...changeValues,
                  changeType: selectedOption.value,
                  services: [],
                  seo_google: [],
                  ppc_google: [],
                  seo_bing: [],
                  social_bing: [],
                  display: [],
                  social: [],
                  social_meta: [],
                  social_pinterest: [],
                  social_tiktok: [],
                  social_linkedin: [],
                  social_tactical: [],
                  social_creative: [],
                  social_programmatic: [],
                  web_maintenance: [],
                  web_hosting: [],
                  web_landing: [],
                  web_project: [],
                  am: [],
                });
                setSelectedCheckboxes([]);
                setSubCheckboxes([]); // Reset selected checkboxes when changing options
              }}
            />
          </div>
          <div className="w-[32%] flex justify-center">
            {request?.value && checkboxesByOption[request.value] ? (
              <div>
                {liveServices.map((val, key) => {
                  console.log(val, request)
                  let disabled = false
                  if(
                    request?.label === 'TEMPORARY PAUSE' && val.status === 'paused' ||
                    request?.label === 'TEMPORARY PAUSE' && val.status === 'cancelling' ||
                    request?.label === 'TEMPORARY PAUSE' && val.status === 'inactive' ||
                    request?.label === 'CANCEL CLIENT' && val.status === 'inactive' ||
                    request?.label === 'CANCEL CLIENT' && val.status === 'cancelling' ||
                    request?.label === 'STOP WORK' && val.status === 'inactive' ||
                    request?.label === 'DELAY BILLING' && val.status === 'inactive' ||
                    request?.label === 'REDUCE BILLING' && val.status === 'inactive' ||
                    request?.label === 'RESTART' && val.status === 'active' ||
                    request?.value === 'cancel' && val.service === 'LANDING PAGES' ||
                    request?.value === 'pause' && val.service === 'LANDING PAGES' ||
                    request?.value === 'delay' && val.service === 'LANDING PAGES' ||
                    request?.value === 'reduce' && val.service === 'LANDING PAGES' ||
                    request?.value === 'restart' && val.service === 'LANDING PAGES' ||
                    request?.value === 'cancel' && val.service === 'PROJECT' ||
                    request?.value === 'pause' && val.service === 'PROJECT' ||
                    request?.value === 'delay' && val.service === 'PROJECT' ||
                    request?.value === 'restart' && val.service === 'PROJECT' ||
                    request?.value === 'reduce' && val.service === 'PROJECT' ||
                    request?.value === 'flagged' && val.status === 'inactive' 

                  ){
                    disabled = true
                  }
                  return (
                    <div
                      key={key}
                      className="flex items-center mb-2"
                      style={{ float: 'left', width: '50%', display: disabled ? 'none' : 'inline'}}
                    >
                      <input
                        id={`checkbox-${key}`}
                        type="checkbox"
                        value={val.service}
                        checked={selectedCheckboxes.includes(val.service)}
                        disabled={disabled}
                        onChange={() => {
                          handleCheckboxChange(val.service);
                          if (
                            val.service === 'WEB DEV' ||
                            val.service === 'SOCIAL MEDIA'
                          ) {
                            setSubCheckboxes([]);
                          }
                        }}
                        className="cursor-pointer peer relative h-[16px] w-[16px] shrink-0 appearance-none rounded-[4px] border border-[#808EA2]  disabled:bg-[#808EA2] checked:after:absolute checked:after:left-0 checked:after:top-0 checked:after:h-full checked:after:w-full checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] checked:after:bg-[length:40px] checked:after:bg-center checked:after:bg-no-repeat checked:after:content-[''] checked:bg-[#005C9F] hover:ring hover:ring-gray-300 focus:outline-none"
                      />
                      <label
                        htmlFor={`checkbox-${key}`}
                        className="ms-2 text-sm text-[#001C44] dark:text-white dark:text-gray-300"
                      >
                        {val.service}
                      </label>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="w-[32%] flex justify-center">
            {selectedCheckboxes.includes('SOCIAL MEDIA') && (
              <div>
                <div className="mb-4 text-[14px] text-[#001C44] dark:text-white">
                  SOCIAL SUB SERVICES
                </div>
                {subCheckboxesOptions['SOCIAL MEDIA'].map(
                  (subCheckboxLabel, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        value={subCheckboxLabel}
                        checked={subCheckboxes.includes(subCheckboxLabel)}
                        onChange={() =>
                          handleWebDevSubCheckboxChange(subCheckboxLabel)
                        }
                        className="cursor-pointer peer relative h-[16px] w-[16px] shrink-0 appearance-none rounded-[4px] border border-[#808EA2] checked:after:absolute checked:after:left-0 checked:after:top-0 checked:after:h-full checked:after:w-full checked:after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] checked:after:bg-[length:40px] checked:after:bg-center checked:after:bg-no-repeat checked:after:content-[''] checked:bg-[#005C9F] hover:ring hover:ring-gray-300 focus:outline-none"
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {subCheckboxLabel}
                      </label>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-white dark:bg-gray-800  p-[26px] mb-[32px]">
        {/* ======== REALLOCATE STAFF ======== */}
        {request?.label === 'REALLOCATE STAFF' &&
        changeValues.services.includes('ACCOUNT MANAGER') ? (
          <AMReallocate
            parentCallback={handleChildChange}
            client={selectedClient}
            staff={allStaff}
          />
        ) : (
          ''
        )}
        {request?.label === 'REALLOCATE STAFF' &&
        changeValues.services.includes('SEO CONSULTANT') ? (
          <SEOReallocate
            parentCallback={handleChildChange}
            client={selectedClient}
            staff={allStaff}
          />
        ) : (
          ''
        )}
        {request?.label === 'REALLOCATE STAFF' &&
        changeValues.services.includes('PPC CONSULTANT') ? (
          <PPCReallocate
            parentCallback={handleChildChange}
            client={selectedClient}
            staff={allStaff}
          />
        ) : (
          ''
        )}
        {/* ======== TEMPORARY PAUSE ======== */}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('META') ? (
          <METAPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'TEMPORARY PAUSE' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTPause
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        ) }

        {/* ======== CANCELLATIONS ======== */}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('META') ? (
          <METACancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVECancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'CANCEL CLIENT' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTCancel
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== NON STARTS ======== */}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEONonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('META') ? (
          <METANonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVENonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DID NOT START' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTNonStart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}

        {/* ======== STOP WORK ======== */}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('META') ? (
          <METAStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'STOP WORK' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTStopWork
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== DELAY BILLING ======== */}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEODelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('META') ? (
          <METADelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'DELAY BILLING' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTDelayBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== REDUCE BILLING ======== */}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
       {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('META') ? (
          <METAReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REDUCE BILLING' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTReduceBilling
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== REFUNDS ======== */}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEORefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('META') ? (
          <METARefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVERefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'REFUND' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTRefund
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== APPLY CREDIT ======== */}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('META') ? (
          <METAApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'APPLY CREDIT' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTApplyCredit
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== FLAGGED ======== */}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEOFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('META') ? (
          <METAFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVEFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'FLAGGED' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTFlagged
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {/* ======== RESTART ======== */}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('GOOGLE SEO') ? (
          <SEORestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('GOOGLE ADS') ? (
          <PPCRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('MICROSOFT SEO') ? (
          <SEOBINGRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('MICROSOFT ADS') ? (
          <PPCBINGRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('REMARKETING') ? (
          <REMRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('META') ? (
          <METARestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('PINTEREST') ? (
          <PINTERESTRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('TIKTOK') ? (
          <TIKTOKRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('LINKEDIN') ? (
          <LINKEDINRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('PROGRAMMATIC') ? (
          <PROGRAMMATICRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('CREATIVE') ? (
          <CREATIVERestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('TACTICAL') ? (
          <TACTICALRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('WEB MAINTENANCE') ? (
          <WEBDEVRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('WEB HOSTING') ? (
          <WEBHOSTRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('LANDING PAGES') ? (
          <WEBLANDINGRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}
        {request?.label === 'RESTART' &&
        changeValues.services.includes('PROJECT') ? (
          <WEBPROJECTRestart
            parentCallback={handleChildChange}
            client={selectedClient}
          />
        ) : (
          ''
        )}

        {selectedCheckboxes.length === 0 &&
        subCheckboxes.length === 0 ? null : (
          <div className="mt-24">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Line manager to approve this change?
            </div>
            <div className="w-[32%] mb-4">
              <ReactSelect
                options={staffValues.gadStaff}
                placeholder="PLEASE SELECT"
                handleOnChange={(selectedOption: any) => {
                  setChangeValues({
                    ...changeValues,
                    lineManager: selectedOption.value,
                  });
                }}
              />
            </div>
            <button
              onClick={submitChangeRequest}
              className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Submit Client Change
            </button>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ChangeRequest;
