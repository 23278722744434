import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import ToolTipV2 from '../../components/TooltipV2';
import { Badge } from '@tremor/react';
import MetricsChart from '../../components/Modals/Performance/metricsChart';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  performanceVisibilityOption: any;
  editClientPerformance: (data) => void;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  performanceVisibilityOption,
  editClientPerformance,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'full_name',
    header: () => (
      <div className="w-[15vw] border-r h-[40px] flex items-center">
        <div className="flex w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Staff Member</div>
          <div>
            {sortingDirection === 'asc' && sortingColumn === 'full_name' && (
              <Icon name="Arrowup" />
            )}
            {sortingDirection === 'desc' && sortingColumn === 'full_name' && (
              <Icon name="ArrowDown" />
            )}
            {sortingDirection === null && <Icon name="Sort" />}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'full_name',
    cell: (props: any) => {
      return (
        <div
          className="w-[15vw] flex justify-start items-center cursor-pointer border-r h-[70px]"
          onClick={() => {
            if (props?.getValue() !== undefined) {
              editClientPerformance(props?.row?.original);
            }
          }}
        >
          <div>
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[15vw] font-inter font-[500] text-[#001C44]">
                {props.getValue()}
              </div>
            </Tooltip>
            <div
              className={`text-left text-xs font-inter font-light text-[#405573] w-fit`}
            >
              {props.getValue()?.category_type === null
                ? 'N/A'
                : props.getValue()?.category_type}
            </div>
          </div>
        </div>
      );
    },
  },
  // revenue column
  {
    id: 'rum',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">RuM</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'rum',
    cell: (props: any) => (
      <div className="w-[9vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44]">
          ${props.getValue().toLocaleString()}
        </div>
      </div>
    ),
  },
  {
    id: 'rum_target',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">RuM Target</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_target' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_target' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'rum_target',
    cell: (props: any) => (
      <div className="w-[9vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44]">
          {props.getValue() ? '$'+props.getValue().toLocaleString() : 'Not Set'}
        </div>
      </div>
    ),
  },
  {
    id: 'reductions',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Reductions</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'reductions',
    cell: (props: any) => {
      const deltaType = props.getValue() > 0
        ? 'ArrowDownLeft' : '';
      const color = props.getValue() > 0
        ? '#FF0000'
        : '#3CCB7F';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                ${props.getValue().toLocaleString()}
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  {
    id: 'rum_util',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">RuM Utilisation</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'rum_util',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? 'ArrowUpLeft' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  /*
  {
    id: 'avg_ret',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Average Retention</div>
          {sortingDirection === 'asc' && sortingColumn === 'avg_ret' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'avg_ret' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'avg_ret',
    cell: (props: any) => (
      <div className="w-[9vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44]">
          {((props.row.original.rum / props.row.original.rum_target)*100).toLocaleString()}%
        </div>
      </div>
    ),
  },
  */
  {
    id: 'last_month',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Last Month</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'last_month',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? '' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  {
    id: 'q1',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Q1</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'q1',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? '' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  {
    id: 'q2',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Q2</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'q2',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? '' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  {
    id: 'q3',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Q3</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'q3',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? '' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  {
    id: 'q4',
    header: () => (
      <div className="w-[9vw] ">
        <div className="flex w-[9vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Q4</div>
          {sortingDirection === 'asc' && sortingColumn === 'rum_util' && (
            <Icon name="Arrowup" />
          )}
          {sortingDirection === 'desc' && sortingColumn === 'rum_util' && (
            <Icon name="ArrowDown" />
          )}
          {sortingDirection === null && <Icon name="Sort" />}
        </div>
      </div>
    ),
    accessorKey: 'q4',
    cell: (props: any) => {
      const deltaType = props.getValue() > 97
        ? '' : 'ArrowDownLeft';
      const color = props.getValue() > 97
        ? '#3CCB7F'
        : '#FF0000';
      return (
        <div className="w-[9vw] ">
        <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
          {
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue().toLocaleString()}%
              </div>
            </div>
          }
        </div>
      </div>
    )},
  },
  // Actions column
  /*
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[5vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'actions',
    cell: (cell: any) => (
      <div className="flex space-x-3 items-center w-[5vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            editClientPerformance(cell?.row.original);
          }}
        >
          <Icon name="edit" />
        </div>
      </div>
    ),
  },
  */
];
