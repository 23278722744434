import { Card } from '@tremor/react';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import AnalyticsTable from '../AnalyticsTable';
import Tooltip from '../../HelpTooltip';
import {
  formatNumber,
  formatNumberWithCommas,
  formatToCurrency,
} from '../../../utils/common';
import useMetaStore from '../../../store/useMetaStore';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchFbAdInsights, useRetryFetchFbAdsetInsights, useRetryFetchFbCampaign } from '../Hooks';

const PerformanceBreakdown = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignInsights, adInsights, adsetInsights } = useMetaStore(
    (state) => state
  );

  const campaignColumns = [
    {
      id: 'Campaign Name',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Campaign Name' && sortDirection === false) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Campaign Name' && sortDirection === true) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'name',
      cell: (props: any) => (
        <div className="flex justify-start font-inter w-[180px] sm:w-[18vw] pl-4 h-fit">
          <Tooltip content={props.getValue()} position="right">
            <div className="truncate w-[150px] sm:max-w-[15vw] overflow-hidden">
              {props.getValue()}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'Ad Spend',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Ad Spend' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Ad Spend' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Ad Spend' || headerId === 'Ad Spend') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'spend',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw]  h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Impressions',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Impressions' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Impressions' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Impressions' || headerId === 'Impressions') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'impressions',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw]  h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Reach',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Reach' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Reach' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Reach' || headerId === 'Reach') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'reach',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Frequency',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Frequency' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Frequency' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Frequency' || headerId === 'Frequency') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'frequency',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPM',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPM' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPM' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPM' || headerId === 'CPM') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpm',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Link Clicks',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Link Clicks' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Link Clicks' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Link Clicks' || headerId === 'Link Clicks') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'linkClicks',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPC',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPC' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPC' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPC' || headerId === 'CPC') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpc',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CTR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CTR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CTR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CTR' || headerId === 'CTR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'ctr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())} %
        </div>
      ),
    },
    {
      id: 'Website Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Website Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[10vw] h-fit">
              <div>Purchases</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Website Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Website Leads' || headerId === 'Website Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'website_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[10vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPL',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPL' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPL' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPL' || headerId === 'CPL') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpl',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Form Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Form Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Form Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Form Leads' || headerId === 'Form Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'form_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CVR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CVR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CVR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CVR' || headerId === 'CVR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cvr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumber(props.getValue(), true)}
        </div>
      ),
    },
    {
      id: 'Purchases',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Position' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Purchases</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Purchases' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Position</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Purchases' || headerId === 'Purchases') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[150px] sm:w-[8vw] h-fit">
              <div>Purchases</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'purchases',
      cell: (props: any) => (
        <div className="flex justify-center font-inter  w-[150px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Average Order Value',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Average Order Value' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Average Order Value' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter  w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (
          headerId !== 'Average Order Value' ||
          headerId === 'Average Order Value'
        ) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'orderValue',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[200px] sm:w-[12vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
  ];
  const campaignData = campaignInsights?.overview?.map((item: any) => {
    return {
      name: item.campaign_name,
      spend: formatNumber(item.spend),
      impressions: formatNumber(item.impressions),
      reach: formatNumber(item.reach),
      frequency: formatNumber(item.frequency),
      cpm: formatNumber(item.cpm),
      linkClicks: formatNumber(item.link_clicks),
      ctr: formatNumber(item.ctr),
      cpc: formatNumber(item.cpc),
      website_leads: formatNumber(item.website_leads),
      cpl: formatNumber(item.cpl),
      form_leads: formatNumber(item.form_leads),
      cvr: formatNumber(item.cvr),
      purchases: formatNumber(item.post_reactions) || 0,
      orderValue: formatNumber(item.post_reactions) || 0,
    };
  });

  const adSetColumns = [
    {
      id: 'Ad Set Name',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Ad Set Name' && sortDirection === false) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ad Set Name</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Ad Set Name' && sortDirection === true) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ad Set Name</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Ad Set Name' || headerId === 'Ad Set Name') {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ad Set Name</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'setName',
      cell: (props: any) => (
        <div className="flex justify-start font-inter w-[160px] sm:w-[18vw] pl-4 h-fit ">
          <Tooltip content={props.getValue()} position="right">
            <div className="truncate w-[150px] sm:max-w-[15vw] overflow-hidden">
              {props.getValue()}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'Campaign Name',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Campaign Name' && sortDirection === false) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[15vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Campaign Name' && sortDirection === true) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[15vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[15vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'compaignName',
      cell: (props: any) => (
        <div className="flex justify-start font-inter w-[160px] sm:w-[15vw] pl-4 h-fit">
          <Tooltip content={props.getValue()} position="right">
            <div className="truncate w-[150px] sm:max-w-[15vw] overflow-hidden">
              {props.getValue()}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'Ad Spend',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Ad Spend' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Ad Spend' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Ad Spend' || headerId === 'Ad Spend') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'spend',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw]  h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Impressions',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Impressions' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Impressions' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Impressions' || headerId === 'Impressions') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'impressions',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw]  h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Reach',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Reach' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Reach' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Reach' || headerId === 'Reach') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'reach',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Frequency',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Frequency' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Frequency' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Frequency' || headerId === 'Frequency') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'frequency',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPM',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPM' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPM' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPM' || headerId === 'CPM') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpm',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Link Clicks',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Link Clicks' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Link Clicks' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Link Clicks' || headerId === 'Link Clicks') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'linkClicks',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPC',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPC' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPC' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPC' || headerId === 'CPC') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpc',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CTR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CTR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CTR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CTR' || headerId === 'CTR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'ctr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())} %
        </div>
      ),
    },
    {
      id: 'Website Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Website Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Purchases</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Website Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Website Leads' || headerId === 'Website Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'website_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[10vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPL',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPL' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPL' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPL' || headerId === 'CPL') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpl',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Form Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Form Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Form Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Form Leads' || headerId === 'Form Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'form_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CVR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CVR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CVR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CVR' || headerId === 'CVR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cvr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumber(props.getValue(), true)}
        </div>
      ),
    },
    {
      id: 'Purchases',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Position' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Purchases</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Purchases' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Position</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Purchases' || headerId === 'Purchases') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Purchases</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'purchases',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Average Order Value',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Average Order Value' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Average Order Value' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (
          headerId !== 'Average Order Value' ||
          headerId === 'Average Order Value'
        ) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[12vw] h-fit">
              <div>Average Order Value</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'orderValue',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[200px] sm:w-[12vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
  ];
  const adSetData = adsetInsights?.overview?.map((item: any) => {
    return {
      compaignName: item.campaign_name,
      setName: item.adset_name,
      spend: formatNumber(item.spend),
      impressions: formatNumber(item.impressions),
      reach: formatNumber(item.reach),
      frequency: formatNumber(item.frequency),
      cpm: formatNumber(item.cpm),
      linkClicks: formatNumber(item.link_clicks),
      ctr: formatNumber(item.ctr),
      cpc: formatNumber(item.cpc),
      website_leads: formatNumber(item.website_leads),
      cpl: formatNumber(item.cpl),
      form_leads: formatNumber(item.form_leads),
      cvr: formatNumber(item.cvr),
      purchases: formatNumber(item.website_purchases) || 0,
      orderValue: formatNumber(item.post_reactions) || 0,
    };
  });

  const adsColumns = [
    // {
    //   id: 'image',
    //   header: () => <div className=""> </div>,
    //   accessorKey: 'image',
    //   cell: (props: any) => (
    //     <div className="flex justify-center font-inter w-10 ">
    //       <div className="w-full flex justify-center">
    //         {props.getValue() ? (
    //           <img
    //             src={props.getValue()}
    //             alt="profile_pic"
    //             className="w-[50px] h-[40px] object-contain "
    //           />
    //         ) : (
    //           <Icon name="DefaultImage" />
    //         )}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      id: 'Ads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Ads' && sortDirection === false) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ads</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Ads' && sortDirection === true) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Ads' || headerId === 'Ads') {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Ads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'adName',
      cell: (props: any) => (
        <div className="flex justify-start font-inter w-[160px] sm:w-[18vw] pl-4 h-fit">
          <div className="flex justify-center font-inter w-10 ">
            <div className="w-full flex justify-center">
              {props.getValue() ? (
                <img
                  src={props.getValue()?.image}
                  alt="profile_pic"
                  className="w-[10px] sm:w-[50px] h-[40px] object-contain "
                />
              ) : (
                <Icon name="DefaultImage" />
              )}
            </div>
          </div>
          <Tooltip content={props.getValue()?.name} position="right">
            <div className="truncate w-[140px] sm:max-w-[14vw] px-2 mt-3 overflow-hidden">
              {props.getValue()?.name}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'Campaign Name',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Campaign Name' && sortDirection === false) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Campaign Name' && sortDirection === true) {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
          return (
            <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
              <div>Campaign Name</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'compaignName',
      cell: (props: any) => (
        <div className="flex justify-start font-inter w-[160px] sm:w-[18vw] pl-4 h-fit">
          <Tooltip content={props.getValue()} position="right">
            <div className="truncate max-w-[18vw] overflow-hidden">
              {props.getValue()}
            </div>
          </Tooltip>
        </div>
      ),
    },
    // {
    //   id: 'Ad Set Name',
    //   header: (props) => {
    //     const headerId = props?.table?.options?.state?.sorting[0]?.id;
    //     const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
    //     if (headerId === 'Ad Set Name' && sortDirection === false) {
    //       return (
    //         <div className="flex justify-start items-center gap-2 font-inter w-[18vw] h-fit pl-4">
    //           <div>Ad Set Name</div>
    //           <Icon name="ChevronUp" color="#808DA1" size={20} />
    //         </div>
    //       );
    //     }

    //     if (headerId === 'Ad Set Name' && sortDirection === true) {
    //       return (
    //         <div className="flex justify-start items-center gap-2 font-inter w-[18vw] h-fit pl-4">
    //           <div>Ad Set Name</div>
    //           <Icon name="ChevronDown" color="#808DA1" size={20} />
    //         </div>
    //       );
    //     }

    //     if (headerId !== 'Ad Set Name' || headerId === 'Ad Set Name') {
    //       return (
    //         <div className="flex justify-start items-center gap-2 font-inter w-[18vw] h-fit pl-4">
    //           <div>Ad Set Name</div>
    //           <Icon name="Sort" />
    //         </div>
    //       );
    //     }
    //   },
    //   accessorKey: 'setName',
    //   cell: (props: any) => (
    //     <div className="flex justify-start font-inter w-[18vw] pl-4 h-fit">
    //       <Tooltip content={props.getValue()} position="right">
    //         <div className="truncate max-w-[18vw] overflow-hidden">
    //           {props.getValue()}
    //         </div>
    //       </Tooltip>
    //     </div>
    //   ),
    // },

    {
      id: 'Ad Spend',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Ad Spend' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Ad Spend' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Ad Spend' || headerId === 'Ad Spend') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Ad Spend</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'spend',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          ${formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Impressions',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Impressions' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Impressions' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Impressions' || headerId === 'Impressions') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Impressions</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'impressions',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Reach',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Reach' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Reach' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Reach' || headerId === 'Reach') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Reach</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'reach',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {isNaN(props.getValue())
            ? '0'
            : formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Frequency',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Frequency' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Frequency' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Frequency' || headerId === 'Frequency') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Frequency</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'frequency',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPM',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPM' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPM' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPM' || headerId === 'CPM') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPM</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpm',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Link Clicks',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Link Clicks' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Link Clicks' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Link Clicks' || headerId === 'Link Clicks') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Link Clicks</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'linkClicks',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPC',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPC' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPC' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPC' || headerId === 'CPC') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPC</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpc',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CTR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CTR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CTR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CTR' || headerId === 'CTR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CTR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'ctr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())} %
        </div>
      ),
    },
    {
      id: 'Website Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Website Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Purchases</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Website Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Website Leads' || headerId === 'Website Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[10vw] h-fit">
              <div>Website Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'website_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[10vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CPL',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CPL' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CPL' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CPL' || headerId === 'CPL') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CPL</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cpl',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatToCurrency(props.getValue())}
        </div>
      ),
    },
    {
      id: 'Form Leads',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'Form Leads' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'Form Leads' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'Form Leads' || headerId === 'Form Leads') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>Form Leads</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'form_leads',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumberWithCommas(props.getValue())}
        </div>
      ),
    },
    {
      id: 'CVR',
      header: (props) => {
        const headerId = props?.table?.options?.state?.sorting[0]?.id;
        const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
        if (headerId === 'CVR' && sortDirection === false) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronUp" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId === 'CVR' && sortDirection === true) {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="ChevronDown" color="#808DA1" size={20} />
            </div>
          );
        }

        if (headerId !== 'CVR' || headerId === 'CVR') {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[8vw] h-fit">
              <div>CVR</div>
              <Icon name="Sort" />
            </div>
          );
        }
      },
      accessorKey: 'cvr',
      cell: (props: any) => (
        <div className="flex justify-center font-inter w-[160px] sm:w-[8vw] h-fit">
          {formatNumber(props.getValue(), true)}
        </div>
      ),
    },
  ];

  const adsData = Object?.entries(adInsights?.data || {}).map(
    ([key, value]: any) => {
      return {
        compaignName: value?.campaign_name,
        adName: {
          name: value?.ad_name,
          image: value?.creatives ? value.creatives[0]?.thumbnail_url : 'N/A',
        },
        setName: value?.adset_name || 'N/A',
        spend: formatNumber(value?.spend) ?? 0,
        impressions: formatNumber(value?.impressions) ?? 0,
        reach: formatNumber(value?.reach) ?? 0,
        frequency: formatNumber(value?.frequency) ?? 0,
        cpm: formatNumber(value?.cpm) ?? 0,
        linkClicks: formatNumber(value?.link_clicks) ?? 0,
        ctr: formatNumber(value?.ctr) ?? 0,
        cpc: formatNumber(value?.cpc) ?? 0,
        website_leads: formatNumber(value.website_leads),
        cpl: formatNumber(value.cpl),
        form_leads: formatNumber(value.form_leads),
        cvr: formatNumber(value.cvr),
        purchases: formatNumber(value?.website_purchases) ?? 0,
        orderValue: formatNumber(value?.website_leads) ?? 0,
      };
    }
  );

  const retryFetchFbCampaign = useRetryFetchFbCampaign();
  const retryFetchFbAdsetInsights = useRetryFetchFbAdsetInsights();
  const retryFetchFbAdInsights = useRetryFetchFbAdInsights();

  return (
    <div>
      <div className="flex justify-start items-center mb-4">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
          Performance Breakdown
        </h2>
      </div>
      <Card className="px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between mb-8">
          <div className="flex gap-2 items-center ">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
              Performance Analysis - Campaign Ads
            </h2>
            <InfoTooltip
              content={campaignInsights?.overview_info}
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`meta`} size={25} />
            </div>
          </Tooltip>
        </div>
        <AnalyticsTable
          data={campaignData ? campaignData : []}
          columns={campaignColumns}
          totalDocs={campaignData ? campaignData?.length : 0}
          sortByColumnId="Ad Spend"
          loading={campaignInsights.loading}
          pagination={false}
          error={campaignInsights.error}
          retryMethod={retryFetchFbCampaign}
        />
      </Card>
      <Card className="mt-4 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between mb-8">
          <div className="flex gap-2 items-center mb-8">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
              Performance Analysis - Ad Sets
            </h2>
            <InfoTooltip content={adsetInsights?.audience_info} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`meta`} size={25} />
            </div>
          </Tooltip>
        </div>

        <AnalyticsTable
          data={adSetData ? adSetData : []}
          columns={adSetColumns}
          totalDocs={adSetData ? adSetData?.length : 0}
          sortByColumnId="Ad Spend"
          loading={adsetInsights?.loading}
          pagination={false}
          error={adsetInsights.error}
          retryMethod={retryFetchFbAdsetInsights}
        />
      </Card>
      <Card className="mt-4 px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-8">
            <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
              Performance Analysis - Ads
            </h2>
            <InfoTooltip content={adInsights?.info} position="top">
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={`Ads ID: ${selectedClient?.facebook_ads?.ads_id}`}
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name={`meta`} size={25} />
            </div>
          </Tooltip>
        </div>
        <AnalyticsTable
          data={adsData ? adsData : []}
          columns={adsColumns}
          totalDocs={adsData ? adsData?.length : 0}
          sortByColumnId="Ad Spend"
          loading={adInsights?.loading}
          pagination={false}
          error={adInsights.error}
          retryMethod={retryFetchFbAdInsights}
        />
      </Card>
    </div>
  );
};

export default PerformanceBreakdown;
