import React from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { getDeltaTypeAndColor } from '../../../utils/performance';
import { formatNumber } from '../../../utils/common';
// import { AreaChart, Card } from '@tremor/react';
// import moment from 'moment';

interface MetricsCardsProps {
  metrics?: any;
  performance?: any;
  type: string;
}

const MetricsCards: React.FC<MetricsCardsProps> = ({ performance, type }) => {

  const ppcCardData = [
    {
      label: 'Clicks',
      value: formatNumber(performance?.client?.googleAds?.clicks || '0'),
      prevPeriod: formatNumber(performance?.client?.googleAds?.clicks || '0'),
    },
    {
      label: 'Conversion Rate',
      value: formatNumber(
        performance?.client?.googleAds?.conversion_rate || '0'
      ),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.conversion_rate || '0'
      ),
    },
    {
      label: 'Conversions',
      value: formatNumber(performance?.client?.googleAds?.conversions || '0'),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.conversions || '0'
      ),
    },
    {
      label: 'Conversions Value',
      value: formatNumber(
        performance?.client?.googleAds?.conversions_value || '0'
      ),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.conversions_value || '0'
      ),
    },
    {
      label: 'Cost Per Conversions',
      value: formatNumber(
        performance?.client?.googleAds?.costPerConversion || '0'
      ),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.costPerConversion || '0'
      ),
    },
    {
      label: 'CPC',
      value: formatNumber(performance?.client?.googleAds?.cpc || '0'),
      prevPeriod: formatNumber(performance?.client?.previous_month?.cpc || '0'),
    },
    {
      label: 'CTR',
      value: formatNumber(performance?.client?.googleAds?.ctr || '0'),
      prevPeriod: formatNumber(performance?.client?.previous_month?.ctr || '0'),
    },
    {
      label: 'Impressions',
      value: formatNumber(performance?.client?.googleAds?.impressions || '0'),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.impressions || '0'
      ),
    },
    {
      label: 'ROAS',
      value: formatNumber(performance?.client?.googleAds?.roas || '0'),
      prevPeriod: formatNumber(
        performance?.client?.previous_month?.roas || '0'
      ),
    },
  ];

  const cardData = [
    {
      label: 'Organic Conversions',
      value: performance?.organicConversions?.current || '0',
      prevPeriod: performance?.organicConversions?.previous || '0',
    },
    {
      label: 'Conversions Return',
      value: performance?.conversions_return?.current || '0',
      prevPeriod: performance?.conversions_return?.previous || '0',
    },
    {
      label: 'Domain Authority',
      value: performance?.domain_authority?.current || '0',
      prevPeriod: performance?.domain_authority?.previous || '0',
    },
    {
      label: 'Ecommerce Return',
      value: performance?.ecommerce_return?.current || '0',
      prevPeriod: performance?.ecommerce_return?.previous || '0',
    },
    {
      label: 'Impressions',
      value: performance?.impressions?.current || '0',
      prevPeriod: performance?.impressions?.previous || '0',
    },

    {
      label: 'New Users',
      value: performance?.newUsers?.current || '0',
      prevPeriod: performance?.newUsers?.previous || '0',
    },
    {
      label: 'Organic Revenue',
      value: performance?.organicRevenue?.current || '0',
      prevPeriod: performance?.organicRevenue?.previous || '0',
    },
    {
      label: 'Organic Users',
      value: performance?.organicUsers?.current || '0',
      prevPeriod: performance?.organicUsers?.previous || '0',
    },
    {
      label: 'Referring Domains',
      value: performance?.referring_domain?.current || '0',
      prevPeriod: performance?.referring_domain?.previous || '0',
    },
  ];
  // const conversionsData =
  //   performance?.organicConversions?.dailyMetrics?.dates?.map(
  //     (date: string, index: number) => ({
  //       date: moment(date).format('MMM DD'),
  //       'Organic Conversions':
  //         performance?.organicConversions?.dailyMetrics?.values[index],
  //     })
  //   );
  // const revenueData = performance?.organicRevenue?.dailyMetrics?.dates?.map(
  //   (date: string, index: number) => ({
  //     date: moment(date).format('MMM DD'),
  //     'Organic Revenue':
  //       performance?.organicRevenue?.dailyMetrics?.values[index],
  //   })
  // );
  // const organicUsersData = performance?.organicUsers?.dailyMetrics?.dates?.map(
  //   (date: string, index: number) => ({
  //     date: moment(date).format('MMM DD'),
  //     'Organic Users': performance?.organicUsers?.dailyMetrics?.values[index],
  //   })
  // );
  // const newUsersData = performance?.newUsers?.dailyMetrics?.dates?.map(
  //   (date: string, index: number) => ({
  //     date: moment(date).format('MMM DD'),
  //     'New Users': performance?.newUsers?.dailyMetrics?.values[index],
  //   })
  // );

  // const MetricsChart = () => {
  //   return (
  //     <div className="grid grid-cols-2 gap-4 mt-4 p-1">
  //       <Card className="relative p-4">
  //         <span
  //           className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
  //         >
  //           {performance?.organicConversions?.current}
  //         </span>
  //         <AreaChart
  //           className="h-72 "
  //           data={conversionsData}
  //           index="date"
  //           yAxisWidth={25}
  //           categories={['Organic Conversions']}
  //           colors={['indigo', 'cyan']}
  //           valueFormatter={(value) => `${value}`}
  //         />
  //       </Card>
  //       <Card className="relative p-4">
  //         <span
  //           className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
  //         >
  //           {performance?.organicRevenue?.current} $
  //         </span>
  //         <AreaChart
  //           className="h-72 "
  //           data={revenueData}
  //           index="date"
  //           yAxisWidth={25}
  //           categories={['Organic Revenue']}
  //           colors={['indigo', 'cyan']}
  //           valueFormatter={(value) => `${value}`}
  //         />
  //       </Card>
  //       <Card className="relative p-4">
  //         <span
  //           className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
  //         >
  //           {performance?.organicUsers?.current}
  //         </span>
  //         <AreaChart
  //           className="h-72 "
  //           data={organicUsersData}
  //           index="date"
  //           yAxisWidth={25}
  //           categories={['Organic Users']}
  //           colors={['indigo', 'cyan']}
  //           valueFormatter={(value) => `${value}`}
  //         />
  //       </Card>
  //       <Card className="relative p-4">
  //         <span
  //           className={`dark:text-gray-400 absolute top-5 left-4 text-black`}
  //         >
  //           {newUsersData.length !== 0 ? performance?.newUsers?.current : ''}
  //         </span>
  //         <AreaChart
  //           className="h-64 mt-4"
  //           data={newUsersData}
  //           index="date"
  //           yAxisWidth={25}
  //           categories={['New Users']}
  //           colors={['indigo', 'cyan']}
  //           valueFormatter={(value) => `${value}`}
  //         />
  //       </Card>
  //     </div>
  //   );
  // };

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 mt-5 p-1">
        {type === 'ppc_google' ? (
          <>
            {ppcCardData?.map((metric, index) => {
              const { deltaType, color } = getDeltaTypeAndColor(
                metric?.value,
                metric?.prevPeriod
              );

              return (
                <div
                  key={index}
                  className={`flex flex-col gap-y-4 p-6 border
          ${index === 0 ? 'rounded-tl-xl' : ''} 
          ${index === 2 ? 'rounded-tr-xl' : ''} 
          ${index === cardData?.length - 3 ? 'rounded-bl-xl' : ''} 
          ${index === cardData?.length - 1 ? 'rounded-br-xl' : ''}`}
                >
                  <p className="font-inter font-[600] text-sm overflow-hidden text-[#000]">
                    {metric?.label}
                  </p>
                  <div className="flex items-center gap-2">
                    <p
                      className={`font-bold text-[30px] overflow-hidden text-[#000]`}
                    >
                      {metric?.value}
                    </p>
                    <Icon name={deltaType} size={25} color={color} />
                  </div>
                  <div className="flex gap-1 items-center w-full mt-0">
                    <div className="font-inter font-[400] text-xs text-[#7C7E7F]">
                      Previous period
                    </div>
                    <div className={`w-2 h-2 rounded-full bg-[${color}]`} />
                    <div className={`font-inter text-xs text-[${color}] mr-1`}>
                      {metric?.prevPeriod}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {cardData?.map((metric, index) => {
              const { deltaType, color } = getDeltaTypeAndColor(
                metric?.value,
                metric?.prevPeriod
              );

              return (
                <div
                  key={index}
                  className={`flex flex-col gap-y-4 p-6 border
          ${index === 0 ? 'rounded-tl-xl' : ''} 
          ${index === 2 ? 'rounded-tr-xl' : ''} 
          ${index === cardData?.length - 3 ? 'rounded-bl-xl' : ''} 
          ${index === cardData?.length - 1 ? 'rounded-br-xl' : ''}`}
                >
                  <p className="font-inter font-[600] text-sm overflow-hidden text-[#000]">
                    {metric?.label}
                  </p>
                  <div className="flex items-center gap-2">
                    <p
                      className={`font-bold text-[30px] overflow-hidden text-[#000]`}
                    >
                      {metric?.value}
                    </p>
                    <Icon name={deltaType} size={25} color={color} />
                  </div>
                  <div className="flex gap-1 items-center w-full mt-0">
                    <div className="font-inter font-[400] text-xs text-[#7C7E7F]">
                      Previous period
                    </div>
                    <div className={`w-2 h-2 rounded-full bg-[${color}]`} />
                    <div className={`font-inter text-xs text-[${color}] mr-1`}>
                      {metric?.prevPeriod}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {/* <MetricsChart /> */}
    </>
  );
};

export default MetricsCards;
