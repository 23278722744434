import React from 'react';

interface Edit05Props {
  size?: number;
}

const Edit05: React.FC<Edit05Props> = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    className="cursor-pointer"
  >
    <path
      d="M9.16675 4.28333H5.66675C4.26662 4.28333 3.56655 4.28333 3.03177 4.55582C2.56137 4.7955 2.17892 5.17795 1.93923 5.64836C1.66675 6.18313 1.66675 6.8832 1.66675 8.28333V15.2833C1.66675 16.6835 1.66675 17.3835 1.93923 17.9183C2.17892 18.3887 2.56137 18.7712 3.03177 19.0108C3.56655 19.2833 4.26662 19.2833 5.66675 19.2833H12.6667C14.0669 19.2833 14.7669 19.2833 15.3017 19.0108C15.7721 18.7712 16.1546 18.3887 16.3943 17.9183C16.6667 17.3835 16.6667 16.6835 16.6667 15.2833V11.7833M6.66673 14.2833H8.06218C8.46983 14.2833 8.67366 14.2833 8.86547 14.2373C9.03553 14.1965 9.1981 14.1291 9.34722 14.0377C9.51542 13.9347 9.65954 13.7905 9.9478 13.5023L17.9167 5.53333C18.6071 4.84298 18.6071 3.72369 17.9167 3.03333C17.2264 2.34298 16.1071 2.34297 15.4167 3.03333L7.44778 11.0023C7.15952 11.2905 7.0154 11.4347 6.91233 11.6029C6.82094 11.752 6.7536 11.9146 6.71278 12.0846C6.66673 12.2764 6.66673 12.4803 6.66673 12.8879V14.2833Z"
      stroke="#7C7E7F"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Edit05;
