import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { Input } from './myDetails';
import { toast } from 'react-toastify';
import { getOmgRoles, getClientRoles, isAdminRole } from '../../../utils';
import { emailValidation } from '../../../utils/validation';
import useClientStore from '../../../store/useClientStore';
import useUserStore from '../../../store/useUserStore';
import ImageIcon from '../../ImageIcon';
import Alert from '../../Alert';
import DropdownUser from '../../DropdownUser';
import { useThemeStore } from '../../../store/useThemeStore';

interface AddModalProps {
  user?: any;
  type?: string;
  updateUser?: any;
  onClose?: any;
}

type FormState = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  hubspot_id: string;
  teamwork_id: string;
  rum_target: number;
  gurulytics_role: string;
  monthly_insights_report: string;
  clients: { name: string; id: string }[];
  phone: string;
};

const AddUserModal: React.FC<AddModalProps> = ({
  user,
  type,
  updateUser,
  onClose,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { fetchClients, clients } = useClientStore((state) => state);
  const { createUser } = useUserStore((state) => state);
  const [searchClientText, setSearchClientText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAllClients, setShowAllClients] = useState(false);
  const [formData, setFormData] = useState<FormState>(user);
  const [openDropdown, setOpenDropdown] = useState(false);

  let clientsToShow: { name: string; id: string }[] = [];
  if (formData && formData.clients && Array.isArray(formData.clients)) {
    if (showAllClients) {
      clientsToShow = formData.clients;
    } else {
      clientsToShow = formData.clients.slice(0, 10);
    }
  }

  const getUserInitials = (name: string) => {
    if (name) {
      const nameParts = name?.split(' ');
      let initials = '';

      if (nameParts.length > 0) {
        initials += nameParts[0][0];

        if (nameParts.length > 1) {
          initials += nameParts[1][0];
        }
      }
      return initials;
    }
  };

  const toggleShowAll = () => {
    setShowAllClients(!showAllClients);
  };

  useEffect(() => {
    if (searchClientText?.length > 2) {
      autoCompleteFetchClients();
    }
    if (searchClientText === '') {
      setOpenDropdown(false);
    }
  }, [searchClientText]);

  //autocomplete fetch clients
  const autoCompleteFetchClients = () => {
    const filters = {
      status: 'all',
      search: searchClientText,
      page: 1,
      limit: 100,
    };
    fetchClients(filters);
  };

  const handleUpdateUser = () => {
    const isTrueSet = String(formData.monthly_insights_report) === 'true';
    let data = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      password: formData?.password,
      hubspot_id: formData?.hubspot_id,
      teamwork_id: formData?.teamwork_id,
      role: formData?.gurulytics_role,
      gurulytics_role: formData?.gurulytics_role,
      rum_target: formData?.rum_target,
      monthly_insights_report: isTrueSet,
      clients: formData.clients,
      phone: formData.phone,
    };

    if (user.id) {
      setIsLoading(true);
      updateUser(user.id, data)
        .then((res: any) => {
          setIsLoading(false);
          toast.success(
            <Alert
              icon=""
              title={`USER UPDATE`}
              message={`User has been updated successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        })
        .catch((e: any) => {
          setErrorMessage(e.message);
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setIsLoading(false);
        });
    } else {
      createUser(data)
        .then(() => {
          toast.success(
            <Alert
              icon=""
              title={`ADD USER`}
              message={`User has been created successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        })
        .catch((e: any) =>
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
    }
  };

  const handleSubmit = () => {
    if (
      !formData?.first_name ||
      !formData?.last_name ||
      emailValidation(formData?.email) ||
      !formData?.gurulytics_role
    ) {
      toast.error(
        <Alert
          icon=""
          title={`ERROR MESSAGE!`}
          message={`${
            !formData?.first_name ? 'First Name is required' : 
            !formData?.last_name ? 'Last Name is required' : 
            !formData?.gurulytics_role ? 'Role is required' : 
            emailValidation(formData?.email)
          }`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      handleUpdateUser();
    }
  };

  const handleChange = (event: any) => {
    if (event.target.name === 'client') {
      setSearchClientText(event.target.value);
      setOpenDropdown(true);
    } else {
      const { name, value } = event.target;
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const deleteClient = (client: any) => {
    const newClients = formData.clients.filter((c: any) => c.id !== client.id);
    setFormData({ ...formData, clients: newClients });
  };

  let roles = type === 'omg' ? getOmgRoles() : getClientRoles();
  roles = roles.filter((item) => item.id !== 'all');

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[26rem] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      style={{ minHeight: '720px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-hidden flex flex-col justify-between">
        <div className="max-h-[100dvh] overflow-y-hidden">
          <div className="px-6 flex  items-start gap-2  border-b border-tertiary-colours-neutral-cool-100 flex justify-between">
            <h2 className="font-inter text-[#001129] text-xl font-semibold my-6  dark:text-[#E6EFF5]  w-[90px]">
              Add User
            </h2>
            <div className="my-4 overflow-hidden">
              {isLoading && <Icon name="Loading" />}
            </div>
            <div
              className="w-[90px] h-full flex justify-end my-6 cursor-pointer"
              onClick={onClose}
            >
              {' '}
              <Icon
                name="Xclose"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
          </div>
          <div className="flex flex-col h-1/2 mt-2 min-h-[85dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="px-6 mb-2">
              <span className="text-sm text-[#344054] dark:text-[#E6EFF5]">
                Profile Picture
              </span>
            </div>
            <div className="flex justify-between w-full border-b pb-4 px-6 mb-2">
              <div className="relative rounded-full w-20 h-20 overflow-hidden">
                <input
                  className="absolute w-full h-full opacity-0 cursor-pointer"
                  type="file"
                />
                <div className="w-full h-full flex items-center justify-center text-gray-600 bg-[#345578]"></div>
              </div>
              <div className="relative  px-2 border border-solid rounded-md border-gray-300 overflow-hidden text-[#344054] ">
                <input
                  className="absolute w-full h-full opacity-0 cursor-pointer"
                  type="file"
                />
                <div className="w-full h-full flex flex-col items-center justify-center text-xs text-gray-600 bg-white dark:bg-[#262627]">
                  <div className="dark:text-[#E6EFF5]">
                    <span className="text-[#005C9F] font-semibold dark:text-[#589778]">
                      Click to upload
                    </span>{' '}
                    or drag and drop
                  </div>
                  <div className="dark:text-[#E6EFF5]">
                    SVG, PNG, JPG or GIF (max.800x400x)
                  </div>
                </div>
              </div>
            </div>
            <form className="relative flex flex-col space-y-4 py-4  px-6  ">
              <Input
                label="First Name"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Last Name"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Email address"
                type="email"
                name="email"
                value={formData?.email}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Password"
                type="password"
                name="password"
                value={formData?.password}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Hubspot ID"
                type="hubspot_id"
                name="hubspot_id"
                value={formData?.hubspot_id}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Teamwork ID"
                type="teamwork_id"
                name="teamwork_id"
                value={formData?.teamwork_id}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="RuM Target"
                type="rum_target"
                name="rum_target"
                value={formData?.rum_target}
                onChange={(e) => handleChange(e)}
              />
              <div>
                <label className="block  text-sm font-medium text-gray-900 dark:text-white">
                  Role
                </label>
                <select
                  className=" border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="gurulytics_role"
                  value={formData?.gurulytics_role}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  <option value="" disabled selected>
                    Select Role
                  </option>

                  {roles.map((role, i) => (
                    <option
                      className="p-[20px] text-lg font-inter font-medium"
                      key={i}
                      value={role.id}
                    >
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
            <div className="px-6 pb-8">
              <div className="border-b border-gray-400">
                <div className="w-full dark:text-[#E6EFF5]  py-6">
                  <h3 className="mb-2">Manage Clients</h3>
                  <p className="text-sm text-gray-600 font-light mb-2">
                    The following users have access to these access.
                  </p>
                  <div>
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute top-0 inset-y-0 start-0 flex items-center ps-3 pointer-events-none max-h-[42px]">
                        <Icon name="User01" color="#667085" />
                      </div>
                      <input
                        type="search"
                        className="block w-full px-1 py-2 ps-10 text-md text-gray-900 border border-gray-600 rounded-lg   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select Clients"
                        name="client"
                        value={searchClientText}
                        onChange={(e) => handleChange(e)}
                      />
                      {openDropdown && (
                        <DropdownUser
                          onSelect={(value: { name: any; id: any }) => {
                            if (Array.isArray(formData?.clients)) {
                              setFormData({
                                ...formData,
                                clients: [
                                  ...formData.clients,
                                  { name: value?.name, id: value?.id },
                                ],
                              });
                              setOpenDropdown(false);
                            } else {
                              setFormData({
                                ...formData,
                                clients: [{ name: value?.name, id: value?.id }],
                              });
                              setOpenDropdown(false);
                            }
                          }}
                          items={
                            Array.isArray(clients?.data) ? clients.data : []
                          }
                          styles={undefined}
                          selected={undefined}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {Array?.isArray(clientsToShow) &&
                clientsToShow?.map((client, index) => (
                  <div
                    className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg  hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white my-2"
                    key={index}
                  >
                    <div className="w-8 h-8 rounded-full overflow-hidden">
                      <div className="bg-[#345578] text-white flex items-center justify-center w-full h-full text-xs uppercase">
                        {getUserInitials(client?.name)}
                      </div>
                    </div>
                    <span className="text-sm flex-1 ms-3 overflow-hidden whitespace-nowrap text-[ellipsis]">
                      {client?.name}
                    </span>
                    <span
                      className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-sm  text-[#B42318]  rounded dark:bg-gray-700 dark:text-gray-400 cursor-pointer"
                      onClick={() => deleteClient(client)}
                    >
                      Remove
                    </span>
                  </div>
                ))}

              {formData?.clients?.length > 10 && (
                <button
                  onClick={toggleShowAll}
                  className="text-sm text-[#005C9F] p-4"
                >
                  {showAllClients
                    ? 'Collapse'
                    : `Expand ${formData?.clients?.length - 10} more`}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="px-6 min-h-[8vh] max-h-[11vh] w-full flex justify-center items-center border-t border-tertiary-colors-neutral-cool-100">
          <button
            type="button"
            onClick={handleSubmit}
            className="w-full rounded-lg text-white border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          >
            Create User
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
