import { useMemo } from 'react';
import { formatToCurrency, formatNumber } from '../../../../utils/common';
import useClientStore from '../../../../store/useClientStore';
import { Card } from '@tremor/react';
import Icon from '../../../../assets/icons/SvgComponent';
import useBingStore from '../../../../store/useBingStore';
import AnalyticsTable from '../../AnalyticsTable';
import Tooltip from '../../../HelpTooltip';

interface IProps {
  filteredCampaigns: any;
}

const BingCampaignOverview = ({ filteredCampaigns }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);

  const campaignsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Campaign Name',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign Name' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign Name' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[13vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[13vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[120px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[120px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },

      {
        id: 'Impression',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impression' || headerId === 'Impression') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cpc',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions Rate',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions Rate' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions Rate' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Conversions Rate' ||
            headerId === 'Conversions Rate'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversion_rate',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'ROAS',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'ROAS' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'ROAS' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'ROAS' || headerId === 'ROAS') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'roas',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    let loading = true;

    const data = filteredCampaigns?.map((resource: any) => ({
      name: resource.CampaignName,
      clicks: Number(resource?.Clicks),
      cost: Number(resource?.Spend),
      conversions: Number(resource?.Conversions),
      conversion_rate: Number(resource?.ConversionRate),
      impressions: Number(resource?.Impressions),
      cpc: Number(resource?.CostPerConversion),
      roas: Number(resource?.ReturnOnAdSpend),
      ctr: Number(resource?.Ctr) || 0,
    }));

    if (filteredCampaigns) {
      loading = false;
    }

    return { tableHeader, data, loading };
  }, [filteredCampaigns]);

  return (
    <>
      <div className="hidden sm:flex justify-between items-center">
        <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
          Campaign Overview
        </h2>
        <div className="sm:pr-4">
          <Tooltip
            content={
              selectedClient?.id ? `Client ID: ${selectedClient?.id}` : 'N/A'
            }
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name="BingAnalyticsTab" size={20} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="mt-8">
        {campaignsReportData?.tableHeader?.length === 0 &&
        !campaignsReport.loading ? (
          <Card className="card-item px-1 sm:px-6">
            <div className="flex flex-col items-center justify-center w-full items-center my-8 h-[148px]">
              <div className="mb-8">
                <Icon name="NoDataCampaign" />
              </div>
              <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                No campaign to display at the moment
              </p>
              <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
                Keep ahead of the curve with the latest industry trends and
                news. Our specialized section is here to equip you with
                essential updates and insightful developments tailored to your
                interests.
              </p>
            </div>
          </Card>
        ) : (
          <AnalyticsTable
            data={campaignsReportData?.data ? campaignsReportData?.data : []}
            columns={campaignsReportData?.tableHeader}
            loading={campaignsReport.loading}
            totalDocs={
              campaignsReportData?.data
                ? campaignsReportData?.data.length
                : 'N/A'
            }
            sortByColumnId="Cost"
            pagination={false}
          />
        )}
      </div>
    </>
  );
};

export default BingCampaignOverview;
