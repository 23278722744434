// performance.tsx
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import TansStackTableV2 from '../../components/TansStackTableV2';
import ReactSelect from '../../components/ReactSelect';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import useVisibilityStore from '../../store/useVisibilityStore';
import { formatToCurrency, formatNumber } from '../../utils/common';
import CustomModal from '../../components/CustomModal';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import PerformanceInsightsModal from '../../components/Modals/Performance/insightsModal';
import usePerformanceStore from '../../store/usePerformanceStore';
import PerformanceModal from '../../components/Modals/Performance';
import MultiFilterPerformanceModal from '../../components/MultiFilterPerformanceModal/kpis';
import 'react-datepicker/dist/react-datepicker.css';
import ExportModal from '../../components/Modals/Performance/Export';
import { generateColumns } from './column';
import useKpiStore from '../../store/useKpiStore';

const roleOptions = [
  { value: 'clients', label: 'Clients' },
  { value: 'user', label: 'Users' },
];

const clientsOptions = [
  { value: 'all', label: 'All Clients' },
  { value: 'my', label: 'My Client' },
];

const cycleOptions = [
  { value: 'all', label: 'All Cycles' },
  { value: 'Cycle 1', label: 'Cycle 1' },
  { value: 'Cycle 2', label: 'Cycle 2' },
];

const statusOptions = [
  { value: 'red', label: 'Red' },
  { value: 'amber', label: 'Amber' },
  { value: 'green', label: 'Green' },
];

const integrationOptions = [
  { value: 'all', label: 'All Integrations' },
  { value: 'missing_integration', label: 'Missing Integration' },
  { value: 'integrated', label: 'Integrated' },
];

interface PerformanceFilterStorage {
  type: string;
  options: any[]; // Adjust this type according to your actual data structure
}

const KPIs: React.FC = () => {
  const [page, setPage] = useState(1);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [openInsightModal, setOpenInsightModal] = useState(false);
  const { performance, fetchPerformance, fetchSummary } = usePerformanceStore();
  const { fetchKpis, kpis, selectedStaff, loading } = useKpiStore((state) => state);
  const [searchText, setSearchText] = useState('');
  const [rowState, setRowState] = useState(true);
  const [mount, setMount] = useState(false);
  const [limit, setLimit] = useState({ label: '250', id: '250' });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const { openVisibility, setOpenVisibility, performanceVisibilityOption } =
    useVisibilityStore();
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [selectedPerformance, setSelectedPerformance] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const lastMonth = moment().subtract(1, 'month').format('MM-yyyy');
  const earliestYear = moment('2021-01-01')
  const minDate = moment(earliestYear, 'MM-yyyy').endOf('month').toDate();
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const today = new Date();
  const [date, setDate] = useState(today);
  const [, setPerformanceFIlter] = useState(null);

  const [performanceFilterStorage, setPerformanceFilterStorage] =
    useState<PerformanceFilterStorage>({
      type: '',
      options: [],
    });

  today.setMonth(today.getMonth() - 1);

  const [, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [cycle, setCycle] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [status, setStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [integration, setIntegration] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [clients, setClients] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const location = useLocation();

  const handleVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  useEffect(() => {
    // Sync initial state
    const usersStorage = localStorage.getItem('performance-filter-storage');
    setPerformanceFilterStorage(JSON.parse(usersStorage));

    const handleStorage = (event) => {
      const getPerformanceFilter = JSON.parse(
        event?.target?.localStorage?.getItem('performance-filter-storage')
      );

      setPerformanceFilterStorage(getPerformanceFilter);
    };
    // // Assuming handlePerformanceFetch now uses clientsStorageState or a similar state for users
    // handlePerformanceFetch();

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);
  useEffect(() => {
    if (mount) {
      handlePerformanceFetch();
    }
  }, [
    date,
    page,
    sortingDirection,
    sortingColumn,
    clients,
    integration,
    cycle,
    status,
    performanceFilterStorage,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateFromUrl = queryParams.get('date');
    const sortDirectionFromURL = queryParams.get('sortDirection');
    const sortColumnFromURL = queryParams.get('sortColumn');
    const cycleFromUrl = queryParams.get('cycle');
    const integrationFromUrl = queryParams.get('integration');
    const clientsFromUrl = queryParams.get('client');
    const roleFromUrl = queryParams.get('role');
    const statusFromUrl = queryParams.get('status');
    const cycleOption = cycleOptions.find(
      (option) => option.value === cycleFromUrl
    );
    const statusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const integrationOption = integrationOptions.find(
      (option) => option.value === integrationFromUrl
    );
    const clientOption = clientsOptions.find(
      (option) => option.value === clientsFromUrl
    );
    const roleOption = roleOptions.find(
      (option) => option.value === roleFromUrl
    );
    const dateUrl = dateFromUrl ? new Date(dateFromUrl) : date;

    setMount(true);
    setSortingColumn(sortColumnFromURL);
    setSortingDirection(sortDirectionFromURL);
    setCycle(cycleOption);
    setIntegration(integrationOption);
    setClients(clientOption);
    setDate(dateUrl);
    setStatus(statusOption);
  }, [location]);

  useEffect(() => {
    if (searchText === '' && mount) {
      handlePerformanceFetch();
    }
  }, [searchText]);

  const handlePerformanceFetch = (clear?: boolean) => {
    let formattedPerformanceClientFilter: string[] = [];
    let formattedPerformanceUserFilter: string[] = [];

    if (performanceFilterStorage?.type === 'clients') {
      formattedPerformanceClientFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option: any) => option.id)
        : [];
      formattedPerformanceUserFilter = []; // Ensure users is empty
    } else {
      formattedPerformanceUserFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option: any) => option.id)
        : [];
      formattedPerformanceClientFilter = []; // Ensure clients is empty
    }

    const payload = {
      search: searchText,
      page: clear ? 1 : page,
      limit: limit.id,
      year: moment(date).format('YYYY'),
      month: moment(date).format('MM'),
      report_date: moment(date).format('YYYY-MM'),
      sortBy: sortingColumn ? sortingColumn : ' ',
      sortOrder: sortingDirection ? sortingDirection : ' ',
      cycle: cycle?.value ? cycle?.value : '',
      integration: integration?.value,
      role: ['gad'],
      status: status?.value === 'all' ? '' : status?.value,
      exclude: [],
      clients: formattedPerformanceClientFilter,
      users: formattedPerformanceUserFilter,
    };
    setPerformanceFIlter(payload);
    //fetchSummary(payload);
    //fetchPerformance(payload);
    setRowState(false);
    fetchKpis(payload);
  };

  const handleSearch = () => {
    try {
      handlePerformanceFetch(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== sortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }

    setSortingDirection(newSortDirection);
    setSortingColumn(sortColumn);

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('sortDirection', newSortDirection);
    newUrl.searchParams.set('sortColumn', sortColumn);
    window.history.pushState({}, '', newUrl);
  };

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      let user_list = [];
      let user_data = [];
  
      data?.data?.forEach((item) => {
        const current_month = moment().format('MMM').toLowerCase();
        const last_month = moment().subtract(1, 'months').format('MMM').toLowerCase();

        const getPercentage = (data, type) => {
          if(data == undefined){
            return 100
          }else{
            var c_budget = typeof data.cancel_budget === 'number' && data.cancel_budget > 0 ? data.cancel_budget : 0
            var p_budget = typeof data.pause_budget === 'number' && data.pause_budget > 0 ? data.cancel_budget : 0
            var r_budget = typeof data.reduction_budget === 'number' && data.reduction_budget > 0 ? data.reduction_budget : 0
            var deficit = c_budget + p_budget + r_budget
            if(type === 'reductions'){
              return deficit
            }else{
              const adjustedBase = data.rum - deficit;
              const percentage = (data.rum_target / adjustedBase) * 100;
              return percentage
            }
          }
        }

        const getQuarter = (data, qtr) => {
          const calculate = (month) => {
            if(month == undefined){
              return 100
            }else{
              var c_budget = typeof month.cancel_budget === 'number' && month.cancel_budget > 0 ? month.cancel_budget : 0
              var p_budget = typeof month.pause_budget === 'number' && month.pause_budget > 0 ? month.cancel_budget : 0
              var r_budget = typeof month.reduction_budget === 'number' && month.reduction_budget > 0 ? month.reduction_budget : 0
              var rum_calc = typeof month.rum === 'number' && month.rum > 0 ? month.rum : 0
              var rum_target_calc = typeof month.rum_target === 'number' && month.rum_target > 0 ? month.rum_target : 0
              var deficit = rum_calc - (c_budget + p_budget + r_budget)

              return (rum_target_calc / deficit) * 100
            }
          }

          var q1 = 0
          var q2 = 0
          var q3 = 0
          var q4 = 0

          if(qtr === 'q1'){
            q1 = calculate(data.jan) + calculate(data.feb) + calculate(data.mar)
            return (q1 / 3).toFixed(0)
          }
          if(qtr === 'q2'){
            q2 = calculate(data.apr) + calculate(data.may) + calculate(data.jun)
            return (q2 / 3).toFixed(0)
          }
          if(qtr === 'q3'){
            q3 = calculate(data.jul) + calculate(data.aug) + calculate(data.sep)
            return (q3 / 3).toFixed(0)
          }
          if(qtr === 'q4'){
            q4 = calculate(data.oct) + calculate(data.nov) + calculate(data.dec)
            return (q4 / 3).toFixed(0)
          }
        }

        var hasData = item.months[current_month] !== undefined ? true : false
        var hasLastMonth = item.months[last_month] !== undefined ? true : false

        user_data.push({
          email: item.email,
          full_name: item.full_name,
          months: item.months,
          current_clients: hasData ? item.months[current_month].current_clients : 0,
          reductions: hasData ? getPercentage(item.months[current_month], 'reductions') : 0,
          last_month: hasLastMonth ? getPercentage(item.months[last_month], '') : 100,
          rum: hasData && item.months[current_month].rum ? item.months[current_month].rum : 0,
          rum_target: hasData && item.months[current_month].rum_target ? item.months[current_month].rum_target : 0,
          rum_util: hasData ? getPercentage(item.months[current_month], '') : 100,
          q1: hasData ? getQuarter(item.months, 'q1') : 100,
          q2: hasData ? getQuarter(item.months, 'q2') : 100,
          q3: hasData ? getQuarter(item.months, 'q3') : 100,
          q4: hasData ? getQuarter(item.months, 'q4') : 100,
          avg_ret: 0
        });
      });
      return user_data;
    };
  
    const formattedData = formatData(kpis);
    return formattedData;
  }, [kpis, performanceVisibilityOption]);

  const editClientPerformance = (item: any) => {
    setSelectedPerformance(item);
    setOpenEditModal(true);
  };

  const columns = useMemo(
    () =>
      generateColumns({
        sortingColumn,
        sortingDirection,
        editClientPerformance,
        performanceVisibilityOption,
      }),
    [
      sortingColumn,
      sortingDirection,
      editClientPerformance,
      performanceVisibilityOption,
    ]
  );
  const handleFilterChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'status') {
      // setStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    // if (actionMeta === 'role') {
    //   setRole(selectedOption);
    //   newUrl.searchParams.set('role', selectedOption.value);
    // }
    if (actionMeta === 'type') {
      setSelectedType(selectedOption);
      newUrl.searchParams.set('type', selectedOption.value);
    }
    if (actionMeta === 'date') {
      setDate(selectedOption);
      newUrl.searchParams.set('date', moment(selectedOption).format('YYYY-MM'));
    }
    if (actionMeta === 'cycle') {
      setCycle(selectedOption);
      newUrl.searchParams.set('cycle', selectedOption.value);
    }
    if (actionMeta === 'clients') {
      setClients(selectedOption);
      newUrl.searchParams.set('client', selectedOption);
    }
    if (actionMeta === 'users') {
      setClients(selectedOption);
      newUrl.searchParams.set('client', selectedOption.value);
    }
    if (actionMeta === 'integration') {
      setIntegration(selectedOption);
      newUrl.searchParams.set('integration', selectedOption.value);
    }

    window.history.pushState({}, '', newUrl);
  };

  return (
    <div className="bg-[#D2EEFF] p-3 overflow-hidden min-h-[93dvh] dark:bg-[#191919] font-inter">
      <div className="border-1 border-gray-50 border rounded-lg dark:border-gray-700 dark:bg-gray-800 shadow-md">
        <div className="flex  justify-between items-center bg-white rounded-t-lg p-4 dark:bg-gray-800">
          <div className="flex items-center gap-x-2  dark:bg-gray-800 sticky top-0 z-30">
            {/* <SearchBarDropdown performanceFilter={performanceFilter} /> */}
            <div
              className={`flex ${performanceFilterStorage?.options?.length > 0 ? 'justify-start' : 'justify-center text-[#525252]'} items-center gap-x-1 p-1 z-50 cursor-pointer`}
            >
              <div>
                <MultiFilterPerformanceModal
                  handleFilterChange={handleFilterChange}
                  isOpen={openFilterModal}
                  onClose={() => setOpenFilterModal(false)}
                  cycle={cycle}
                  clients={clients}
                  integration={integration}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  handleSearch={handleSearch}
                  performanceFilterStorage={performanceFilterStorage}
                  date={date}
                />
              </div>
            </div>
            <div className="font-inter">
              <DatePicker
                selected={moment(date, 'YYYY').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    handleFilterChange(date, 'date');
                  }
                }}
                dateFormat="yyyy"
                minDate={minDate}
                maxDate={maxDate}
                showYearPicker
                className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex gap-x-8 items-center">
              <button
                className="flex items-center py-2 px-3.5 gap-2 rounded-lg border border-[#808EA2]"
                onClick={handleVisibilityClick}
              >
                <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                  Columns
                </span>
                <Icon
                  name="offEye"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
            </div>
          </div>
        </div>
        <TansStackTableV2
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={kpis?.total_pages}
          limit="150"
          setLimit="150"
          page={page}
          setPage={setPage}
          loading={kpis?.loading}
          rowState={rowState}
          setRowState={setRowState}
          totalDocs={kpis?.total_docs}
          stickyColumn="name"
          sortData={handleSortTable}
        />
      </div>

      <CustomModal
        open={openInsightModal}
        onClose={() => setOpenInsightModal(false)}
      >
        <PerformanceInsightsModal onClose={() => setOpenInsightModal(false)} />
      </CustomModal>
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <PerformanceModal
          onClose={() => setOpenEditModal(false)}
          clientPerformance={selectedPerformance}
          handleFetchReports={handlePerformanceFetch}
        />
      </CustomModal>
      <CustomModal
        open={showExportModal}
        onClose={() => {
          setRowState(false);
          setShowExportModal(false);
        }}
      >
        <ExportModal
          closeModal={() => {
            setRowState(false);
            setShowExportModal(false);
          }}
          selectDate={date}
          cycle={cycle}
          clients={clients}
          integration={integration}
        />
      </CustomModal>
    </div>
  );
}

export default KPIs;
